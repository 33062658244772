import React from "react";
import { Link } from "react-router-dom";
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
import CreateCircle from "../Modal/CreateCircle";
import StatusupdateModal from "../Modal/Edit";
import DivisionView from "../Modal/DivisionView";
import ViewTickets from "../Modal/ViewTickets";
import { baseurl } from "../BaseUrl/Baseurl";
import cancelledimg from "../../assets/images/Status/cancelled.png";
import acceptedimg from "../../assets/images/Status/accepted.png";
import pendingimg from "../../assets/images/Status/pending.png";
import axios from "axios";
import close from "../../assets/images/Status/closed.png";
import open from "../../assets/images/Status/open.png";
import renewal from "../../assets/images/Status/renewal.png";
import renewed from "../../assets/images/Status/renewed.png";
import suspended from "../../assets/images/Status/suspended.png";
import { useSelector } from "react-redux";
import "./Commoncss.css";
import circleicon from "../../assets/icons/createcircle.png";
import editicon from "../../assets/icons/editicon.png";
import deleteicon from "../../assets/icons/deleteicon.png";
import circle from "../../assets/icons/Vector-3.png";
import CircleEdit from "../Modal/CircleEdit";
import { BiEditAlt } from "react-icons/bi";
import { Modal, Offcanvas } from "react-bootstrap";
import InputTextField from "../InputText/InputText";
import { IoCloseSharp } from "react-icons/io5";
const User = (btnClick) => {
  const [btnname, setBtnname] = useState("");
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [projectid, setProjectid] = useState("");
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [project_renewalid, setProject_renewalid] = useState("");
  const [ticketno, setTicketno] = useState("");
  const [successstatus, setSuccessstatus] = useState(false);
  const [successstatus1, setSuccessstatus1] = useState(false);
  const [successstatus2, setSuccessstatus2] = useState(false);
  const [editstatus, setEditstatus] = useState(false);
  const [deletestatus, setDeletestatus] = useState(false);
  const [roleModal, setRoleModal] = useState(false);
  const [show, setShow] = useState(false);
  const [canShow, setCanShow] = useState(false);
  const [projectnameValid, setProjectnameValid] = useState(false);
  const [role, setRole] = useState("");
  const [area, setArea] = useState("");
  const [addRole, setAddRole] = useState("");
  const [contactmailValid, setContactmailValid] = useState(false);
  const [contactmailerror, setContactmailerror] = useState("");
  const [passValid, setPassValid] = useState(false);
  const [passerror, setPasserror] = useState("");
  const [contactnoValid, setContactnoValid] = useState(false);
  const [contactnoerror, setContactnoerror] = useState("");
  const [namevalid, setNamevalid] = useState(false);
  const [nameerror, setNameerror] = useState("");
  const [roleerror, setRoleerror] = useState("");
  const [roleValid, setRoleValid] = useState(false);
  const [areaerror, setAreaerror] = useState("");
  const [areaValid, setAreaValid] = useState(false);
  const [userlist1, setUserlist1] = useState([]);
  const [name, setName] = useState("");
  const [mailid, setMailid] = useState("");
  const [contnum, setContnum] = useState("");
  const [pass, setPass] = useState("");
  const [formErr, setFormErr] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const [projectnameerror, setProjectnameerror] = useState("");

  const userData = useSelector((state) => state.userDetails);
  useEffect(() => {}, [requestlist]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const RequestData = response.data;

        setRequestlist(RequestData);
        setSuccessstatus(true);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    //Renwal list
    axios({
      method: "GET",

      url: baseurl + "/api/project/renewal/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        console.log(response.data);
        const renewalData = response.data;
        setRenewallist(renewalData);
        setSuccessstatus1(true);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    //Ticket list
    axios({
      method: "GET",

      url: baseurl + "/api/support/issue/ticket/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        console.log(response.data);
        const TicketData = response.data;
        setTicketlist(TicketData);
        setSuccessstatus2(true);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });
  }, [modelStatus, updatestatus]);

  const toggleModelStatus = () => {
    //Refresh the Table

    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const RequestData = response.data;
        setRequestlist(RequestData);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    setRequeststatus(false);
  };
  const toggleModelStatus1 = () => {
    setUpdatestatus(false);

    //Refresh the Table

    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const RequestData = response.data;
        setRequestlist(RequestData);
        setSuccessstatus(true);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });
  };
  const toggleRenewalStatus = () => {
    console.log("***********");
    setRenewalstatus(false);
  };
  const toggleViewTicketStatus = () => {
    console.log("***********");
    setViewticketstatus(false);
  };
  const requestdata1 = () => {
    console.log("*****" + requeststatus);
    setRequeststatus(true);
    setShow(true);
  };

  const RenewalUpdate = (id) => {
    setProject_renewalid(id);
    setRenewalstatus(true);
  };
  const onEdit = (id) => {
    setEditstatus(true);
  };

  const onDelete = (id) => {
    setDeletestatus(true);
  };
  const onEditClose = () => {
    setEditstatus(false);
  };
  const ViewticketstatusUpdate = (id) => {
    setTicketno(id);
    setViewticketstatus(true);
  };

  const handleClose = () => {
    setShow(false);
    setCanShow(false);
    setName("");
    setMailid("");
    setContnum("");
    setPass("");
    setRole("");
    setArea("");
  };

  function handleRole(e) {
    setRole(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
    setCanShow(true);
  }
  function handlearea(e) {
    setArea(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  function handleName(e) {
    setName(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleMailid(e) {
    setMailid(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handlePass(e) {
    setPass(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleContnum(e) {
    setContnum(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  const validateField = (fieldName, value) => {
    if (fieldName === "projectname") {
      if (value.length != 0) {
        setProjectnameerror("");
        setProjectnameValid(true);
      } else {
        setProjectnameerror("Please enter your Project name");
        setProjectnameValid(false);
      }
    }

    if (fieldName === "contactno") {
      if (value.length != 0 && value.length == 10) {
        setContactnoerror("");
        setContactnoValid(true);
      } else {
        setContactnoerror("Please enter valid Contact number");
        setContactnoValid(false);
      }
    }
    // if (fieldName === 'clientname') {

    //   if (value != "") {
    //     setClientnameerror('')
    //     setClientnameValid(true)
    //   } else {
    //     setClientnameerror('Please select Client name')
    //     setClientnameValid(false)
    //   }
    // }
    if (fieldName === "role") {
      console.log(value);

      if (value != "") {
        setRoleerror("");
        setRoleValid(true);
      } else {
        setRoleerror("Please enter Role");
        setRoleValid(false);
      }
    }
    if (fieldName === "area") {
      console.log(value);

      if (value != "") {
        setAreaerror("");
        setAreaValid(true);
      } else {
        setAreaerror("Please Choose Area");
        setAreaValid(false);
      }
    }
    if (fieldName === "name") {
      if (value != "") {
        setNameerror("");
        setNamevalid(true);
      } else {
        setNameerror("Please Enter Valid Name");
        setNamevalid(false);
      }
    }
    if (fieldName === "contactmail") {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (value.length != 0) {
        if (value.match(regex)) {
          setContactmailValid(true);
          setContactmailerror("");
        } else {
          setContactmailValid(false);
          setContactmailerror("Please enter valid email");
        }
      } else {
        setContactmailValid(false);
        setContactmailerror("Please enter valid email");
      }
    }
    if (fieldName === "password") {
      // Password regex: at least one digit, one lowercase, one uppercase, one special char, and 8-20 characters long
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;

      if (value.length !== 0) {
        if (value.match(regex)) {
          setPassValid(true);
          setPasserror("");
        } else {
          setPassValid(false);
          setPasserror(
            "Password must be 8-20 characters long, and include at least one uppercase letter, one lowercase letter, one number, and one special character."
          );
        }
      } else {
        setPassValid(false);
        setPasserror("Please enter a password.");
      }
    }
  };

  function onSubmitEvent(event) {
    event.preventDefault();

    if (
      namevalid &&
      roleValid &&
      contactmailValid &&
      passValid &&
      contactnoValid &&
      areaValid
    ) {
      console.log(name);
    }
  }

  const handleAddRoleChange = (e) => {
    setAddRole(e.target.value);
    setFormErr("");
  };

  const handleRolebtn = () => {
    console.log(addRole);

    let userData1 = {
      role: addRole,
    };
    axios({
      method: "POST",
      url: `${baseurl}/api/list/add?user_id=${userData.userId}&field=role`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
      data: userData1,
    })
      .then((response) => {
        console.log("Response Data:", response.data);
        setAddRole("");
        setCanShow(false);
        setRole("");
        setFormSuccess(response.data.message);
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        setFormErr(errorMessage);
        console.log(errorMessage);
      });
  };

  return (
    <div class="row mt-5  ">
      <div class=" col-xl-4 pt-1 pb-1 "></div>
      <div class=" col-xl-4 pt-1 pb-1"></div>
      <div class=" col-xl-3 pt-1 pb-1 mt-2 ">
        <button
          onClick={requestdata1}
          type="button"
          className="createcircle-btn"
        >
          <img src={circleicon} />
          &nbsp;Create New Userlist
        </button>
        {/* {requeststatus && (
          <CreateCircle status={requeststatus} btnClick={toggleModelStatus} />
        )} */}
      </div>
      {/* <div class=" col-xl-1 pt-1 pb-1"></div> */}
      {successstatus1 && renewallist.length == 0 && (
        // !checkduedate &&

        <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3">
          <center>
            <b>
              <h6>No Data Available</h6>
            </b>
          </center>
        </div>
      )}
      {renewallist.length != 0 && (
        <table class="table  tablemargin" readOnly="readOnly">
          <tr class="th-circle ">
            <th style={{ borderTopLeftRadius: "10px" }}>
              <div
              // className='trpad'
              >
                <center>Circle Name</center>
              </div>
            </th>
            <th>
              <center>Contact Number</center>
            </th>
            <th>
              <center>Role</center>
            </th>
            <th>
              <center>State</center>
            </th>
            <th>
              <center>Wing</center>
            </th>
            <th>
              <center>Circle</center>
            </th>
            <th>
              <center>Division</center>
            </th>
            <th>
              <center>Sub-Division</center>
            </th>

            <td style={{ borderTopRightRadius: "10px" }}></td>
          </tr>
          {editstatus && (
            <CircleEdit
              status={editstatus}
              btnClick={onEditClose}
              ProjectId={project_renewalid}
              modalname="Create Userlist"
              fieldName="Userlist"
            />
          )}

          {renewalstatus && (
            <DivisionView
              status={renewalstatus}
              btnClick={toggleRenewalStatus}
              ProjectId={project_renewalid}
              modalname="Division List"
            />
          )}
          {renewallist.length != 0 &&
            renewallist.map((item, index) => {
              return (
                <tr key={index} className="tablefontsize">
                  <td>
                    <div>
                      <center>
                        {item.project_id != "" && item.project_id != undefined
                          ? item.project_id
                          : "NA"}
                      </center>
                    </div>
                  </td>
                  <td>
                    <center>
                      {" "}
                      {item.contact_person != "" &&
                      item.contact_person != undefined
                        ? item.contact_person
                        : "NA"}
                    </center>
                  </td>
                  <td>
                    <center>
                      {" "}
                      {item.contact_person != "" &&
                      item.contact_person != undefined
                        ? item.contact_person
                        : "NA"}
                    </center>
                  </td>
                  <td>
                    <center>
                      {" "}
                      {item.contact_person != "" &&
                      item.contact_person != undefined
                        ? item.contact_person
                        : "NA"}
                    </center>
                  </td>
                  <td>
                    {" "}
                    <center>
                      {" "}
                      <button
                        type="button"
                        className="view-circle"
                        onClick={() => RenewalUpdate(item.project_id)}
                      >
                        View
                      </button>
                    </center>
                  </td>
                  <td>
                    {" "}
                    <center>
                      {" "}
                      <button
                        type="button"
                        className="view-circle"
                        onClick={() => RenewalUpdate(item.project_id)}
                      >
                        View
                      </button>
                    </center>
                  </td>
                  <td>
                    {" "}
                    <center>
                      {" "}
                      <button
                        type="button"
                        className="view-circle"
                        onClick={() => RenewalUpdate(item.project_id)}
                      >
                        View
                      </button>
                    </center>
                  </td>
                  <td>
                    {" "}
                    <center>
                      {" "}
                      <button
                        type="button"
                        className="view-circle"
                        onClick={() => RenewalUpdate(item.project_id)}
                      >
                        View
                      </button>
                    </center>
                  </td>
                  {/* <td><center>{item.last_renewal_date!=''&& item.last_renewal_date!=undefined?item.last_renewal_date:'NA'}</center></td> */}
                  {/* <td> <center><img src={editicon}    onClick={() => onEdit(item.project_id)}/>&nbsp;&nbsp;&nbsp;<img src={deleteicon}  onClick={() => onDelete(item.project_id)} /></center></td> */}
                  <td>
                    {" "}
                    <center>
                      <div className="d-flex ">
                        <button
                          onClick={() => onEdit(item.project_id)}
                          className="btn btn2  "
                        >
                          <BiEditAlt
                            style={{ fontSize: "17px", color: "#069E55" }}
                          />
                        </button>

                        <button
                          onClick={() => onDelete(item.project_id)}
                          class="btn btn1 "
                        >
                          <svg
                            viewBox="0 0 15 17.5"
                            height="15"
                            width="13"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon"
                          >
                            <path
                              transform="translate(-2.5 -1.25)"
                              d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                              id="Fill"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </center>
                  </td>
                </tr>
              );
            })}
        </table>
      )}

      {requeststatus && (
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="d-flex justify-content-end">
            <button className="btn " onClick={handleClose}>
              <IoCloseSharp />
            </button>
          </div>
          <Modal.Body className=" pb-0">
            <form
              class="row d-flex justify-content-center align-center  circlediv"
              onSubmit={(event) => {
                onSubmitEvent(event);
              }}
            >
              <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3 pt-3">
                <label for="formFileLg" className="login-text">
                  Name
                </label>
                <center>
                  <InputTextField
                    handleInputChange={handleName}
                    valueText={name}
                    inputName="name"
                    inputId="name"
                    classname="project-box pl-2"
                    maxLen={20}
                    inputType="text"
                    //  placeholderValue="Contact"
                    defaultValue={name}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  ></InputTextField>
                </center>
                <div className="login-error">{nameerror}</div>
              </div>

              <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3 pt-3">
                <label for="formFileLg" className="login-text">
                  Mail ID
                </label>
                <center>
                  <InputTextField
                    handleInputChange={handleMailid}
                    valueText={mailid}
                    inputName="contactmail"
                    inputId="contactmail"
                    classname="project-box pl-2"
                    maxLen={20}
                    inputType="text"
                    //  placeholderValue="Contact"
                    defaultValue={mailid}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  ></InputTextField>
                </center>
                <div className="login-error">{contactmailerror}</div>
              </div>

              <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3 pt-3">
                <label for="formFileLg" className="login-text">
                  Contact Number
                </label>
                <center>
                  <InputTextField
                    handleInputChange={handleContnum}
                    valueText={contnum}
                    inputName="contactno"
                    inputId="contactno"
                    classname="project-box pl-2"
                    maxLen={20}
                    inputType="text"
                    //  placeholderValue="Contact"
                    defaultValue={contnum}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  ></InputTextField>
                </center>
                <div className="login-error">{contactnoerror}</div>
              </div>

              <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3 pt-3">
                <label for="formFileLg" className="login-text">
                  Password
                </label>
                <center>
                  <InputTextField
                    handleInputChange={handlePass}
                    valueText={pass}
                    inputName="password"
                    inputId="password"
                    classname="project-box pl-2"
                    maxLen={20}
                    inputType="text"
                    //  placeholderValue="Contact"
                    defaultValue={pass}
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                  ></InputTextField>
                </center>
                <div className="login-error">{passerror}</div>
              </div>

              <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-md-12 pb-3 pt-3">
                <label
                  for="formFileLg"
                  class="form-label"
                  className="formfontst ast"
                >
                  <b>Role</b>
                </label>
                {canShow ? (
                  <div>
                    <div class="input-group mb-3">
                      <input
                        onChange={handleAddRoleChange}
                        type="text"
                        class="form-control"
                        placeholder="Enter Role"
                        value={addRole}
                      />
                      <div class="input-group-append">
                        <button
                          onClick={handleRolebtn}
                          class="btn border"
                          style={{ backgroundColor: "#dedee3" }}
                          type="button"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                    <div style={{ fontSize: '14px' }} className="text-danger d-flex justify-content-end m-0"><span style={{cursor:'pointer'}} onClick={() => {
                                setCanShow(false)
                                setAddRole('')
                              }}>Cancel</span></div>
                    <p style={{ color: "red", fontWeight: "500" }}>{formErr}</p>
                  </div>
                ) : (
                  <select
                    className="project-box pl-2"
                    id="role"
                    name="role"
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    value={role}
                    onChange={handleRole}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                    <option
                      onClick={() => {
                        setCanShow(true);
                      }}
                    >
                      Add New Role
                    </option>
                    {userlist1.map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                )}

                <div className="login-error">{roleerror}</div>
              </div>
              <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-md-12 pb-3 pt-3">
                <label
                  for="formFileLg"
                  class="form-label"
                  className="formfontst ast"
                >
                  <b>Choose area</b>
                </label>
                <select
                  multiple
                  className="project-box pl-2"
                  id="area"
                  name="area"
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  value={area}
                  onChange={handlearea}
                >
                  <option value="Select" selected>
                    Select
                  </option>
                  {userlist1.map((name, index) => (
                    <option key={index} value={name}>
                      {name}
                    </option>
                  ))}
                </select>

                <div className="login-error">{areaerror}</div>
              </div>
              {formSuccess && (
                <p style={{ color: "green", fontWeight: "500" }}>
                  {formSuccess}
                </p>
              )}
              <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                <center>
                  <button
                    type="submit"
                    className="search-circle"
                    name="submit"
                    disabled={!projectnameValid}
                  >
                    <b>Create</b>
                  </button>
                </center>{" "}
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default User;
