import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ButtonLink(btnDetails) {
  
  const navigate = useNavigate()


//For global nativation with params
  useEffect(() => {
    navigate(btnDetails.btnPath, {
      state: {
         projectid: btnDetails.projectid,
         clientid: btnDetails.clientid,
         userId:btnDetails.userId,       
      },
    })
  }, [
    navigate,
     btnDetails.btnPath,
    btnDetails.projectid,
    btnDetails.clientid,
    btnDetails.userId,
  
  ])

  return null
}
