import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import { connect, useDispatch, useSelector } from 'react-redux'
import HorizontalLine from '../horizontallinesample/horizan'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const [projectname, setProjectname] = useState('')
  const [projectnameValid, setProjectnameValid] = useState(true)
  const [projectnameerror, setProjectnameerror] = useState('')
  const [projecttype, setProjecttype] = useState('')
  const [projecttypeValid, setProjecttypeValid] = useState(true)
  const [projecttypeerror, setProjecttypeerror] = useState('')
  const [startdate, setStartdate] = useState('')
  const [startdateValid, setStartdateValid] = useState(true)
  const [startdateeerror, setStartdateerror] = useState('')
  const [enddate, setEnddate] = useState('')
  const [enddateValid, setEnddateValid] = useState(true)
  const [enddateeerror, setEnddateerror] = useState('')
  const [budget, setBudget] = useState('')
  const [budgetValid, setBudgetValid] = useState(true)
  const [budgeterror, setBudgeterror] = useState('')
  const [contactno, setContactno] = useState('')
  const [contactnoValid, setContactnoValid] = useState(true)
  const [contactnoerror, setContactnoerror] = useState('')
  const [contactmail, setContactmail] = useState('')
  const [contactmailValid, setContactmailValid] = useState(true)
  const [contactmailerror, setContactmailerror] = useState('')
  const [companyname, setCompanyname] = useState('')
  const [companynameValid, setCompanynameValid] = useState(true)
  const [companynameerror, setCompanynameerror] = useState('')
  const [contactperson, setContactperson] = useState('')
  const [contactpersonValid, setContactpersonValid] = useState(true)
  const [contactpersonerror, setContactpersonerror] = useState('')
  const [successstatus, setSuccessstatus] = useState(false)
  const [projectid, setProjectid] = useState('')
  const [clientid, setClientid] = useState('')
  const [editstatus, setEditstatus] = useState(false)
  const userData = useSelector((state) => state.userDetails)
  const dispatch = useDispatch()
  const [companynamelist,setCompanynamelist]=useState([])
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  
//Getting Project data

axios({
    method: 'GET',
  
    url:  baseurl+'/api/project/get/project_details?project_id='+userData.projectId,
  
    headers: {
      'Content-Type': 'multipart/form-data',     
      Authorization: 'Bearer ' + userData.token,
    }
  
    // data: userData,
  })
    
  .then((response) => {
    console.log(response.data)
   
  const RequestData=response.data

  setProjectname(RequestData.project_name)
  setProjecttype(RequestData.project_type)
  setStartdate(RequestData.est_start_date)
  setEnddate(RequestData.est_end_date)
  setBudget(RequestData.budget)
  setCompanyname(RequestData.client_company_name)
  setContactperson(RequestData.contact_person)
  setContactno(RequestData.contact_no)
  setContactmail(RequestData.contact_email)
  
  })
  .catch((error) => {
     console.log(error)
   // setErrormsg(error.response.data.message)
  })
//


    // axios({
    //   method: 'GET',
    
    //   url:  baseurl+'/api/request/trial/list/company/status/accepted',
    
    //   headers: {
    //     'Content-Type': 'multipart/form-data',     
    //     Authorization: 'Bearer ' + userData.token,
    //   }
    
    //   // data: userData,
    // })
      
    // .then((response) => {
    //   console.log("uuuuu"+JSON.stringify(response.data))
    // const RequestData=response.data
    // const Datalist=RequestData[0]
    // const data=Datalist.company_names
    //       alert(data)
    // setCompanynamelist(data)
    // })
    // .catch((error) => {
    //    console.log(error)
    //  // setErrormsg(error.response.data.message)
    // })


    
    // if (successstatus) {   
    //   dispatch(
    //     updateLoginStatus({
    //       isLoggedIn: true,
    //       userName:userData.userName,
    //       userId:userData.userId,
    //       role:userData.role,
    //       projectId:projectid,
    //       clientId:clientid,
    //       Pagename:userData.Pagename

    //     })
    //   )
    // }
  }, [successstatus])

  function handleProjectname(e) {
    console.log(e.target.value)
    setProjectname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleprojecttype(e) {
    setProjecttype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleStartdate(e) {

    setStartdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleEnddate(e) {

    setEnddate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleContactmail(e) {
    setContactmail(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleBudget(e) {
    setBudget(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleCompanyname(e) {
    setCompanyname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleContactperson(e) {
    setContactperson(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {
    if (fieldName === 'projectname') {
      console.log(value)
      if (value.length != 0) {
        setProjectnameerror('')
        setProjectnameValid(true)
      } else {
        setProjectnameerror('Please enter your Project name')
        setProjectnameValid(false)
      }
    }
    if (fieldName === 'projecttype') {
      if (value.length != 0) {
        setProjecttypeerror('')
        setProjecttypeValid(true)
      } else {
        setProjecttypeerror('Please enter your Project type')
        setProjecttypeValid(false)
      }
    }
    if (fieldName === 'startdate') {
      if (value.length != 0) {
        setStartdateerror('')
        setStartdateValid(true)
      } else {
        setStartdateerror('Please select Start date')
        setStartdateValid(false)
      }
    }
    if (fieldName === 'enddate') {
      if (value.length != 0) {
        setEnddateerror('')
        setEnddateValid(true)
      } else {
        setEnddateerror('Please select End date')
        setEnddateValid(false)
      }
    }
    if (fieldName === 'Select') {
      if (value.length != 0) {
        setProjecttypeerror('')
        setProjecttypeValid(true)
      } else {
        setProjecttypeerror('Please select your Project type')
        setProjecttypeValid(false)
      }
    }
    if (fieldName === 'contactmail') {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      if (value.length != 0) {
        console.log(value.match(regex))
        if (value.match(regex)) {

          setContactmailValid(true)
          setContactmailerror('')
        } else {

          setContactmailValid(false)
          setContactmailerror('Please enter valid email')
        }
      } else {

        setContactmailValid(false)
        setContactmailerror('Please enter valid email')
      }
    }
    if (fieldName === 'budget') {
      if (value.length != 0) {
        setBudgeterror('')
        setBudgetValid(true)
      } else {
        setBudgeterror('Please enter your Budget')
        setBudgetValid(false)
      }
    }
    if (fieldName === 'contactno') {
      if (value.length != 0 && value.length == 10) {
        setContactnoerror('')
        setContactnoValid(true)
      } else {
        setContactnoerror('Please enter valid Contact number')
        setContactnoValid(false)
      }
    }
    if (fieldName === 'companyname') {
      console.log(value)

      if (value.length != 0) {
        setCompanynameerror('')
        setCompanynameValid(true)
      } else {
        setCompanynameerror('Please select Company name')
        setCompanynameValid(false)
      }
    }
    if (fieldName === 'contactperson') {
      console.log(value)
      if (value != "") {
        setContactpersonerror('')
        setContactpersonValid(true)
      } else {
        setContactpersonerror('Please enter Client Contact person')
        setContactpersonValid(false)
      }
    }
  }
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    console.log(year)
    console.log(month)
    console.log(day)
    return `${year}-${month}-${day}`;
  }

  function onSubmitEvent(event) {
    event.preventDefault()

  


    // if (projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
    //    && companynameValid && contactpersonValid && contactmailValid && contactnoValid) {


    let userData1 = new FormData()
    userData1.append('project_name', projectname)
    userData1.append('project_id', userData.projectId)
    userData1.append('contact_person', contactperson)
    userData1.append('client_company_name', companyname)
    userData1.append('contact_no', contactno)
    userData1.append('contact_email', contactmail)
    userData1.append('est_start_date', startdate)
    userData1.append('est_end_date', enddate)
    userData1.append('budget', budget)
    userData1.append('project_type', projecttype)
  
      axios({
        method: 'PUT',
        url: baseurl +'/api/project/update/project_details', 
  
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        },
        data:userData1
      })
      .then((response) => {
      
      // alert("Success")

      setEditstatus(false)
        setSuccessstatus(true)
      })
      .catch((error) => {
         console.log(error)
        //setErrormsg(error.response.data.message)
      })
//   }
  
  }
  const todayDate = getTodayDate();
  console.log(todayDate)
  return (
    <>

      {/* {success && <Na
        vigate to="/FreeTrialRegistration" />} */}
      {/* {successstatus && <ButtonLink btnPath="/PlanUpdate"
      //  projectid={projectid}  clientid={clientid} 
       />} */}
      <div className="">
        <div >
          <div className='table1bdstyle'>
            <div className='table1st'>

              <div class="row ">

              {/* <HorizontalLine stage={3} /> */}
                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={onSubmitEvent}
                  >

                    <div class="row">

                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6  pb-3">
                        <label for="formFileLg" className="login-text">
                          Project Name
                        </label>
                        <center>
                          <InputTextField

                            valueText={projectname}
                            inputName="projectname"
                            inputId="projectname"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="Project name"
                            handleInputChange={handleProjectname}
                            PreventEnter={(e) => {
                              e.key === 'Enter' && e.preventDefault()
                            }}
                            disabled={!editstatus}

                          ></InputTextField>

                          <div className="login-error">{projectnameerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Project Type
                        </label>
                        <center>
                          {/* <InputTextField
                            handleInputChange={handleprojecttype}
                            valueText={projecttype}
                            inputName="projecttype"
                            inputId="projecttype"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="Project type"
                          ></InputTextField> */}
                           <select
                          className="project-box"
                          id="projecttype"
                          name="projecttype"
                          //  defaultValue={companyname}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                          value={projecttype}
                          onChange={handleprojecttype}
                          disabled={!editstatus}
                        >
                          <option value="Select" selected>
                            Select
                          </option>
                          <option value="Building">Building</option>
                          <option value="Road">Road</option>
                      
</select>
                          <div className="login-error">{projecttypeerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Estimate Start date
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleStartdate}
                            valueText={startdate}
                            inputName="startdate"
                            inputId="startdate"
                            classname="project-box"
                            inputType="date"
                            placeholderValue="Start Date"
                            mindate={todayDate}
                            disabled={!editstatus}
                          ></InputTextField>

                          <div className="login-error">{startdateeerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Estimate End date
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleEnddate}
                            valueText={enddate}
                            inputName="enddate"
                            inputId="enddate"
                            classname="project-box"
                            inputType="date"
                            placeholderValue="End date"
                            mindate={todayDate}
                            disabled={!editstatus}
                          ></InputTextField>

                          <div className="login-error">{enddateeerror}</div>
                        </center>
                      </div>
                      {/* <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3 ">
                  <label
                    for="dob"
        
                    className="login-text"
                  >
                 
                  </label>
                  <center>
                  <input
                    type="date"
                    id="dob"
                    classname="formboxnew"
                     max={values.maxyear}
                    // min={values.minyear}
                    name="dob"
                    placeholder=""
                    // onChange={handleChange('dob')}
                    // defaultValue={values.dob}
                    // value={values.dob}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                  />
                  </center>
                   <div className="sign-error"> {values.doberror}</div> }
                </div> */}

                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Budget
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleBudget}
                            valueText={budget}
                            inputName="budget"
                            inputId="budget"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="Budget"
                            disabled={!editstatus}
                          ></InputTextField>
                        </center>
                        <div className="login-error">{budgeterror}</div>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                        Company Name
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleCompanyname}
                            valueText={companyname}
                            inputName="companyname"
                            inputId="companyname"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="companyname"
                            disabled={!editstatus}
                          ></InputTextField>
                        </center>
                        <div className="login-error">{companynameerror}</div>
                      </div>


{/* 
                      <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
                        <label
                          for="formFileLg"
                          class="form-label"
                          className="login-text"
                        >
                          Company Name
                        </label>
                        <select
                          className="project-box"
                          id="companyname"
                          name="companyname"
                          //  defaultValue={companyname}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                          value={companyname}
                          onChange={handleCompanyname}
                          disabled={!editstatus}
                        >
                          <option value={companyname} >
                          {companyname}
                          </option>
                       
                         
                    {companynamelist.map((name, index) => (
      <option key={index} value={name}>
        {name}
      </option>
    ))}
                        </select>
                        <div className="login-error">{companynameerror}</div>
                      </div> */}



                      <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
                        {/* <label
                          for="formFileLg"
                          class="form-label"
                          className="formfontst ast"
                        >
                          Client Contact Person
                        </label>
                        <select
                          className="project-box"
                          id="contactperson"
                          name="contactperson"
                          defaultValue={contactperson}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                          value={contactperson}
                          onChange={handleContactperson}
                        >
                          <option value="Select" selected>
                            Select
                          </option>
                          <option value="Devi">Devi</option>
                          <option value="Vageshwari">Vageshwari</option>
                          <option value="Thanalaskhmi">Thanalaskhmi</option>
                        </select> */}
                          <label for="formFileLg" className="login-text">
                          Contact Person
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleContactperson}
                            valueText={contactperson}
                            inputName="contactperson"
                            inputId="contactperson"
                            classname="project-box"
                          
                            inputType="text"
                            placeholderValue="Contact Person"
                            disabled={!editstatus}
                          ></InputTextField>
                        </center>
                        <div className="login-error">{contactpersonerror}</div>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Contact Number
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleContactno}
                            valueText={contactno}
                            inputName="contactno"
                            inputId="contactno"
                            classname="project-box"
                            maxLen={10}
                            inputType="text"
                            placeholderValue="Contact number"
                            disabled={!editstatus}
                          ></InputTextField>
                        </center>
                        <div className="login-error">{contactnoerror}</div>

                      </div>

                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Contact Email
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleContactmail}
                            valueText={contactmail}
                            inputName="contactmail"
                            inputId="contactmail"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="Contact email"
                          disabled={!editstatus}
                          ></InputTextField>

                          <div className="login-error">{contactmailerror}</div>
                        </center>
                      </div>


                    </div>
                  { !editstatus && <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 ">
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                        // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
                        //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                  onClick={()=>setEditstatus(true)}
                        >
                          <b>Edit</b>
                        </button>
                      </center>{' '}
                    </div>}
                    { editstatus && <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 ">
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                        // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
                        //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                        onClick={(e)=>onSubmitEvent(e)}
                        >
                          <b>Submit</b>
                        </button>
                      </center>{' '}
                    </div>}
                  </form>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>



    </>
  )
}

export default FormPage
