import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, Form } from "react-bootstrap";
import "./Modal.css";
import CreateRequest from "../CreateRequest/CreateRequest";
import { useState } from "react";
import InputTextField from "../InputText/InputText";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { useSelector } from "react-redux";
import circle from "../../assets/icons/Vector-3.png";
import { IoCloseSharp } from "react-icons/io5";
export default function Model({
  btnClick,
  status,
  fieldName1,
  fieldName2,
  tab,
}) {
  const [projectname, setProjectname] = useState("");
  const [projectnameValid, setProjectnameValid] = useState(false);
  const [projectnameerror, setProjectnameerror] = useState("");
  const [contactno, setContactno] = useState("");
  const [contactnoValid, setContactnoValid] = useState(false);
  const [contactnoerror, setContactnoerror] = useState("");
  const [clientname, setClientname] = useState("");
  const [clientnameValid, setClientnameValid] = useState(false);
  const [clientnameerror, setClientnameerror] = useState("");
  const [circle, setcircle] = useState("");
  const [circleValid, setcircleValid] = useState(false);
  const [circleerror, setcircleerror] = useState("");
  const [modalVisible, setModalVisible] = useState(true);
  const [division, setdivision] = useState([]);
  const [divisionValid, setdivisionValid] = useState(false);
  const [divisionerror, setdivisionerror] = useState("");
  const [contactmail, setContactmail] = useState("");
  const [contactmailValid, setContactmailValid] = useState(false);
  const [contactmailerror, setContactmailerror] = useState("");
  const [divisionlist, setdivisionlist] = useState([]);
  const [formErr,setFormErr] = useState('')
  const [formSuccess, setFormSuccess] = useState('')
  const [options,setOptions] = useState([])

  const userData = useSelector((state) => state.userDetails);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    // axios({
    //   method: "GET",

    //   url: baseurl + "/api/request/trial/list/company/status/accepted",

    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     Authorization: "Bearer " + userData.token,
    //   },

    //   // data: userData,
    // })
    //   .then((response) => {
    //     console.log(response.data);
    //     const RequestData = response.data;
    //     const Datalist = RequestData[0];
    //     const data = Datalist.company_names;
    //     setdivisionlist(data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     // setErrormsg(error.response.data.message)
    //   });

    if (tab === 'Wing'){
      axios({
        method: 'GET',
  
        url: baseurl+'/api/get/list?field=wing',
  
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userData.token,
        }
      })
  
        .then((response) => {    
          const RequestData = response.data.message
          console.log(RequestData);
          setOptions(RequestData)
        })
        .catch((error) => {
          console.log(error)
        })
      }
    if (tab === 'Circle'){
      axios({
        method: 'GET',
  
        url: baseurl+'/api/get/list?field=circle',
  
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userData.token,
        }
      })
  
        .then((response) => {    
          const RequestData = response.data.message
          console.log(RequestData);
          setOptions(RequestData)
        })
        .catch((error) => {
          console.log(error)
        })
      }
    if (tab === 'Division'){
        axios({
          method: 'GET',
    
          url: baseurl+'/api/get/list?field=division',
    
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userData.token,
          }
        })
    
          .then((response) => {    
            const RequestData = response.data.message
            console.log(RequestData);
            setOptions(RequestData)
          })
          .catch((error) => {
            console.log(error)
          })
      }
 
  }, []);
  function handleContactmail(e) {
    setContactmail(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleProjectname(e) {
    console.log(e.target.value);
    setProjectname(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  function handledivision(e) {
    setdivision(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
    setFormErr('')
  }

  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
    
  }

  // function handleClientname(e) {
  //   setClientname(e.target.value.replace(/\s+/g, ''))
  //   validateField(e.target.name, e.target.value)
  // }
  function handlecircle(e) {
    setcircle(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
    setFormErr('')
  }
  const validateField = (fieldName, value) => {
    if (fieldName === "projectname") {
      if (value.length != 0) {
        setProjectnameerror("");
        setProjectnameValid(true);
      } else {
        setProjectnameerror("Please enter your Project name");
        setProjectnameValid(false);
      }
    }

    if (fieldName === "contactno") {
      if (value.length != 0 && value.length == 10) {
        setContactnoerror("");
        setContactnoValid(true);
      } else {
        setContactnoerror("Please enter valid Contact number");
        setContactnoValid(false);
      }
    }
    
    if (fieldName === "division") {
      console.log(value);

      if (value != "") {
        setdivisionerror("");
        setdivisionValid(true);
        setProjectnameValid(true);
      } else {
        setdivisionerror("Please enter Company name");
        setdivisionValid(false);
        setProjectnameValid(false);
      }
    }
    if (fieldName === "circle") {
      if (value != "") {
        setcircleerror("");
        setcircleValid(true);
      } else {
        setcircleerror("Please select Contact person");
        setcircleValid(false);
      }
    }
    if (fieldName === "contactmail") {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (value.length != 0) {
        if (value.match(regex)) {
          setContactmailValid(true);
          setContactmailerror("");
        } else {
          setContactmailValid(false);
          setContactmailerror("Please enter valid email");
        }
      } else {
        setContactmailValid(false);
        setContactmailerror("Please enter valid email");
      }
    }
  };

  function Create(event) {
    event.preventDefault();
 
    if (tab === "Division") {  
      let userData1 = {
        division:circle,
        sub_division: Array.isArray(division) ? division : [division], 
      };
  
      axios({
        method: "POST",
        url: `${baseurl}/api/list/add?user_id=${userData.userId}&field=division`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
        data: userData1,
      })
        .then((response) => {
          console.log("Response Data:", response.data);
          btnClick()
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          setFormErr(errorMessage);
          console.log(errorMessage);  
          
        });
      }
    
    if (tab === "Circle") {  
      let userData1 = {
        circle:circle,
        division: Array.isArray(division) ? division : [division], 
      };
  
      axios({
        method: "POST",
        url: `${baseurl}/api/list/add?user_id=${userData.userId}&field=circle`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
        data: userData1,
      })
        .then((response) => {
          console.log("Response Data:", response.data);
          btnClick()
          setFormSuccess(response.data.message)

        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          setFormErr(errorMessage);
          console.log(errorMessage);  
          
        });
      }

    if (tab === "Wing") {  
      let userData1 = {
        wing:circle,
        circle: Array.isArray(division) ? division : [division], 
      };
  
      axios({
        method: "POST",
        url: `${baseurl}/api/list/add?user_id=${userData.userId}&field=wing`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.token}`,
        },
        data: userData1,
      })
        .then((response) => {
          console.log("Response Data:", response.data);
          btnClick()
        })
        .catch((error) => {
          const errorMessage = error.response.data.message;
          setFormErr(errorMessage);
          console.log(errorMessage);  
          
        });
      }
    
  }

  // const options = ["Option 1", "Option 2", "Option 3", "Option 4"];


  return (
    <>
      <Modal
        show={status}
        onHide={btnClick}
        id="modal-size1"
        className="custom-modal-width1"
      >
        <div className="d-flex justify-content-end">
            <button className="btn " onClick={btnClick}>
              <IoCloseSharp />
            </button>
          </div>
        <Modal.Body>          
          <form
            class="row d-flex justify-content-center align-center  circlediv"
            
          >
            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3 pt-3">
              <label for="formFileLg" className="login-text">
                {fieldName1}
              </label>
              <center>
                <InputTextField
                  handleInputChange={handlecircle}
                  valueText={circle}
                  inputName="circle"
                  inputId="circle"
                  classname="project-box"
                  maxLen={20}
                  inputType="text"
                  defaultValue={circle}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                ></InputTextField>
              </center>
              {/* <div className="login-error">{circleerror}</div> */}
            </div>            
            <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-md-12 pb-3 pt-3">
              <label
                for="formFileLg"
                class="form-label"
                className="formfontst ast"
              >
                <b>{fieldName2}</b>
              </label>
              <select
                multiple
                className="project-box"
                id="division"
                name="division"
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                value={division}
                onChange={handledivision}
              >
                <option value="Select" selected>
                  Select
                </option>
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>

            </div>
            <div className="login-error">{formErr}</div>
                {/* {formSuccess && <p>{formSuccess}</p>} */}
            <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
              <center>
                <button onClick={Create}
                  type="submit"
                  className="search-circle"
                  name="submit"
                >
                  <b>Submit</b>
                </button>
              </center>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
