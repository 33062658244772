import React, { useState, useEffect } from 'react';
import './ViewProjects.css'
import { connect, useDispatch, useSelector } from 'react-redux'
import { baseurl } from '../BaseUrl/Baseurl'

import { Link } from 'react-router-dom';
import axios from 'axios';
import Createprojects from '../ViewAllProjectPages/ProjectCreation'
import Boq from '../ViewAllProjectPages/Boq'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import { useLocation } from 'react-router-dom';
import CalculateRatio from '../CalculateRatio/Calculateratio'
import Resource from '../ViewAllProjectPages/ResourceDetails'
import CreateUser from '../ViewAllProjectPages/UserCreation'
import PlanUpdate from '../ViewAllProjectPages/PlanUpdate'
const TabComponent = ({ children, tabnameupdate }) => {
  const [activeTab, setActiveTab] = useState(0);
 // const [selectedTab, setselectedTab] = useState('');
 const[projectstatus,setProjectstatus]=useState(1);

  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();

  const handleTabClick = (index, label) => {
    setActiveTab(index);
   // setselectedTab(label);

    tabnameupdate(label);
  };
  useEffect(() => {
      
    axios({
      method: 'GET',

      url: baseurl+'/api/project/get/project_creation_status?project_id='+userData.projectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token,
      }
     
    })
      .then((response) => {
        console.log(response.data)
        const Data = response.data
        const projstatus=Data.project_creation_status
       setProjectstatus(projstatus)
    
     
      })
      .catch((error) => {
        console.log(error)
        // setErrormsg(error.response.data.message)
      })
  }, []);
  const childArray = React.Children.toArray(children);

  return (
    <div className="cont-margin1 ">
      <div className="cont-margin-latest pt-1">
        <div className="table1bdstyle">
          <div className="row table1st">
            <div className="col-lg-12 col-xl-12">
              <div className="tab-container">
                {childArray.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab ${index === activeTab ? 'active' : ''}`}
                    onClick={() => handleTabClick(index, tab.props.label)}
                    disabled={
                      // index+1<=projectstatus ?false:true
                      (projectstatus == 1 && index == projectstatus - 1) || (projectstatus> 1 ) 
                   ?false:  true

                    
                    }

                  >
                    <center>{tab.props.label} </center>
                    {tab.props.label === 'BOQ' && <div className="tab-spaceforRoad"></div>}
                    {tab.props.label === 'Work Reference' && <div className="tab-spaceforRoad1"></div>}
                  </button>
                ))}
              </div>
            </div>
            <div className="col-lg-12 col-xl-12"></div>
            {childArray[activeTab]}
          </div>
        </div>
      </div>
    </div>
  );
};

const Tab = ({ children }) => {
  return <div className="tab-content">{children}</div>;
};
const WeareHere = () => {

  const userData = useSelector((state) => state.userDetails); 
  const [selectedOption, setSelectedOption] = useState('Project');
  // const location = useLocation()
  // const projectid = location.state?.projectid

  const tabnameupdate = (label) => {
    setSelectedOption(label);
  };

  useEffect(() => {
      
 
  }, []);

  return (
    <div className="tab-content">
      <TabComponent tabnameupdate={tabnameupdate}>
        <Tab label="Project">

          {selectedOption === 'Project' && <Createprojects   />}
        </Tab>
        {/* <Tab label="Plan Update">{selectedOption === 'Plan Update' && <PlanUpdate />}</Tab> */}
        <Tab label="Details">{selectedOption === 'Details' && <Boq />}</Tab>
        {/* <Tab label="Calculate Ratio">{selectedOption === 'Calculate Ratio' && <CalculateRatio />}</Tab> */}
        <Tab label='QS'>{selectedOption === 'QS' && <Resource />}</Tab>
        <Tab label='User Creation'>{selectedOption === 'User Creation' && <CreateUser />}</Tab>
      </TabComponent>
    </div>
  );
};

export default WeareHere;