import React,{useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal, Form } from 'react-bootstrap'
import './Modal.css'
import CreateRequest from '../CreateRequest/CreateRequest'
import { useState} from 'react'
import InputTextField from '../InputText/InputText'
import {baseurl} from '../BaseUrl/Baseurl'
import axios from 'axios'
import {useSelector } from 'react-redux'
export default function Model({ btnClick, status }) {
  const [projectname, setProjectname] = useState('')
  const [projectnameValid, setProjectnameValid] = useState(false)
  const [projectnameerror, setProjectnameerror] = useState('')
  const [contactno, setContactno] = useState('')
  const [contactnoValid, setContactnoValid] = useState(false)
  const [contactnoerror, setContactnoerror] = useState('')
  const [clientname, setClientname] = useState('')
  const [clientnameValid, setClientnameValid] = useState(false)
  const [clientnameerror, setClientnameerror] = useState('')
  const [contactperson, setContactperson] = useState('')
  const [contactpersonValid, setContactpersonValid] = useState(false)
  const [contactpersonerror, setContactpersonerror] = useState('')
  const [modalVisible, setModalVisible] = useState(true);
  const [companyname, setCompanyname] = useState('')
  const [companynameValid, setCompanynameValid] = useState(false)
  const [companynameerror, setCompanynameerror] = useState('')
  const [contactmail, setContactmail] = useState('')
  const [contactmailValid, setContactmailValid] = useState(false)
  const [contactmailerror, setContactmailerror] = useState('')
  const [companynamelist,setCompanynamelist]=useState([])
  const userData = useSelector((state) => state.userDetails)
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

axios({
  method: 'GET',

  url:  baseurl+'/api/request/trial/list/company/status/accepted',

  headers: {
    'Content-Type': 'multipart/form-data',     
    Authorization: 'Bearer ' + userData.token,
  }

  // data: userData,
})
  
.then((response) => {
  console.log(response.data)
const RequestData=response.data
const Datalist=RequestData[0]
const data=Datalist.company_names
setCompanynamelist(data)
})
.catch((error) => {
   console.log(error)
 // setErrormsg(error.response.data.message)
})


    }, [])
    function handleContactmail(e) {
      setContactmail(e.target.value.replace(/\s+/g, ''))
      validateField(e.target.name, e.target.value)
    }
  function handleProjectname(e) {
   console.log(e.target.value)
    setProjectname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleCompanyname(e) {
    setCompanyname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  // function handleClientname(e) {
  //   setClientname(e.target.value.replace(/\s+/g, ''))
  //   validateField(e.target.name, e.target.value)
  // }
  function handleContactperson(e) {
    setContactperson(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {
    if (fieldName === 'projectname') {
   
      if (value.length != 0) {
        setProjectnameerror('')
        setProjectnameValid(true)
      } else {
        setProjectnameerror('Please enter your Project name')
        setProjectnameValid(false)
      }
    }
    
 
 
    if (fieldName === 'contactno') {
        if (value.length != 0 && value.length==10) {
          setContactnoerror('')
          setContactnoValid(true)
        } else {
          setContactnoerror('Please enter valid Contact number')
          setContactnoValid(false)
        }
      }
      // if (fieldName === 'clientname') {
   
    
      //   if (value != "") {
      //     setClientnameerror('')
      //     setClientnameValid(true)
      //   } else {
      //     setClientnameerror('Please select Client name')
      //     setClientnameValid(false)
      //   }
      // }
      if (fieldName === 'companyname') {
        console.log(value)
    
        if (value != "") {
          setCompanynameerror('')
          setCompanynameValid(true)
        } else {
          setCompanynameerror('Please enter Company name')
          setCompanynameValid(false)
        }
      }
      if (fieldName === 'contactperson') {
   
        if (value != "") {
          setContactpersonerror('')
          setContactpersonValid(true)
        } else {
          setContactpersonerror('Please select Contact person')
          setContactpersonValid(false)
        }
      }
      if (fieldName === 'contactmail') {
        const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        if (value.length != 0) {
       
          if (value.match(regex)) {
      
            setContactmailValid(true)
            setContactmailerror('')
          } else {
       
            setContactmailValid(false)
            setContactmailerror('Please enter valid email')
          }
        } else {
          
          setContactmailValid(false)
          setContactmailerror('Please enter valid email')
        }
      }
  }

  function onSubmitEvent(event) {
    event.preventDefault()
    
  // console.log(projectname)
  // console.log(contactno)


    if (projectnameValid &&  contactpersonValid && companynameValid && contactnoValid && contactmailValid) {

      console.log(projectname)
      console.log(contactperson)
      console.log(companyname)
      console.log(contactno)
      console.log(contactmail)

        let userData = new FormData()
        userData.append('designation', projectname)
        userData.append('contact_person', contactperson)
        userData.append('company_name', companyname)
        userData.append('contact_number',contactno )
        userData.append('contact_email',contactmail )
        userData.append('status_comment',companyname )
          axios({
            method: 'POST',
      
            url: baseurl + '/api/request/trial/new',
      
            headers: {
              'Content-Type': 'multipart/form-data',     
              Authorization: 'Bearer ' + userData.token,
            },
      
            data: userData,
          })
            
          .then((response) => {
        //  alert("success request created")
          })
          .catch((error) => {
             console.log(error)
           // setErrormsg(error.response.data.message)
          })
  //  setModalVisible(!modalVisible)
   }
  }

  return (
    <>
      <Modal show={status} onHide={btnClick} id="modal-size1">
        <Modal.Body>
          <center>  <div className="model-top">Create Request</div></center>
          {/* <CreateRequest /> */}
          
  
          <div className="cont-modalstyle">
          

                <div class="row pb-2">
           
                
                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={(event) => {
                      onSubmitEvent(event);
                      btnClick(); // Close the modal
                    }}
                  >

                    <div class="row">
    <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                      <label for="formFileLg" className="login-text">
                  Designation
                  </label>
                      <center>
                        <InputTextField
                        
                          valueText={projectname}
                          inputName="projectname"
                          inputId="projectname"
                          classname="project-box"
                          inputType="text"
                          // placeholderValue="Project name"
                          handleInputChange={handleProjectname}
                          PreventEnter={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                       
                         
                        ></InputTextField>

                        
                        </center>
                        <div className="login-error">{projectnameerror}</div>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                      <label for="formFileLg" className="login-text">
                    Contact
                  </label>
                      <center>
                        <InputTextField
                       handleInputChange={handleContactperson}
                       valueText={contactperson}
                       inputName="contactperson"
                       inputId="contactperson"
                       classname="project-box"
                       maxLen={20}
                       inputType="text"
                      //  placeholderValue="Contact"
                       defaultValue={contactperson}
                       onKeyPress={(e) => {
                         e.key === 'Enter' && e.preventDefault()
                       }}
                        ></InputTextField>

                     
                        </center>
                        <div className="login-error">{contactpersonerror}</div>
                      </div>
                      {/* <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                    Client Name
                  </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleClientname}
                          valueText={clientname}
                          inputName="clientname"
                          inputId="clientname"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                          placeholderValue="Project type"
                        ></InputTextField>

                        <div className="login-error">{clientnameerror}</div>
                        </center>
                      </div> */}
                     <div class="col-12 col-sm-12  col-md-12 col-lg-12 ol-md-12 pb-3">
                  {/* <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast"
                  >
                    Company Name
                  </label> */}
                  {/* <select
                    className="project-box"  
                     id="companyname"
                     name="companyname"
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={companyname}
                 onChange={handleCompanyname}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                    {companynamelist.map((name, index) => (
      <option key={index} value={name}>
        {name}
      </option>
    ))}
                   
                  </select> */}
                   <label for="formFileLg" className="login-text">
                    Companyname
                  </label>
                      <center>
                        <InputTextField
                       handleInputChange={handleCompanyname}
                       valueText={companyname}
                       inputName="companyname"
                       inputId="companyname"
                       classname="project-box"
                       maxLen={20}
                       inputType="text"
                      //  placeholderValue="Contact"
                       defaultValue={companyname}
                       onKeyPress={(e) => {
                         e.key === 'Enter' && e.preventDefault()
                       }}
                        ></InputTextField>
                   
                        
                        </center>
                        <div className="login-error">{companynameerror}</div>
                </div>
                     
                      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                Phone Number
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleContactno}
                          valueText={contactno}
                          inputName="contactno"
                          inputId="contactno"
                          classname="project-box"
                          maxLen={10}
                          inputType="text"
                          // placeholderValue="Contact number"

                        ></InputTextField>
     </center>
                        <div className="login-error">{contactnoerror}</div>
                   
                      </div>
                     
                      <div class="col-12 col-sm-12 col-lg-12  col-xl-12 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                    Contact Email
                  </label>
                        <center>
                        <InputTextField
                          handleInputChange={handleContactmail}
                          valueText={contactmail}
                          inputName="contactmail"
                          inputId="contactmail"
                          classname="project-box"
                          
                          inputType="text"
                          // placeholderValue="Contact email"

                        ></InputTextField>

                     
                        </center>
                        <div className="login-error">{contactmailerror}</div> 
                      </div>
                    
                    

                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                          disabled={!(projectnameValid )}
                        >
                          <b>Submit</b>
                        </button>
                      </center>{' '}
                    </div>
                  
                  </form>
                </div>
              
              </div>
            </div>
           

         
        </Modal.Body>
      </Modal>
    </>
  )
}
