import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import './Userlist.css'
import { connect, useDispatch, useSelector } from 'react-redux'
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const userData = useSelector((state) => state.userDetails)
  const [userlist, setUserlist] = useState([]);
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
     
          axios({
            method: 'GET',
            url: baseurl + '/api/user/list?project_id='+userData.projectId,
      
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token,
            },
          })
          .then((response) => {
              console.log(response.data)
              const list=response.data
              const data=list.user_details
              setUserlist(data)
          })
          .catch((error) => {
              console.log(error)
              //setErrormsg(error.response.data.message)
          })
    }, [])
  
  return (
    <>
      
        {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
        {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}
        <div class="cont-margin tmar pt-3">
        <div className='table1bdstyle'>
          <div class="row table1st ">
          <div class="pt-1 pb-1">
                <p><b>List of Users</b></p>
              </div>
              {userlist.length != 0 &&    <table
              class="table tablemargin" 
             
            >
              <tr class="trst pt-1 pb-1">
                <th><div className='trpad'>Name</div></th>
                <th>Mail Id</th>
                <th>Contact Number</th>
                {/* <th>Temporary Password</th> */}
                <th>Role</th>
              </tr>
             
           {userlist.length != 0 && userlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>

                    <td><div className='trpad'>{item.name}</div></td>
                    <td>{item.email}</td>
                    <td>{item.contact_number}</td>
                    {/* <td style={{ width: '200px', maxWidth: '200px', overflowX: 'auto' }}>{item.password}</td> */}
                    <td>{item.role}</td>

                  </tr>
                )
              })}
            </table>}
            <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-3 pb-5">
                      <center>
                        <Link to="/Project">
                        <button
                          type="submit"
                          className="user-btn1"
                          name="submit"
                        
                        >
                          <b>Start Project</b>
                        </button>
                        </Link>
                      </center>{' '}
                    </div>
          </div>
      
        </div>
                
            </div>
            
           
          
  

    
    </>
  )
}

export default FormPage
