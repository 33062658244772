
import './App.css';
import Route from './route'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/js/bootstrap.bundle';
import "@fontsource/plus-jakarta-sans";
function App() {
  return (
    <div >
     <Route />
    </div>
  );
}

export default App;
