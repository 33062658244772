import { Outlet, Link } from "react-router-dom";
import React from "react";
import { useState, useEffect, useRef } from "react";
import "./CreateProject.css";
// import { Navigate } from 'react-router-dom'
import InputTextField from "../InputText/InputText";
import mappic from "../../assets/images/map1.jpg";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import ButtonLink from "../ButtonLink/ButtonLink";
import { connect, useDispatch, useSelector } from "react-redux";
import HorizontalLine from "../horizontallinesample/horizan";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import AddContractor from "../Modal/AddContractor";
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const hiddenFileInput1 = React.useRef(null);
  const handleClick1 = (event) => {
    hiddenFileInput1.current.click();
  };
  const [modelStatus, setModelStatus] = useState(false);
  const [projectname, setProjectname] = useState("");
  const [projectnameValid, setProjectnameValid] = useState(false);
  const [projectnameerror, setProjectnameerror] = useState("");
  const [projecttype, setProjecttype] = useState("Road");
  const [roadtype, setRoadtype] = useState("");
  const [projecttypeValid, setProjecttypeValid] = useState(false);
  const [projecttypeerror, setProjecttypeerror] = useState("");
  const [startdate, setStartdate] = useState("");
  const [startdateValid, setStartdateValid] = useState(false);
  const [startdateeerror, setStartdateerror] = useState("");
  const [enddate, setEnddate] = useState("");
  const [enddateValid, setEnddateValid] = useState(false);
  const [enddateeerror, setEnddateerror] = useState("");
  const [budget, setBudget] = useState("");
  const [budgetValid, setBudgetValid] = useState(false);
  const [budgeterror, setBudgeterror] = useState("");
  const [contactno, setContactno] = useState("");
  const [contactnoValid, setContactnoValid] = useState(false);
  const [contactnoerror, setContactnoerror] = useState("");
  const [contactmail, setContactmail] = useState("");
  const [contactmailValid, setContactmailValid] = useState(false);
  const [contactmailerror, setContactmailerror] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [companynameValid, setCompanynameValid] = useState(false);
  const [companynameerror, setCompanynameerror] = useState("");
  const [contactperson, setContactperson] = useState("");
  const [contactpersonValid, setContactpersonValid] = useState(false);
  const [contactpersonerror, setContactpersonerror] = useState("");
  const [successstatus, setSuccessstatus] = useState(false);
  const [Resourcests, setResourcests] = useState(false);
  const [projectid, setProjectid] = useState("");
  const [clientid, setClientid] = useState("");
  const [addRole, setAddRole] = useState("");

  const [validationMsg, setValidationMsg] = useState("");
  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const [companynamelist, setCompanynamelist] = useState([]);

  const [agreementDate, setagreementDate] = useState("");
  const [agreementDateValid, setagreementDateValid] = useState(false);
  const [agreementDateError, setagreementDateError] = useState("");

  const [agreement, setagreement] = useState("");
  const [agreementValid, setagreementValid] = useState(false);
  const [agreementError, setagreementError] = useState("");

  const [scheme_of_works, setScheme_of_works] = useState("");
  const [scheme_of_worksValid, setScheme_of_worksValid] = useState(false);
  const [scheme_of_worksError, setScheme_of_worksError] = useState("");

  const [circle, setCircle] = useState("");
  const [circleValid, setCircleValid] = useState(false);
  const [circleError, setCircleError] = useState("");

  const [wing, setWing] = useState("");
  const [wingValid, setWingValid] = useState(false);
  const [wingError, setWingError] = useState("");

  const [division, setDivision] = useState("");
  const [divisionValid, setDivisionValid] = useState(false);
  const [divisionError, setDivisionError] = useState("");

  const [subDivision, setSubDivision] = useState("");
  const [subDivisionValid, setSubDivisionValid] = useState(false);
  const [subDivisionError, setSubDivisionError] = useState("");

  const [scheme, setScheme] = useState("");
  const [schemeValid, setSchemeValid] = useState(false);
  const [schemeError, setSchemeError] = useState("");

  const [typeOfWork, setTypeOfWork] = useState("");
  const [typeOfWorkValid, setTypeOfWorkValid] = useState(false);
  const [typeOfWorkError, setTypeOfWorkError] = useState("");

  const [workCode, setWorkCode] = useState("");
  const [workCodeValid, setWorkCodeValid] = useState(false);
  const [workCodeError, setWorkCodeError] = useState("");

  const [workName, setWorkName] = useState("");
  const [workNameValid, setWorkNameValid] = useState(false);
  const [workNameError, setWorkNameError] = useState("");

  const [sanctionedAmount, setSanctionedAmount] = useState("");
  const [sanctionedAmountValid, setSanctionedAmountValid] = useState(false);
  const [sanctionedAmountError, setSanctionedAmountError] = useState("");

  const [lumpSumAmount, setLumpSumAmount] = useState("");
  const [lumpSumAmountValid, setLumpSumAmountValid] = useState(false);
  const [lumpSumAmountError, setLumpSumAmountError] = useState("");

  const [contractValue, setContractValue] = useState("");
  const [contractValueValid, setContractValueValid] = useState(false);
  const [contractValueError, setContractValueError] = useState("");

  const [attachment, setAttachment] = useState("");
  const [attachmentValid, setAttachmentValid] = useState(false);
  const [attachmentError, setAttachmentError] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionValid, setDescriptionValid] = useState(false);
  const [descriptionError, setDescriptionError] = useState("");

  const [tags, setTags] = useState([]);
  const [tagsValid, setTagsValid] = useState(false);
  const [tagsError, setTagsError] = useState("");

  const [mlaConstituency, setMlaConstituency] = useState("");
  const [mlaConstituencyValid, setMlaConstituencyValid] = useState(false);
  const [mlaConstituencyError, setMlaConstituencyError] = useState("");

  const [mpConstituency, setMpConstituency] = useState("");
  const [mpConstituencyValid, setMpConstituencyValid] = useState(false);
  const [mpConstituencyError, setMpConstituencyError] = useState("");

  const [goNo, setGoNo] = useState("");
  const [goNoValid, setGoNoValid] = useState(false);
  const [goNoError, setGoNoError] = useState("");

  const [goIssueDate, setGoIssueDate] = useState("");
  const [goIssueDateValid, setGoIssueDateValid] = useState(false);
  const [goIssueDateError, setGoIssueDateError] = useState("");

  const [dateOfSanction, setDateOfSanction] = useState("");
  const [dateOfSanctionValid, setDateOfSanctionValid] = useState(false);
  const [dateOfSanctionError, setDateOfSanctionError] = useState("");

  const [actualStartDate, setActualStartDate] = useState("");
  const [actualStartDateValid, setActualStartDateValid] = useState(false);
  const [actualStartDateError, setActualStartDateError] = useState("");

  const [actualCompletionDate, setActualCompletionDate] = useState("");
  const [actualCompletionDateValid, setActualCompletionDateValid] =
    useState(false);
  const [actualCompletionDateError, setActualCompletionDateError] =
    useState("");

  const [eccDate, setEccDate] = useState("");
  const [eccDateValid, setEccDateValid] = useState(false);
  const [eccDateError, setEccDateError] = useState("");

  const [ccDate, setCcDate] = useState("");
  const [ccDateValid, setCcDateValid] = useState(false);
  const [ccDateError, setCcDateError] = useState("");

  const [remarks, setRemarks] = useState("");
  const [remarksValid, setRemarksValid] = useState(false);
  const [remarksError, setRemarksError] = useState("");

  const [typeofroad, setTypeofroad] = useState("");
  const [typeofroadValid, setTypeofroadValid] = useState(false);
  const [typeofroadError, setTypeofroadError] = useState("");

  const [contractorname, setContractorname] = useState("");
  const [contractornameValid, setContractornameValid] = useState(false);
  const [contractornameError, setContractornameError] = useState("");

  const [divlist, setDivlist] = useState([]);
  const [subdivlist, setSubdivlist] = useState([]);
  const [circlelist, setCirclelist] = useState([]);
  const [typeofroadlist, setTypeofroadlist] = useState([]);
  const [winglist, setWinglist] = useState([]);
  const [canShow, setCanShow] = useState(false);
  const [roadtypesel, setRoadTypeSel] = useState([]);
  const [added, setAdded] = useState(false)

  useEffect(() => {

    axios({
      method: "GET",

      url: baseurl + "/api/get/list?field=type_of_road",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const RequestData = response.data.message;
        setTypeofroadlist(RequestData);
      })
      .catch((error) => {
        console.log(error);
      });

      console.log('1');
      

  }, [!canShow])

  useEffect(() => {
    axios({
      method: "GET",

      url: baseurl + "/api/get/list?field=wing",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },

      // data: userData,
    })
      .then((response) => {
        console.log(response.data);
        const RequestData = response.data;
        const wlist = RequestData.message || [];
        setWinglist(wlist);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    axios({
      method: "GET",

      url: baseurl + "/api/get/list?field=circle",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },

      // data: userData,
    })
      .then((response) => {
        console.log(response.data);
        const RequestData = response.data;
        const wlist = RequestData.message || [];
        setCirclelist(wlist);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    axios({
      method: "GET",

      url: baseurl + "/api/get/list?field=type_of_road",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },

      // data: userData,
    })
      .then((response) => {
        console.log(response.data);
        const RequestData = response.data;
        const wlist = RequestData.message || [];
        // setTypeofroadlist(wlist);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list/company/status/accepted",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },

      // data: userData,
    })
      .then((response) => {
        console.log(response.data);
        const RequestData = response.data;
        const Datalist = RequestData[0];

        const data = Datalist.company_names || [];

        setCompanynamelist(data);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    // if (successstatus) {
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        projectId: projectid,
        clientId: clientid,
        Pagename: userData.Pagename,
        token: userData.token,
        profileImage: userData.profileImage,
        projectType: userData.projectType,
      })
    );
    // }
  }, []);

  const handleCircleChange = (e) => {
    setCircle(e.target.value);
    alert(e.target.value);

    axios({
      method: "GET",

      url: baseurl + "/api/get/list?field=division&circle=" + e.target.value,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },

      // data: userData,
    })
      .then((response) => {
        console.log(response.data);
        const RequestData = response.data;
        const wlist = RequestData.message || [];
        setDivlist(wlist);

        alert(wlist);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    validateField(e.target.name, e.target.value);
  };

  const handleDivisionChange = (e) => {
    setDivision(e.target.value);

    axios({
      method: "GET",

      url:
        baseurl + "/api/get/list?field=sub_division&division=" + e.target.value,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },

      // data: userData,
    })
      .then((response) => {
        console.log(response.data);
        const RequestData = response.data;
        const wlist = RequestData.message || [];
        setSubdivlist(wlist);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    validateField(e.target.name, e.target.value);
  };

  const handleSubDivisionChange = (e) => {
    setSubDivision(e.target.value);

    validateField(e.target.name, e.target.value);
  };

  const handleWingChange = (e) => {
    setWing(e.target.value);

    validateField(e.target.name, e.target.value);
  };

  const handleSchemeChange = (e) => {
    setScheme(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleTypeOfWorkChange = (e) => {
    setTypeOfWork(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleWorkCodeChange = (e) => {
    setWorkCode(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleWorkNameChange = (e) => {
    setWorkName(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleSanctionedAmountChange = (e) => {
    setSanctionedAmount(Number(e.target.value));
    validateField(e.target.name, e.target.value);
  };

  const handleLumpSumAmountChange = (e) => {
    setLumpSumAmount(Number(e.target.value));
    validateField(e.target.name, e.target.value);
  };

  const handleContractValueChange = (e) => {
    setContractValue(Number(e.target.value));
    validateField(e.target.name, e.target.value);
  };

  const handleAttachmentChange = (e) => {
    setAttachment(e.target.files[0]);
    validateField(e.target.name, e.target.files[0]);
  };

  const handleAgreementChange = (e) => {
    setagreement(e.target.files[0]);
    validateField(e.target.name, e.target.files[0]);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleTagsChange = (e) => {
    setTags(e.target.value.split(",").map((tag) => tag.trim()));
    validateField(e.target.name, e.target.value);
  };

  const handleMlaConstituencyChange = (e) => {
    setMlaConstituency(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleMpConstituencyChange = (e) => {
    setMpConstituency(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleGoNoChange = (e) => {
    setGoNo(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleGoIssueDateChange = (e) => {
    setGoIssueDate(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleDateOfSanctionChange = (e) => {
    setDateOfSanction(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleActualStartDateChange = (e) => {
    setActualStartDate(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleActualCompletionDateChange = (e) => {
    setActualCompletionDate(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleEccDateChange = (e) => {
    setEccDate(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleCcDateChange = (e) => {
    setCcDate(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
    validateField(e.target.name, e.target.value);
  };

  function handleSchemeOfWorksChange(e) {
    setScheme_of_works(e.target.value);
    validateField(e.target.name, e.target.value);
  }

  function handleProjectname(e) {
    console.log(e.target.value);
    setProjectname(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleprojecttype(e) {
    setProjecttype(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleroadtype(e) {
    setRoadtype(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);

    const value = e.target.value;
    if (value === "Add New Road Type") {
      setCanShow(true);
    }
  }
  function handleStartdate(e) {
    setStartdate(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleEnddate(e) {
    setEnddate(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleContactmail(e) {
    setContactmail(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  function handleBudget(e) {
    setBudget(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleCompanyname(e) {
    setCompanyname(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleContactperson(e) {
    setContactperson(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleAgreementDate(e) {
    setagreementDate(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  function handlecontractorname(e) {
    if (e.target.value === "New") {
      setModelStatus(true);
    } else {
      setModelStatus(false);

      setContractorname(e.target.value.replace(/\s+/g, ""));
      validateField(e.target.name, e.target.value);
    }
  }

  function handletypeofroad(e) {
    setTypeofroad(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  const validateField = (fieldName, value) => {
    if (fieldName === "projectname") {
      console.log(value);
      if (value.length != 0) {
        setProjectnameerror("");
        setProjectnameValid(true);
      } else {
        setProjectnameerror("Please enter your Project name");
        setProjectnameValid(false);
      }
    }
    if (fieldName === "projecttype") {
      if (value.length != 0) {
        setProjecttypeerror("");
        setProjecttypeValid(true);
      } else {
        setProjecttypeerror("Please enter your Project type");
        setProjecttypeValid(false);
      }
    }
    if (fieldName === "wing") {
      if (value.length != 0) {
        setWingError("");
        setWingValid(true);
      } else {
        setWingError("Please select wing");
        setWingValid(false);
      }
    }
    if (fieldName === "startdate") {
      if (value.length != 0) {
        setStartdateerror("");
        setStartdateValid(true);
      } else {
        setStartdateerror("Please select Start date");
        setStartdateValid(false);
      }
    }
    if (fieldName === "enddate") {
      if (value.length != 0) {
        setEnddateerror("");
        setEnddateValid(true);
      } else {
        setEnddateerror("Please select End date");
        setEnddateValid(false);
      }
    }
    if (fieldName === "Select") {
      if (value.length != 0) {
        setProjecttypeerror("");
        setProjecttypeValid(true);
      } else {
        setProjecttypeerror("Please select your Project type");
        setProjecttypeValid(false);
      }
    }
    if (fieldName === "contactmail") {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (value.length != 0) {
        console.log(value.match(regex));
        if (value.match(regex)) {
          setContactmailValid(true);
          setContactmailerror("");
        } else {
          setContactmailValid(false);
          setContactmailerror("Please enter valid email");
        }
      } else {
        setContactmailValid(false);
        setContactmailerror("Please enter valid email");
      }
    }
    if (fieldName === "budget") {
      if (value.length != 0) {
        setBudgeterror("");
        setBudgetValid(true);
      } else {
        setBudgeterror("Please enter your Budget");
        setBudgetValid(false);
      }
    }
    if (fieldName === "contactno") {
      if (value.length != 0 && value.length == 10) {
        setContactnoerror("");
        setContactnoValid(true);
      } else {
        setContactnoerror("Please enter valid Contact number");
        setContactnoValid(false);
      }
    }
    if (fieldName === "companyname") {
      console.log(value);

      if (value != "Select") {
        setCompanynameerror("");
        setCompanynameValid(true);
      } else {
        setCompanynameerror("Please select Company name");
        setCompanynameValid(false);
      }
    }
    if (fieldName === "contactperson") {
      console.log(value);
      if (value != "") {
        setContactpersonerror("");
        setContactpersonValid(true);
      } else {
        setContactpersonerror("Please enter Client Contact person");
        setContactpersonValid(false);
      }
    }

    // const [scheme_of_works,setScheme_of_works] = useState('');
    // const [circle, setCircle] = useState('');
    // const [division, setDivision] = useState('');
    // const [subDivision, setSubDivision] = useState('');
    // const [scheme, setScheme] = useState('');
    // const [typeOfWork, setTypeOfWork] = useState('');
    // const [workCode, setWorkCode] = useState('');
    // const [workName, setWorkName] = useState('');
    // const [sanctionedAmount, setSanctionedAmount] = useState(0);
    // const [lumpSumAmount, setLumpSumAmount] = useState(0);
    // const [contractValue, setContractValue] = useState(0);
    // const [attachment, setAttachment] = useState('');
    // const [description, setDescription] = useState('');
    // const [tags, setTags] = useState([]);
    // const [mlaConstituency, setMlaConstituency] = useState('');
    // const [mpConstituency, setMpConstituency] = useState('');
    // const [goNo, setGoNo] = useState('');
    // const [goIssueDate, setGoIssueDate] = useState('');
    // const [dateOfSanction, setDateOfSanction] = useState('');
    // const [actualStartDate, setActualStartDate] = useState('');
    // const [actualCompletionDate, setActualCompletionDate] = useState('');
    // const [eccDate, setEccDate] = useState('');
    // const [ccDate, setCcDate] = useState('');
    // const [remarks, setRemarks] = useState('');

    if (fieldName === "scheme") {
      console.log(value);
      if (value != "") {
        setSchemeError("");
        setSchemeValid(true);
      } else {
        setSchemeError("Please enter the scheme");
        setSchemeValid(false);
      }
    }

    if (fieldName === "scheme_of_works") {
      if (value !== "") {
        setScheme_of_worksError("");
        setScheme_of_worksValid(true);
      } else {
        setScheme_of_worksError("Please enter the scheme of works");
        setScheme_of_worksValid(false);
      }
    }

    if (fieldName === "circle") {
      if (value !== "") {
        setCircleError("");
        setCircleValid(true);
      } else {
        setCircleError("Please enter the circle");
        setCircleValid(false);
      }
    }

    if (fieldName === "division") {
      if (value !== "") {
        setDivisionError("");
        setDivisionValid(true);
      } else {
        setDivisionError("Please enter the division");
        setDivisionValid(false);
      }
    }

    if (fieldName === "subDivision") {
      if (value !== "") {
        setSubDivisionError("");
        setSubDivisionValid(true);
      } else {
        setSubDivisionError("Please enter the subdivision");
        setSubDivisionValid(false);
      }
    }

    if (fieldName === "scheme") {
      if (value !== "") {
        setSchemeError("");
        setSchemeValid(true);
      } else {
        setSchemeError("Please enter the scheme");
        setSchemeValid(false);
      }
    }

    if (fieldName === "typeOfWork") {
      if (value !== "") {
        setTypeOfWorkError("");
        setTypeOfWorkValid(true);
      } else {
        setTypeOfWorkError("Please enter the type of work");
        setTypeOfWorkValid(false);
      }
    }

    if (fieldName === "workCode") {
      if (value !== "") {
        setWorkCodeError("");
        setWorkCodeValid(true);
      } else {
        setWorkCodeError("Please enter the work code");
        setWorkCodeValid(false);
      }
    }

    if (fieldName === "workName") {
      if (value !== "") {
        setWorkNameError("");
        setWorkNameValid(true);
      } else {
        setWorkNameError("Please enter the work name");
        setWorkNameValid(false);
      }
    }

    if (fieldName === "sanctionedAmount") {
      if (value > 0) {
        setSanctionedAmountError("");
        setSanctionedAmountValid(true);
      } else {
        setSanctionedAmountError("Please enter a valid sanctioned amount");
        setSanctionedAmountValid(false);
      }
    }

    if (fieldName === "lumpSumAmount") {
      if (value > 0) {
        setLumpSumAmountError("");
        setLumpSumAmountValid(true);
      } else {
        setLumpSumAmountError("Please enter a valid lump sum amount");
        setLumpSumAmountValid(false);
      }
    }

    if (fieldName === "contractValue") {
      if (value > 0) {
        setContractValueError("");
        setContractValueValid(true);
      } else {
        setContractValueError("Please enter a valid contract value");
        setContractValueValid(false);
      }
    }

    if (fieldName === "attachment") {
      const filesize1 = attachment.size;
      if (filesize1 != 0) {
        setAttachmentError("");
        setAttachmentValid(true);
      } else {
        setAttachmentError("Please attach a file");
        setAttachmentValid(false);
      }
    }

    if (fieldName === "description") {
      if (value !== "") {
        setDescriptionError("");
        setDescriptionValid(true);
      } else {
        setDescriptionError("Please enter a description");
        setDescriptionValid(false);
      }
    }

    if (fieldName === "tags") {
      if (value.length > 0) {
        setTagsError("");
        setTagsValid(true);
      } else {
        setTagsError("Please enter the tags");
        setTagsValid(false);
      }
    }

    if (fieldName === "mlaConstituency") {
      if (value !== "") {
        setMlaConstituencyError("");
        setMlaConstituencyValid(true);
      } else {
        setMlaConstituencyError("Please enter the MLA constituency");
        setMlaConstituencyValid(false);
      }
    }

    if (fieldName === "mpConstituency") {
      if (value !== "") {
        setMpConstituencyError("");
        setMpConstituencyValid(true);
      } else {
        setMpConstituencyError("Please enter the MP constituency");
        setMpConstituencyValid(false);
      }
    }

    if (fieldName === "goNo") {
      if (value !== "") {
        setGoNoError("");
        setGoNoValid(true);
      } else {
        setGoNoError("Please enter the GO number");
        setGoNoValid(false);
      }
    }

    if (fieldName === "goIssueDate") {
      if (value !== "") {
        setGoIssueDateError("");
        setGoIssueDateValid(true);
      } else {
        setGoIssueDateError("Please enter the GO issue date");
        setGoIssueDateValid(false);
      }
    }

    if (fieldName === "dateOfSanction") {
      if (value !== "") {
        setDateOfSanctionError("");
        setDateOfSanctionValid(true);
      } else {
        setDateOfSanctionError("Please enter the date of sanction");
        setDateOfSanctionValid(false);
      }
    }

    // if (fieldName === 'actualStartDate') {
    //   if (value !== "") {
    //     setActualStartDateError('');
    //     setActualStartDateValid(true);
    //   } else {
    //     setActualStartDateError('Please enter the actual start date');
    //     setActualStartDateValid(false);
    //   }
    // }

    // if (fieldName === 'actualCompletionDate') {
    //   if (value !== "") {
    //     setActualCompletionDateError('');
    //     setActualCompletionDateValid(true);
    //   } else {
    //     setActualCompletionDateError('Please enter the actual completion date');
    //     setActualCompletionDateValid(false);
    //   }
    // }

    if (fieldName === "eccDate") {
      if (value !== "") {
        setEccDateError("");
        setEccDateValid(true);
      } else {
        setEccDateError("Please enter the ECC date");
        setEccDateValid(false);
      }
    }

    if (fieldName === "ccDate") {
      if (value !== "") {
        setCcDateError("");
        setCcDateValid(true);
      } else {
        setCcDateError("Please enter the CC date");
        setCcDateValid(false);
      }
    }

    if (fieldName === "remarks") {
      if (value !== "") {
        setRemarksError("");
        setRemarksValid(true);
      } else {
        setRemarksError("Please enter remarks");
        setRemarksValid(false);
      }
    }
  };

  const closeModel = () => {
    setModelStatus(false);

    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list/company/status/accepted",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },

      // data: userData,
    })
      .then((response) => {
        console.log(response.data);
        const RequestData = response.data;
        const Datalist = RequestData[0];

        const data = Datalist.company_names || [];

        setCompanynamelist(data);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });
  };
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    console.log(year);
    console.log(month);
    console.log(day);
    return `${year}-${month}-${day}`;
  }

  function onSubmitEvent1(event) {
    event.preventDefault();
    alert("hello");
    setSuccessstatus(true);
    setResourcests(true);
    console.log("projectnameValid:", projectnameValid);
    console.log("projecttypeValid:", projecttypeValid);
    console.log("startdateValid:", startdateValid);
    console.log("enddateValid:", enddateValid);
    console.log("budgetValid:", budgetValid);
    console.log("contactpersonValid:", contactpersonValid);
    console.log("contactmailValid:", contactmailValid);
    console.log("contactnoValid:", contactnoValid);
    console.log("scheme_of_worksValid:", scheme_of_worksValid);
    console.log("circleValid:", circleValid);
    console.log("divisionValid:", divisionValid);
    console.log("subDivisionValid:", subDivisionValid);
    console.log("schemeValid:", schemeValid);
    console.log("typeOfWorkValid:", typeOfWorkValid);
    console.log("workCodeValid:", workCodeValid);
    console.log("workNameValid:", workNameValid);
    console.log("sanctionedAmountValid:", sanctionedAmountValid);
    console.log("lumpSumAmountValid:", lumpSumAmountValid);
    console.log("contractValueValid:", contractValueValid);
    console.log("attachmentValid:", attachmentValid);
    console.log("descriptionValid:", descriptionValid);
    console.log("tagsValid:", tagsValid);
    console.log("mlaConstituencyValid:", mlaConstituencyValid);
    console.log("mpConstituencyValid:", mpConstituencyValid);
    console.log("goNoValid:", goNoValid);
    console.log("goIssueDateValid:", goIssueDateValid);
    console.log("dateOfSanctionValid:", dateOfSanctionValid);
    console.log("eccDateValid:", eccDateValid);
    console.log("ccDateValid:", ccDateValid);
    console.log("remarksValid:", remarksValid);
    console.log("contractorname:", contractorname);
    console.log("agreementDate:", agreementDate);
    console.log("agreement:", agreement);

    if (
      projectnameValid &&
      projecttypeValid &&
      startdateValid &&
      enddateValid &&
      budgetValid &&
      //  && companynameValid
      // contactpersonValid &&
      // contactmailValid &&
      // contactnoValid &&
      scheme_of_worksValid &&
      circleValid &&
      divisionValid &&
      subDivisionValid &&
      schemeValid &&
      typeOfWorkValid &&
      workCodeValid &&
      workNameValid &&
      sanctionedAmountValid &&
      lumpSumAmountValid &&
      contractValueValid &&
      attachmentValid &&
      descriptionValid &&
      tagsValid &&
      mlaConstituencyValid &&
      mpConstituencyValid &&
      goNoValid &&
      goIssueDateValid &&
      dateOfSanctionValid &&
      eccDateValid &&
      ccDateValid &&
      remarksValid
    ) {
      let userData1 = new FormData();
      userData1.append("project_name", projectname);
      userData1.append("client_contact_person", contactperson);
      userData1.append("company_name", companyname);
      userData1.append("contact_number", contactno);
      userData1.append("contact_email", contactmail);
      userData1.append("est_start_date", startdate);
      userData1.append("est_end_date", enddate);
      userData1.append("budget", budget);
      userData1.append("project_type", projecttype);
      userData1.append("type_of_road", roadtype);

      userData1.append("scheme", scheme);
      userData1.append("type_of_work", typeOfWork);
      userData1.append("work_code", workCode);
      userData1.append("work_name", workName);
      userData1.append("go_number", goNo);
      userData1.append("go_issue_date", goIssueDate);
      userData1.append("sanctioned_amnt", sanctionedAmount);
      userData1.append("date_of_sanction", dateOfSanction);
      userData1.append("other_lumpsum_amnt", lumpSumAmount);
      userData1.append("tags", tags);
      userData1.append("remarks", remarks);
      userData1.append("ecc_date", eccDate);
      userData1.append("cc_date", ccDate);
      userData1.append("mla_constituency", mlaConstituency);
      userData1.append("mp_constituency", mpConstituency);
      userData1.append("attachment", attachment);
      userData1.append("description", description);
      userData1.append("condition_of_agreement", agreement);
      userData1.append("agreement_date", agreementDate);
      userData1.append("contract_company", contractorname);

      axios({
        method: "POST",
        url: baseurl + "/api/project/create",

        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + userData.token,
        },
        data: userData1,
      })
        .then((response) => {
          const dat = response.data;
          console.log(JSON.stringify(dat));
          setProjectid(dat.project_id);
          setClientid(dat.client_id);
          setValidationMsg("");
          Project_Creation_status_update(dat.project_id);
        })
        .catch((error) => {
          console.log(error);
          //setErrormsg(error.response.data.message)
        });
    } else {
      setValidationMsg("Please fill all the required fields");
    }
  }

  function Project_Creation_status_update(projid) {
    axios({
      method: "PUT",
      url:
        baseurl +
        "/api/project/update/project_creation_status?project_id=" +
        projid +
        "&project_creation_status=1",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const dat = response.data;

        setSuccessstatus(true);
        setResourcests(true);
      })
      .catch((error) => {
        console.log(error);
        //setErrormsg(error.response.data.message)
      });
  }

  const todayDate = getTodayDate();
  console.log(todayDate);

  const handleAddRoleChange = (e) => {
    setAddRole(e.target.value);
    // setFormErr('')
  };

  const handleRolebtn = () => {
    console.log(addRole);



    let userData1 = {
      type_of_road: addRole,
    };
    axios({
      method: "POST",
      url: `${baseurl}/api/list/add?user_id=${userData.userId}&field=type_of_road`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
      data: userData1,
    })
      .then((response) => {
        console.log("Response Data:", response.data);
        setAddRole("");
        setCanShow(false);
        setAdded(true)
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        // setFormErr(errorMessage);
        console.log(errorMessage);
      });




  };

  return (
    <>
      {/* {success && <Na
        vigate to="/FreeTrialRegistration" />} */}
      {successstatus && projecttype === "Building" && (
        <ButtonLink btnPath="/PlanUpdate" />
      )}
      {successstatus && projecttype === "Road" && (
        <ButtonLink
          btnPath="/ResourceLocationDetails"
        //  {successstatus && projecttype==="Road" &&<ButtonLink btnPath="/Boq"
        />
      )}
      <div className="cont-margin tmar ">
        <div>
          <div className="table1bdstyle">
            <div className="table1st">
              <div class="row ">
                {/* <HorizontalLine stage={3} /> */}
                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                  <form class="row d-flex justify-content-center align-center  blacktext">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6  pb-3">
                        <label for="formFileLg" className="login-text">
                          Project Name
                        </label>
                        <center>
                          <InputTextField
                            valueText={projectname}
                            inputName="projectname"
                            inputId="projectname"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="Project name"
                            handleInputChange={handleProjectname}
                            PreventEnter={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                          ></InputTextField>

                          <div className="login-error">{projectnameerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Project Type
                        </label>
                        <center>
                          <select
                            className="project-box"
                            id="projecttype"
                            name="projecttype"
                            //  defaultValue={companyname}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={projecttype}
                            onChange={handleprojecttype}
                          >
                            <option value="">Select</option>
                            <option value="Building">Building</option>
                            <option value="Road">Road</option>
                          </select>
                          <div className="login-error">{projecttypeerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Road Type
                        </label>
                        <center>
                          {/* <InputTextField
                            handleInputChange={handleprojecttype}
                            valueText={projecttype}
                            inputName="projecttype"
                            inputId="projecttype"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="Project type"
                          ></InputTextField> */}
                          {canShow ? (
                            <div>
                              <div className="input-group mb-3">
                                <input
                                  onChange={handleAddRoleChange}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Road Type"
                                  value={addRole}
                                />
                                <div className="input-group-append">
                                  <button
                                    onClick={handleRolebtn}
                                    className="btn border"
                                    style={{ backgroundColor: "#dedee3" }}
                                    type="button"
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                              <div style={{ fontSize: '14px' }} className="text-danger d-flex justify-content-end m-0"><span style={{cursor:'pointer'}} onClick={() => {
                                setCanShow(false)
                                setAddRole('')
                              }}>Cancel</span></div>
                            </div>
                          ) : (
                            <select
                              className="project-box"
                              id="roadtype"
                              name="roadtype"
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              onChange={handleroadtype}
                            >
                              <option value="">Select</option>
                              <option value="Add New Road Type">
                                Add New Road Type
                              </option>
                              {typeofroadlist.length !== 0 &&
                                typeofroadlist.map((name, index) => (
                                  <option key={index} value={name}>
                                    {name}
                                  </option>
                                ))}
                            </select>
                          )}
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Estimate Start date
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleStartdate}
                            valueText={startdate}
                            inputName="startdate"
                            inputId="startdate"
                            classname="project-box"
                            inputType="date"
                            placeholderValue="Start Date"
                            mindate={todayDate}
                          ></InputTextField>

                          <div className="login-error">{startdateeerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Estimate End date
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleEnddate}
                            valueText={enddate}
                            inputName="enddate"
                            inputId="enddate"
                            classname="project-box"
                            inputType="date"
                            placeholderValue="End date"
                            mindate={todayDate}
                          ></InputTextField>

                          <div className="login-error">{enddateeerror}</div>
                        </center>
                      </div>
                      {/* <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3 ">
                  <label
                    for="dob"
        
                    className="login-text"
                  >
                 
                  </label>
                  <center>
                  <input
                    type="date"
                    id="dob"
                    classname="formboxnew"
                     max={values.maxyear}
                    // min={values.minyear}
                    name="dob"
                    placeholder=""
                    // onChange={handleChange('dob')}
                    // defaultValue={values.dob}
                    // value={values.dob}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                  />
                  </center>
                   <div className="sign-error"> {values.doberror}</div> }
                </div> */}

                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Budget
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleBudget}
                            valueText={budget}
                            inputName="budget"
                            inputId="budget"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="Budget"
                          ></InputTextField>
                        </center>
                        <div className="login-error">{budgeterror}</div>
                      </div>
                      {/* <div class="col-12 col-sm-12  col-md-3 col-lg-3 ol-md-6 pb-3">
                        <label
                          for="formFileLg"
                          class="form-label"
                          className="login-text"
                        >
                          Company Name
                        </label>
                        <select
                          className="project-box"
                          id="companyname"
                          name="companyname"
                        
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={companyname}
                          onChange={handleCompanyname}
                        >
                          <option value="" selected>
                            Select
                          </option>
                         

                          {companynamelist.map((name, index) => (
                            <option key={index} value={name}>
                              {name}
                            </option>
                          ))}
                        </select>
                        <div className="login-error">{companynameerror}</div>
                      </div>
                      <div class="col-12 col-sm-12  col-md-3 col-lg-3 ol-md-6 pb-3">
                      
                        <label for="formFileLg" className="login-text">
                          Contact Person
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleContactperson}
                            valueText={contactperson}
                            inputName="contactperson"
                            inputId="contactperson"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="Contact Person"
                          ></InputTextField>
                        </center>
                        <div className="login-error">{contactpersonerror}</div>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Contact Number
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleContactno}
                            valueText={contactno}
                            inputName="contactno"
                            inputId="contactno"
                            classname="project-box"
                            maxLen={10}
                            inputType="text"
                            placeholderValue="Contact number"
                          ></InputTextField>
                        </center>
                        <div className="login-error">{contactnoerror}</div>
                      </div>

                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Contact Email
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleContactmail}
                            valueText={contactmail}
                            inputName="contactmail"
                            inputId="contactmail"
                            classname="project-box"
                            inputType="text"
                         
                          ></InputTextField>

                          <div className="login-error">{contactmailerror}</div>
                        </center>
                      </div> */}
                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="schemeOfWorks" className="login-text">
                          Scheme of Works
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleSchemeOfWorksChange}
                            valueText={scheme_of_works}
                            inputName="scheme_of_works"
                            inputId="scheme_of_works"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">
                            {scheme_of_worksError}
                          </div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="circle" className="login-text">
                          Wing
                        </label>
                        <center>
                          {/* <InputTextField
                            handleInputChange={handleCircleChange}
                            valueText={circle}
                            inputName="circle"
                            inputId="circle"
                            classname="project-box"
                            inputType="text"
                          /> */}
                          <select
                            className="project-box"
                            id="wing"
                            name="wing"
                            value={wing}
                            onChange={handleWingChange}
                          >
                            <option value="" selected>
                              Select
                            </option>
                            {winglist.length != 0 &&
                              winglist.map((name, index) => (
                                <option key={index} value={name}>
                                  {name}
                                </option>
                              ))}
                          </select>
                        </center>
                        <div className="login-error">{circleError}</div>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="circle" className="login-text">
                          Circle
                        </label>
                        <center>
                          {/* <InputTextField
                            handleInputChange={handleCircleChange}
                            valueText={circle}
                            inputName="circle"
                            inputId="circle"
                            classname="project-box"
                            inputType="text"
                          /> */}
                          <select
                            className="project-box"
                            id="circle"
                            name="circle"
                            value={circle}
                            onChange={handleCircleChange}
                          >
                            <option value="" selected>
                              Select
                            </option>
                            {circlelist.length != 0 &&
                              circlelist.map((name, index) => (
                                <option key={index} value={name}>
                                  {name}
                                </option>
                              ))}
                          </select>
                        </center>
                        <div className="login-error">{circleError}</div>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="division" className="login-text">
                          Division
                        </label>
                        <center>
                          {/* <InputTextField
                            handleInputChange={handleDivisionChange}
                            valueText={division}
                            inputName="division"
                            inputId="division"
                            classname="project-box"
                            inputType="text"
                          /> */}

                          <select
                            className="project-box"
                            id="division"
                            name="division"
                            value={division}
                            onChange={handleDivisionChange}
                          >
                            <option value="" selected>
                              Select
                            </option>
                            {divlist.length != 0 &&
                              divlist.map((name, index) => (
                                <option key={index} value={name}>
                                  {name}
                                </option>
                              ))}
                          </select>

                          <div className="login-error">{divisionError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="subDivision" className="login-text">
                          Sub-Division
                        </label>
                        <center>
                          <select
                            className="project-box"
                            id="subDivision"
                            name="subDivision"
                            value={subDivision}
                            onChange={handleSubDivisionChange}
                          >
                            <option value="" selected>
                              Select
                            </option>
                            {subdivlist.length != 0 &&
                              subdivlist.map((name, index) => (
                                <option key={index} value={name}>
                                  {name}
                                </option>
                              ))}
                          </select>

                          <div className="login-error">{subDivisionError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="scheme" className="login-text">
                          Scheme
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleSchemeChange}
                            valueText={scheme}
                            inputName="scheme"
                            inputId="scheme"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">{schemeError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="typeOfWork" className="login-text">
                          Type of Work
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleTypeOfWorkChange}
                            valueText={typeOfWork}
                            inputName="typeOfWork"
                            inputId="typeOfWork"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">{typeOfWorkError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="workCode" className="login-text">
                          Work Code
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleWorkCodeChange}
                            valueText={workCode}
                            inputName="workCode"
                            inputId="workCode"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">{workCodeError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="workName" className="login-text">
                          Work Name
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleWorkNameChange}
                            valueText={workName}
                            inputName="workName"
                            inputId="workName"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">{workNameError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label
                          htmlFor="sanctionedAmount"
                          className="login-text"
                        >
                          Sanctioned Amount
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleSanctionedAmountChange}
                            valueText={sanctionedAmount}
                            inputName="sanctionedAmount"
                            inputId="sanctionedAmount"
                            classname="project-box"
                            inputType="number"
                          />
                          <div className="login-error">
                            {sanctionedAmountError}
                          </div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="lumpSumAmount" className="login-text">
                          Lump Sum Amount
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleLumpSumAmountChange}
                            valueText={lumpSumAmount}
                            inputName="lumpSumAmount"
                            inputId="lumpSumAmount"
                            classname="project-box"
                            inputType="number"
                          />
                          <div className="login-error">
                            {lumpSumAmountError}
                          </div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="contractValue" className="login-text">
                          Contract Value
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleContractValueChange}
                            valueText={contractValue}
                            inputName="contractValue"
                            inputId="contractValue"
                            classname="project-box"
                            inputType="number"
                          />
                          <div className="login-error">
                            {contractValueError}
                          </div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="attachment" className="login-text">
                          Attachment
                        </label>
                        {/* <center>
          <InputTextField
            handleInputChange={handleAttachmentChange}
            valueText={attachment}
            inputName="attachment"
            inputId="attachment"
            classname="project-box"
            inputType="file"
          />
           <div className="login-error">{attachmentError}</div>
        </center> */}
                        <button
                          type="button"
                          className="project-box "
                          onClick={() => handleClick()}
                        >
                          {attachment != undefined && attachment != ""
                            ? "Uploaded "
                            : "Upload"}
                        </button>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={handleAttachmentChange}
                          style={{ display: "none" }}
                          name="attachment"
                          id="attachment"
                          accept="image/jpeg"
                        />
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="description" className="login-text">
                          Description
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleDescriptionChange}
                            valueText={description}
                            inputName="description"
                            inputId="description"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">{descriptionError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="tags" className="login-text">
                          Tags
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleTagsChange}
                            valueText={tags.join(", ")}
                            inputName="tags"
                            inputId="tags"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">{tagsError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="mlaConstituency" className="login-text">
                          MLA Constituency
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleMlaConstituencyChange}
                            valueText={mlaConstituency}
                            inputName="mlaConstituency"
                            inputId="mlaConstituency"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">
                            {mlaConstituencyError}
                          </div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="mpConstituency" className="login-text">
                          MP Constituency
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleMpConstituencyChange}
                            valueText={mpConstituency}
                            inputName="mpConstituency"
                            inputId="mpConstituency"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">
                            {mpConstituencyError}
                          </div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="goNo" className="login-text">
                          GO No
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleGoNoChange}
                            valueText={goNo}
                            inputName="goNo"
                            inputId="goNo"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">{goNoError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="goIssueDate" className="login-text">
                          GO Issue Date
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleGoIssueDateChange}
                            valueText={goIssueDate}
                            inputName="goIssueDate"
                            inputId="goIssueDate"
                            classname="project-box"
                            inputType="date"
                          />
                          <div className="login-error">{goIssueDateError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="dateOfSanction" className="login-text">
                          Date of Sanction
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleDateOfSanctionChange}
                            valueText={dateOfSanction}
                            inputName="dateOfSanction"
                            inputId="dateOfSanction"
                            classname="project-box"
                            inputType="date"
                          />
                          <div className="login-error">
                            {dateOfSanctionError}
                          </div>
                        </center>
                      </div>

                      {/* <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="actualStartDate" className="login-text">
                          Actual Start Date
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleActualStartDateChange}
                            valueText={actualStartDate}
                            inputName="actualStartDate"
                            inputId="actualStartDate"
                            classname="project-box"
                            inputType="date"
                          />
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label
                          htmlFor="actualCompletionDate"
                          className="login-text"
                        >
                          Actual Completion Date
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleActualCompletionDateChange}
                            valueText={actualCompletionDate}
                            inputName="actualCompletionDate"
                            inputId="actualCompletionDate"
                            classname="project-box"
                            inputType="date"
                          />
                        </center>
                      </div> */}

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="eccDate" className="login-text">
                          ECC Date
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleEccDateChange}
                            valueText={eccDate}
                            inputName="eccDate"
                            inputId="eccDate"
                            classname="project-box"
                            inputType="date"
                          />
                          <div className="login-error">{eccDateError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="ccDate" className="login-text">
                          CC Date
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleCcDateChange}
                            valueText={ccDate}
                            inputName="ccDate"
                            inputId="ccDate"
                            classname="project-box"
                            inputType="date"
                          />
                          <div className="login-error">{ccDateError}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="remarks" className="login-text">
                          Remarks
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleRemarksChange}
                            valueText={remarks}
                            inputName="remarks"
                            inputId="remarks"
                            classname="project-box"
                            inputType="text"
                          />
                          <div className="login-error">{remarksError}</div>
                        </center>
                      </div>

                      {modelStatus && (
                        <AddContractor
                          status={modelStatus}
                          btnClick={closeModel}
                        />
                      )}

                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Contractor
                        </label>
                        <center>
                          <select
                            className="project-box"
                            id="contractorname"
                            name="contractorname"
                            //  defaultValue={companyname}
                            onKeyPress={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={contractorname}
                            onChange={handlecontractorname}
                          >
                            <option value="" selected>
                              Select
                            </option>
                            <option value="New">New</option>
                            {companynamelist.length != 0 &&
                              companynamelist.map((name, index) => (
                                <option key={index} value={name}>
                                  {name}
                                </option>
                              ))}
                          </select>
                          <div className="login-error">{projecttypeerror}</div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="ccDate" className="login-text">
                          Agreement Date
                        </label>
                        <center>
                          <InputTextField
                            handleInputChange={handleAgreementDate}
                            valueText={agreementDate}
                            inputName="agreementDate"
                            inputId="agreementDate"
                            classname="project-box"
                            inputType="date"
                          />
                          <div className="login-error">
                            {agreementDateError}
                          </div>
                        </center>
                      </div>

                      <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                        <label htmlFor="attachment" className="login-text">
                          Condition of Agreement
                        </label>

                        <button
                          type="button"
                          className="project-box "
                          onClick={() => handleClick1()}
                        >
                          {agreement != undefined && agreement != ""
                            ? "Uploaded "
                            : "Upload"}
                        </button>
                        <input
                          type="file"
                          ref={hiddenFileInput1}
                          onChange={handleAgreementChange}
                          style={{ display: "none" }}
                          name="agreement"
                          id="agreement"
                          accept="image/jpeg"
                        />
                      </div>
                    </div>

                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 ">
                      <div className="login-error pb-3">
                        <center>{validationMsg}</center>
                      </div>
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                          onSubmit={onSubmitEvent1}
                        // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid
                        //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                        >
                          <b>Submit</b>
                        </button>
                      </center>{" "}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FormPage;
