import React, { useState } from 'react';
import './ss.css'; // Make sure you have your CSS file imported
import a from '../../assets/images/Status/accepted.png'
const WeareHere = () => {
  const [startIndex, setStartIndex] = useState(0);
  const images = [
    a,
    a, a, a, a, a, a, a, a, a, a,
  ];

  const numImagesToShow = 6; // Number of images to show at once
  const visibleImages = images.slice(startIndex, startIndex + numImagesToShow);

  const prevImages = () => {
    setStartIndex((prevIndex) =>
      prevIndex - numImagesToShow >= 0 ? prevIndex - numImagesToShow : 0
    );
  };

  const nextImages = () => {
    setStartIndex((prevIndex) =>
      prevIndex + numImagesToShow < images.length
        ? prevIndex + numImagesToShow
        : images.length - numImagesToShow
    );
  };

  return (
    <div>
      <div className="image-slider">
        <div className="image-container">
          {visibleImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index + startIndex + 1}`}
              className="image"
            />
          ))}
        </div>
        <button className="prev" onClick={prevImages}>
          &#10094;
        </button>
        <button className="next" onClick={nextImages}>
          &#10095;
        </button>
      </div>
    </div>
  );
};

export default WeareHere;
