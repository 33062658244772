
import Project from '../Component/CreateProject/CreateProject';

function HomePage() {
  return (
    <div>
     <Project />
     
    </div>
  );
}

export default HomePage;
