import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import './LoginForm.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import {baseurl} from '../BaseUrl/Baseurl'
import ButtonLink from '../ButtonLink/ButtonLink'
import axios from 'axios'
function Login() {
 
    const [isPasswordShown, setIsPasswordShown] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailValid, setEmailValid] = useState(false)
    const [emailerror, setEmailerror] = useState('')
    const [passwordValid, setPasswordValid] = useState(false)
    const [passworderror, setPassworderror] = useState('')
    const [isLoggedIn, updateLogin] = useState('')
    const[successstatus,setSuccessstatus]=useState('')
    const[uname,setUname]=useState('')
    const[userid,setUserid]=useState('')
    const[role,setRole]=useState('')
    const[token,setToken]=useState('')
    const [errormsg, setErrormsg] = useState('')
    const userData = useSelector((state) => state.userDetails)
    const[clientid,setClientid]=useState('')
    const[profileImg,setProfileImg] = useState('')
    const dispatch = useDispatch()
      useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        if (successstatus) {   
          dispatch(
            updateLoginStatus({
              isLoggedIn: true,
              userName:uname,
              userId:userid,
              role:role,
              clientId:clientid,
              profileImage:profileImg,
              token:token,
              projectType:userData.projectType,
              pagename:userData.pagename,
              projectId:userData.projectId
            })
          )
        }
      }, [successstatus])   
 
      const togglePasswordVisiblity= (event) => {
        setIsPasswordShown(!isPasswordShown )
        event.preventDefault()
      }
      function onpasswordChange(e) {
   
        setPassword(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
      }
      function onemailChange(e) {
   
        setEmail(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
      }

   
      const strongRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
      )

      const validateField = (fieldName, value) => {
  
        if (fieldName === 'password') {
          let passwordValid = value != '' && value.match(strongRegex)
    
       
          setPasswordValid(passwordValid)
        
          if (passwordValid === null || passwordValid === undefined) {
            setPassworderror(
              'Password should be more than or equal to 6 character and it should contain lowercase,uppercase,numeric and special character'
            )
            setPasswordValid(false)
          } else {
            setPassworderror('')
            setPasswordValid(true)
          }
        }
       
        if (fieldName === 'email') {
          //   const regex =
          // /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
          
           
          //   let emailValid = value != '' && value.match(regex)
          //   console.log(emailValid)
         
          //   setEmailValid(emailValid)
          
          //   if (emailValid === null || emailValid === undefined) {
          //     setEmailerror(
          //       'Please Enter your valid Email'
          //     )
          //     setEmailValid(false)
          //   } else {
          //     setEmailerror('')
          //     setEmailValid(true)
          //   }
              if (value.length==0) {
              setEmailerror(
                'Please Enter your valid Username'
              )
              setEmailValid(false)
            } else {
              setEmailerror('')
              setEmailValid(true)
            }
          }
    }
    function onSubmitEvent(event) {
        event.preventDefault()

        if (emailValid && passwordValid) {
            console.log(email)
            console.log(password)

              let formData = new FormData()
              formData.append('username', email)
              formData.append('password', password)
           
          
                axios({
                  method: 'POST',
            
                  url: baseurl+'/api/login', formData,
            
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                  data:formData,
                 
                })
                .then((response) => {
           
               const data=response.data
                 console.log(JSON.stringify(data))
                  setUname(data.name)
                  setUserid(data.userid)
                  setToken(data.token)
                  setRole(data.role)
                  
                  setClientid(data.client_id)
                  setSuccessstatus(true)
                  setProfileImg(data.profile_image)
                  

                })
                .catch((error) => {
                  console.log(error)
                  console.log(error.response.data.message)
                  setErrormsg(error.response.data.message)
                })
                 
        }
      
    }
    return (
        <>
            <div>
                {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
                {successstatus && <ButtonLink btnPath="/AdminConsole" />}

                <div class="row pb-5 pt-5">
                    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 pb-5 ">
                        <div class="trialformtext">
                            <center>
                                <b>Login into account </b>
                            </center>
                        </div>
                        <p> <center>
                            use your credentials to access your account
                        </center></p>
                       
                  {errormsg.length != 0 && (
                     <div class="pt-3">
                     <center>
                    <p className="warningfont">{errormsg} 
                   
                    </p>
                    </center>
              </div>
                  )}
                 
            
                        <form
                            class="row d-flex justify-content-center align-center logincont "
                            // onSubmit={onSubmitEvent}
                        >

                            <div class="col-12 col-sm-12 col-lg-11 col-md-12 pt-3">
                            <center>
                    
                                <InputTextField
                                       handleInputChange={onemailChange}
                                    //     onKeydown={Loginspace}
                                    valueText={email}
                                    inputName="email"
                                    inputId="email"
                                    classname="login-box3"
                                    inputType="text"
                                    placeholderValue="Username"
                                    PreventEnter={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                      }}
                                ></InputTextField></center>
                    

                                <div className="login-error">{emailerror}</div>
                            </div>
                            <div class="col-12 col-sm-12 col-lg-11 col-md-12 pt-3">
<center>            <InputTextField
                                     handleInputChange={onpasswordChange}
                                    valueText={password}
                                    inputName="password"
                                    inputId="password"
                                    classname="login-box3"
                                    maxLen={16}
                                    placeholderValue="Password"
                                    inputType={isPasswordShown  ? 'text' : 'password'}
                                    PreventEnter={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                      }}
                                ></InputTextField></center>
                    
                    {isPasswordShown ? (
                  <AiFillEye
                    className="eye-style"
                    onClick={togglePasswordVisiblity}
                  size={20}
                    
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="eye-style"
                    onClick={togglePasswordVisiblity}
                    size={20}
                  />
                )}
                                <div className="login-error">
                                    {passworderror}

                                </div>
                            </div>

                            <div class="pt-4 pb-4 col-12 col-sm-12 col-md-6 col-lg-6">
                                <center>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label class="form-check-label" for="flexCheckDefault">Remember me</label>
                                </div>
                                </center>
                            </div>

                            <div class="pt-4 pb-4 col-12 col-sm-12 col-md-6 col-lg-6">
                                <center>
                                    <a href="/" className="forgot">
                                        Forgot password?
                                    </a>
                                </center>
                            </div>

                            <div class="col-sm-12 col-lg-12 col-12 col-md-12">
                                <center>
                                    <button type="submit"  className="login-btn3" name="submit" onClick={onSubmitEvent}>
                                        <b>LOGIN TO YOUR ACCOUNT</b>
                                    </button>
                                </center>{' '}
                            </div>
                            <div class="col-sm-12 col-lg-12 col-12 col-md-12 pt-3 ">
                  <center>
                  If you don't have an account? <a href="/Registration" class="login-lnk">
                     <u> Register Now</u>
                    </a>
                   
                  </center>
                </div>

                        </form>
                    </div>

                </div>
            </div>




        </>
    )
}

export default Login
