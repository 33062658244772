import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {  Modal } from "react-bootstrap";
import "./Modal.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";

export default function Model({ btnClick, status, modalname, data, tab }) {
  const [projectname, setProjectname] = useState("");
  const [projectnameValid, setProjectnameValid] = useState(false);
  const [projectnameerror, setProjectnameerror] = useState("");
  const [contactno, setContactno] = useState("");
  const [contactnoValid, setContactnoValid] = useState(false);
  const [contactnoerror, setContactnoerror] = useState("");
  const [clientname, setClientname] = useState("");
  const [clientnameValid, setClientnameValid] = useState(false);
  const [clientnameerror, setClientnameerror] = useState("");
  const [circle, setcircle] = useState("");
  const [circleValid, setcircleValid] = useState(false);
  const [circleerror, setcircleerror] = useState("");
  const [modalVisible, setModalVisible] = useState(true);
  const [division, setdivision] = useState("");
  const [divisionValid, setdivisionValid] = useState(false);
  const [divisionerror, setdivisionerror] = useState("");
  const [contactmail, setContactmail] = useState("");
  const [contactmailValid, setContactmailValid] = useState(false);
  const [contactmailerror, setContactmailerror] = useState("");
  const [divisionlist, setdivisionlist] = useState([]);

  const userData = useSelector((state) => state.userDetails);
  // useEffect(() => {

  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  //   axios({
  //     method: "GET",

  //     url: baseurl + "/api/request/trial/list/company/status/accepted",

  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       Authorization: "Bearer " + userData.token,
  //     },

  //     // data: userData,
  //   })
  //     .then((response) => {
  //       console.log(response.data);
  //       const RequestData = response.data;
  //       const Datalist = RequestData[0];
  //       const data = Datalist.company_names;
  //       setdivisionlist(data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       // setErrormsg(error.response.data.message)
  //     });
  // }, []);

  function handleContactmail(e) {
    setContactmail(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleProjectname(e) {
    console.log(e.target.value);
    setProjectname(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  function handledivision(e) {
    setdivision(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  // function handleClientname(e) {
  //   setClientname(e.target.value.replace(/\s+/g, ''))
  //   validateField(e.target.name, e.target.value)
  // }
  function handlecircle(e) {
    setcircle(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  const validateField = (fieldName, value) => {
    if (fieldName === "projectname") {
      if (value.length != 0) {
        setProjectnameerror("");
        setProjectnameValid(true);
      } else {
        setProjectnameerror("Please enter your Project name");
        setProjectnameValid(false);
      }
    }

    if (fieldName === "contactno") {
      if (value.length != 0 && value.length == 10) {
        setContactnoerror("");
        setContactnoValid(true);
      } else {
        setContactnoerror("Please enter valid Contact number");
        setContactnoValid(false);
      }
    }
    // if (fieldName === 'clientname') {

    //   if (value != "") {
    //     setClientnameerror('')
    //     setClientnameValid(true)
    //   } else {
    //     setClientnameerror('Please select Client name')
    //     setClientnameValid(false)
    //   }
    // }
    if (fieldName === "division") {
      console.log(value);

      if (value != "") {
        setdivisionerror("");
        setdivisionValid(true);
      } else {
        setdivisionerror("Please enter Company name");
        setdivisionValid(false);
      }
    }
    if (fieldName === "circle") {
      if (value != "") {
        setcircleerror("");
        setcircleValid(true);
      } else {
        setcircleerror("Please select Contact person");
        setcircleValid(false);
      }
    }
    if (fieldName === "contactmail") {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (value.length != 0) {
        if (value.match(regex)) {
          setContactmailValid(true);
          setContactmailerror("");
        } else {
          setContactmailValid(false);
          setContactmailerror("Please enter valid email");
        }
      } else {
        setContactmailValid(false);
        setContactmailerror("Please enter valid email");
      }
    }
  };

  return (
    <>
      <Modal show={status} onHide={btnClick} id="modal-size1">
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <button className="btn " onClick={btnClick}>
              <IoCloseSharp />
            </button>
          </div>
          <div class="row d-flex justify-content-center align-center  flex-column text-center ">
            <div class="mb-2">
              <center>
                <b>{modalname}</b>
              </center>
            </div>

            {data && data.length > 0 ? (
              <div className="mb-1">
                <div className="">
                  {data.map((item, index) =>
                    tab === "Wing" ? (
                      <p className="mb-1" key={index}>
                        {item}
                      </p>
                    ) : tab === "Circle" ? (
                      <p key={index}>{item}</p>
                    ) : tab === "Division" ? (
                      <p key={index}>{item}</p>
                    ) : tab === "Sub-Division" ? (
                      <p className="text-dark" key={index}>
                        {item}
                      </p>
                    ) : null
                  )}
                </div>
              </div>
            ) : (
              <p>No data available</p>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
