import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import './ClientStatus.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import cancelledimg from '../../assets/images/Status/cancelled.png'
import completedimg from '../../assets/images/Status/completed-5.png'
import pendingimg from '../../assets/images/Status/pending.png'
import {useSelector } from 'react-redux'
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const [successstatus, setSuccessstatus] = useState(false);
  const [userlist, setUserlist] = useState([]);
  const userData = useSelector((state) => state.userDetails)

    useEffect(() => {

      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  
          axios({
            method: 'GET',
            url: baseurl + '/api/report/client/status',
      
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token,
            },
          })


          .then((response) => {
              console.log(response.data)
              const list=response.data
              console.log(list)
              setUserlist(list)
              // alert(list)
          setSuccessstatus(true)
          })
          .catch((error) => {
              console.log(error)
              //setErrormsg(error.response.data.message)
          })
    }, [])
  
//   const list1 = [
//     { key1: "add", key2: "fdg", key3: "sds" },
//     { key1: "add", key2: "fdg", key3: "sds" }
//   ];
  
  return (
    <>
      
        {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
        {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}
        <div class="cont-margin1 tmar">
        <div className='table1bdstyle'>
          <div class="row table1st ">
          {/* <div class=" col-xl-1 pt-1 pb-1"></div> */}
              <div class=" col-xl-4 pt-1 pb-1">
             <div className='padtext1'><h5><b>Client Status</b></h5></div> 
              </div>
              <div class=" col-xl-4 pt-1 pb-1">
              
              </div>
              <div class=" col-xl-3 pt-1 pb-1 ">
            <Link to="/SearchClient"> 
            <button 
                    type="button"
                    className="searchpro-btn"
                  >
                  Search</button>
            </Link> 
              </div>
              <div class=" col-xl-1 pt-1 pb-1"></div>
              {successstatus && userlist.length == 0 &&
            // !checkduedate &&
            
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3">
                <center><b><h6>No Data Available</h6></b></center>
             
                </div>}
              {userlist.length != 0 &&   <table
              class="table tablemargin" 
             
            >
              <tr class="trst pt-1 pb-1">
                <th><div className='trpad'>Client Name</div></th>
                <th>Number of Projects</th>
                <th>Projects In Progress</th>
                <th>Pending Amount</th>
                <th>Total Paid</th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;Status</th>
              </tr>
             
           {userlist.length != 0 && userlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>

                    <td><div className='trpad'>{item.client_name}</div></td>
                    <td><center>{item.no_of_projects}</center></td>
                    <td><center>{item.projects_in_progress}</center></td>
                    <td ><center>{item.pending_amount}</center></td>
                    <td><center>{item.total_pay}</center></td>
                    <td> {item.payment_status === "Pending" ? 
<img src={pendingimg}  className='leftimg'/>: 
(item.payment_status === "Completed1" ?
 <img src={completedimg}  className='leftimg'/>:
                       <img src={cancelledimg}  className='leftimg'/>)} </td>
                  </tr>
                )
              })}
            </table>}
          </div>
      
        </div>
                
            </div>
            
           
          
  

    
    </>
  )
}

export default FormPage
