import React from "react";
import { Link } from "react-router-dom";
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
import CreateCircle from "../Modal/CreateCircle";
import StatusupdateModal from "../Modal/Edit";
import DivisionView from "../Modal/DivisionView";
import ViewTickets from "../Modal/ViewTickets";
import { baseurl } from "../BaseUrl/Baseurl";
import cancelledimg from "../../assets/images/Status/cancelled.png";
import acceptedimg from "../../assets/images/Status/accepted.png";
import pendingimg from "../../assets/images/Status/pending.png";
import axios from "axios";
import close from "../../assets/images/Status/closed.png";
import open from "../../assets/images/Status/open.png";
import renewal from "../../assets/images/Status/renewal.png";
import renewed from "../../assets/images/Status/renewed.png";
import suspended from "../../assets/images/Status/suspended.png";
import { useSelector } from "react-redux";
import "./Commoncss.css";
import circleicon from "../../assets/icons/createcircle.png";
import editicon from "../../assets/icons/editicon.png";
import deleteicon from "../../assets/icons/deleteicon.png";
import circle from "../../assets/icons/Vector-3.png";
import CircleEdit from "../Modal/CircleEdit";
import { BiEditAlt } from "react-icons/bi";
import { PiCopyFill } from "react-icons/pi";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import InputTextField from "../InputText/InputText";
const WeareHere = (btnClick) => {
  const [btnname, setBtnname] = useState("");
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [projectid, setProjectid] = useState("");
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [project_renewalid, setProject_renewalid] = useState("");
  const [ticketno, setTicketno] = useState("");
  const [successstatus, setSuccessstatus] = useState(false);
  const [successstatus1, setSuccessstatus1] = useState(false);
  const [successstatus2, setSuccessstatus2] = useState(false);
  const [editstatus, setEditstatus] = useState(false);
  const [deletestatus, setDeletestatus] = useState(false);
  const [show, setShow] = useState(false);
  const [projectnameValid, setProjectnameValid] = useState(false);
  const [division, setdivision] = useState("");
  const [userlist, setUserlist] = useState("");
  const [circle, setcircle] = useState("");
  const [circleValid, setcircleValid] = useState(false);
  const [circleerror, setcircleerror] = useState("");
  const [divisionerror, setdivisionerror] = useState("");
  const [divisionlist, setdivisionlist] = useState([]);
  const [projectnameerror, setProjectnameerror] = useState("");
  const [data,setData] = useState([])
  const [divisionValid, setdivisionValid] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const userData = useSelector((state) => state.userDetails);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios({
      method: 'GET',

      url: baseurl+'/api/get/list?field=division&are_details_rqd=1',

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userData.token,
      }
    })

      .then((response) => {    
        const RequestData = response.data.message
        console.log(RequestData);
        setRenewallist(RequestData)
        setData(RequestData)
      })
      .catch((error) => {
        console.log(error)
      })
    
  }, []);

  function handledivision(e) {
    setUserlist(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  function handlecircle(e) {
    setcircle(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  const validateField = (fieldName, value) => {
    if (fieldName === "projectname") {
      if (value.length != 0) {
        setProjectnameerror("");
        setProjectnameValid(true);
      } else {
        setProjectnameerror("Please enter your Project name");
        setProjectnameValid(false);
      }
    }

    // if (fieldName === "contactno") {
    //   if (value.length != 0 && value.length == 10) {
    //     setContactnoerror("");
    //     setContactnoValid(true);
    //   } else {
    //     setContactnoerror("Please enter valid Contact number");
    //     setContactnoValid(false);
    //   }
    // }
    // if (fieldName === 'clientname') {

    //   if (value != "") {
    //     setClientnameerror('')
    //     setClientnameValid(true)
    //   } else {
    //     setClientnameerror('Please select Client name')
    //     setClientnameValid(false)
    //   }
    // }
    if (fieldName === "division") {
      console.log(value);

      if (value != "") {
        setdivisionerror("");
        setdivisionValid(true);
      } else {
        setdivisionerror("Please enter Company name");
        setdivisionValid(false);
      }
    }
    if (fieldName === "circle") {
      if (value != "") {
        setcircleerror("");
        setcircleValid(true);
      } else {
        setcircleerror("Please select Contact person");
        setcircleValid(false);
      }
    }
    // if (fieldName === "contactmail") {
    //   const regex =
    //     /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
    //   if (value.length != 0) {
    //     if (value.match(regex)) {
    //       setContactmailValid(true);
    //       setContactmailerror("");
    //     } else {
    //       setContactmailValid(false);
    //       setContactmailerror("Please enter valid email");
    //     }
    //   } else {
    //     setContactmailValid(false);
    //     setContactmailerror("Please enter valid email");
    //   }
    // }
  };
  function onSubmitEvent(event) {
    event.preventDefault();

    // console.log(projectname)
    // console.log(contactno)

    if (circleValid && divisionValid) {
      console.log(circle);
      console.log(division);
      console.log(userlist);
    }
  }

  const toggleModelStatus = () => {
    setRequeststatus(false);
  };
  
  const toggleModelStatus1 = () => {
    setUpdatestatus(false);

    //Refresh the Table

    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const RequestData = response.data;
        setRequestlist(RequestData);
        setSuccessstatus(true);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });
  };
  const toggleRenewalStatus = () => {
    console.log("***********");
    setRenewalstatus(false);
  };
  const toggleViewTicketStatus = () => {
    console.log("***********");
    setViewticketstatus(false);
  };
  const requestdata = () => {
    console.log("*****" + requeststatus);
    setRequeststatus(true);
    // setShow(true);
  };

  const RenewalUpdate = (id,subdiv) => {
    setProject_renewalid(id);
    setSelectedOptions(subdiv)
    setRenewalstatus(true);
  };
  const onEdit = (id) => {
    setEditstatus(true);
  };

  const onDelete = (id) => {
    setDeletestatus(true);
  };
  const onEditClose = () => {
    setEditstatus(false);
  };
  const ViewticketstatusUpdate = (id) => {
    setTicketno(id);
    setViewticketstatus(true);
  };

  return (
    <div class="row mt-5 centered-container-circle ">
      <div class=" col-xl-4 pt-1 pb-1 "></div>
      <div class=" col-xl-4 pt-1 pb-1"></div>
      <div class=" col-xl-3 pt-1 pb-1 mt-2 ">
        <button
          onClick={requestdata}
          type="button"
          className="createcircle-btn"
        >
          <PiCopyFill style={{ fontSize: "25px" }} />
          &nbsp;Create Division
        </button>
        {requeststatus && (
          <CreateCircle
            status={requeststatus}
            btnClick={toggleModelStatus}
            fieldName1={"Division Name"}
            fieldName2={"Choose Sub Division"}
            tab={'Division'}
          />
        )}
      </div>
      {/* <div class=" col-xl-1 pt-1 pb-1"></div> */}
      {successstatus1 && renewallist.length == 0 && (
        // !checkduedate &&

        <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3">
          <center>
            <b>
              <h6>No Data Available</h6>
            </b>
          </center>
        </div>
      )}
      {renewallist.length != 0 && (
        <table class="table  tablemargin" readOnly="readOnly">
          <tr class="th-circle">
            <th style={{ borderTopLeftRadius: "10px" }}>
              <div
              // className='trpad'
              >
                <center>Division Name</center>
              </div>
            </th>
            <th>
              <center>State</center>
            </th>
            <th>
              <center>Sub-Division</center>
            </th>
            <th>
              <center>Created By</center>
            </th>
            <td style={{ borderTopRightRadius: "10px" }}></td>
          </tr>
          {editstatus && (
            <CircleEdit
              status={editstatus}
              btnClick={onEditClose}
              ProjectId={project_renewalid}
              modalname="Create Division"
              fieldName="Division"
            />
          )}

          {renewalstatus && (
            <DivisionView
              status={renewalstatus}
              btnClick={toggleRenewalStatus}
              // ProjectId={project_renewalid}
              modalname="Sub Division List"
              tab={'Division'}
              data={selectedOptions}
            />
          )}
          {renewallist.length != 0 &&
            renewallist.map((item, index) => {
              return (
                <tr key={index} className="tablefontsize">
                  <td>
                    <div>
                      <center>
                        {item.division}
                      </center>
                    </div>
                  </td>
                  <td>
                    <center>
                      {" "}
                      {item.state}
                    </center>
                  </td>
                  <td>
                    {" "}
                    <center>
                      {" "}
                      <button
                        type="button"
                        className="view-circle"
                        onClick={() => RenewalUpdate(item.project_id,item.sub_division_list)}
                      >
                        View
                      </button>
                    </center>
                  </td>
                  <td>
                    <center>
                      {item.created_by}
                    </center>
                  </td>
                  <td>
                    {" "}
                    <center>
                      <div className="d-flex ">
                        <button
                          onClick={() => onEdit(item.project_id)}
                          className="btn btn2  "
                        >
                          <BiEditAlt
                            style={{ fontSize: "17px", color: "#069E55" }}
                          />
                        </button>

                        <button
                          onClick={() => onDelete(item.project_id)}
                          class="btn btn1 "
                        >
                          <svg
                            viewBox="0 0 15 17.5"
                            height="15"
                            width="13"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon"
                          >
                            <path
                              transform="translate(-2.5 -1.25)"
                              d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                              id="Fill"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </center>
                  </td>
                </tr>
              );
            })}
        </table>
      )}

      {/* <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      
      <Modal.Body className=" pb-0">
      <form
                  class="row d-flex justify-content-center align-center  circlediv"
                  onSubmit={(event) => {
                    onSubmitEvent(event);
                    btnClick(); // Close the modal
                  }}
                >
               
                
                    <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3 pt-3">
                      <label for="formFileLg" className="login-text">
                       Division
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handlecircle}
                          valueText={circle}
                          inputName="circle"
                          inputId="circle"
                          classname="project-box pl-2"
                          maxLen={20}
                          inputType="text"
                          //  placeholderValue="Contact"
                          defaultValue={circle}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        ></InputTextField>
                      </center>
                      <div className="login-error">{circleerror}</div>
                    </div>
                
                    <div class="col-12 col-sm-12  col-md-12 col-lg-6 col-md-12 pb-3 pt-3">
                      <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast"
                  >
                   <b>Sub-Division</b> 
                  </label>
                      <select
                    className="project-box pl-2"  
                     id="division"
                     name="division"
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={userlist}
                 onChange={handledivision}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                    {divisionlist.map((name, index) => (
      <option key={index} value={name}>
        {name}
      </option>
    ))}             
                  </select>
             
                      <div className="login-error">{divisionerror}</div>
                    </div>
                  
                  <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                    <center>
                      <button
                        type="submit"
                        className="search-circle"
                        name="submit"
                        disabled={!projectnameValid}
                      >
                        <b>Create</b>
                      </button>
                    </center>{" "}
                  </div>
                </form>
        
      </Modal.Body>
      
    </Modal> */}
    </div>
  );
};

export default WeareHere;
