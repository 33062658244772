import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { PiFactory } from "react-icons/pi";
import ButtonLink from "../ButtonLink/ButtonLink";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { useSelector } from "react-redux";

const ResourceLocationDetails = () => {
    const [sts, setSts] = useState(false)
    const [fields, setFields] = useState([
        { Sitename: "", Address: "", Lattitude: "", Longitude: "" },
    ]);

    const handleInputChange = (index, event) => {
        const values = [...fields];
        values[index][event.target.name] = event.target.value;
        setFields(values);
    };

    const handleAddFields = () => {
        setFields([
            ...fields,
            { Sitename: "", Address: "", Lattitude: "", Longitude: "" },
        ]);
    };
    const userData = useSelector((state) => state.userDetails);
    
    useEffect(()=>{
        console.log(userData.projectId);        
    },[])

    const handleSubmit = () => {
        const resourceLocationDataList = fields.map((field) => ({
            name_of_resource_site: field.Sitename,
            address: field.Address,
            lat_coordinate: field.Lattitude,
            lon_coordinate: field.Longitude,
        }));
    


const project_id = userData.projectId;
    
        axios({
            method: 'POST',
            url: baseurl + '/api/add/resource/location',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userData.token,     
            },
            data: {
                resource_location_data_list: resourceLocationDataList,
                project_id:'PR-2'
            },
        })
        .then((response) => {
            alert("hello"+response.data)                
            setSts(true);
            console.log(project_id);
            
        })
        .catch((error) => {
            console.log(error);
        });
        setSts(true);
    };
    
   


    return (
        <div className="container w-50 mx-auto mb-3">
            {sts && <ButtonLink btnPath="/RoadPlanUpdate" />}
            <div className="  mt-5 rounded" style={{ backgroundColor: "#6777EF" }}>
                <h5 className="text-white d-inline-flex align-items-center p-1 mb-0">
                    <PiFactory />
                    &nbsp;Resource
                </h5>
            </div>
            <div className="mt-2 d-flex justify-content-end">
                <button
                    className="btn d-flex align-items-center text-white "
                    style={{
                        background: "#069E55",
                        fontWeight: "500",
                        boxShadow: " 0 2px 6px #069E55",
                    }}
                    onClick={handleAddFields}
                >
                    <FaPlus />
                    &nbsp;&nbsp;<span>Add</span>
                </button>
            </div>

            <div>
    {fields.map((field, index) => (
        <div key={index}>
            <form className="row py-3">
                <div className="col-6">
                    <label className="form-label">Site Name</label>
                    <input
                        name="Sitename" // Added name attribute
                        value={field.Sitename}
                        className="form-control"
                        onChange={(event) => handleInputChange(index, event)}
                    />
                </div>
                <div className="col-6">
                    <label className="form-label">Address</label>
                    <input
                        name="Address" // Added name attribute
                        value={field.Address}
                        className="form-control"
                        onChange={(event) => handleInputChange(index, event)}
                    />
                </div>
                <div className="col-6">
                    <label className="form-label">Latitude</label>
                    <input
                        name="Lattitude" // Added name attribute
                        value={field.Lattitude}
                        className="form-control"
                        onChange={(event) => handleInputChange(index, event)}
                    />
                </div>
                <div className="col-6">
                    <label className="form-label">Longitude</label>
                    <input
                        name="Longitude" // Added name attribute
                        value={field.Longitude}
                        className="form-control"
                        onChange={(event) => handleInputChange(index, event)}
                    />
                </div>
            </form>
            <hr className="w-75 mx-auto" />
        </div>
    ))}
    <div className="text-center">
        <button onClick={handleSubmit}
            className="btn rounded-pill py-1 text-white"
            style={{ backgroundColor: "#429B42", boxShadow: 'none' }}
        >
            Submit
        </button>
    </div>
</div>

        </div>
    );
};

export default ResourceLocationDetails;
