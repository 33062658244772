import React,{useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal, Form } from 'react-bootstrap'
import './Modal.css'
import CreateRequest from '../CreateRequest/CreateRequest'
import { useState} from 'react'
import InputTextField from '../InputText/InputText'
import {baseurl} from '../BaseUrl/Baseurl'
import axios from 'axios'
import {useSelector } from 'react-redux'
export default function RenewalModel({ btnClick, status ,  ProjectId }) {
  const [projectstatus, setProjectstatus] = useState('')
  const [projectstatusValid, setProjectstatusValid] = useState(false)
  const [projectstatuserror, setProjectstatuserror] = useState('')
  const [paymentdate, setPaymentdate] = useState('')
  const [paymentdateValid, setPaymentdateValid] = useState(false)
  const [paymentdateeerror, setPaymentdateerror] = useState('')
  const [fileattach, setFileattach] = useState('')
  const [fileattacherror, setFileattacherror] = useState('')
  const [fileattachValid, setFileattachValid] = useState(false)
  const [message, setMessage] = useState('')
  const hiddenFileInput = React.useRef(null)
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }
  const userData = useSelector((state) => state.userDetails)
  const [modalVisible, setModalVisible] = useState(status);
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })


      
    }, [status,modalVisible])

    const [companynamelist, setCompanynamelist] = useState([]);
useEffect(()=>
{
  // axios({
  //   method: "GET",

  //   url: baseurl + "/api/request/trial/list/company/status/accepted",

  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: "Bearer " + userData.token,
  //   },

  //   // data: userData,
  // })
  //   .then((response) => {
  //     console.log(response.data);
  //     const RequestData = response.data;
  //     const Datalist = RequestData[0];
  //     console.log(Datalist);
  //     const data = Datalist.company_names;
  //     console.log("pppp" + data);
  //     setCompanynamelist(data);
  //     setCompanyName(data[0])

      
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //     // setErrormsg(error.response.data.message)
  //   });
})


    const [companyName, setCompanyName] = useState("");
    const [companyNameValid, setCompanyNameValid] = useState(false);
    const [companyNameError, setCompanyNameError] = useState("");
  
    const [contactPerson, setContactPerson] = useState("");
    const [contactPersonValid, setContactPersonValid] = useState(false);
    const [contactPersonError, setContactPersonError] = useState("");
  
    const [contactMail, setContactMail] = useState("");
    const [contactMailValid, setContactMailValid] = useState(false);
    const [contactMailError, setContactMailError] = useState("");
  
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberValid, setPhoneNumberValid] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState("");
  
    const [contractCode, setContractCode] = useState("");
    const [contractCodeValid, setContractCodeValid] = useState(false);
    const [contractCodeError, setContractCodeError] = useState("");
  
    const [organizationAddress, setOrganizationAddress] = useState("");
    const [organizationAddressValid, setOrganizationAddressValid] = useState(false);
    const [organizationAddressError, setOrganizationAddressError] = useState("");
  
    const [firmDetails, setFirmDetails] = useState("");
    const [firmDetailsValid, setFirmDetailsValid] = useState(false);
    const [firmDetailsError, setFirmDetailsError] = useState("");
  
    const [panNo, setPanNo] = useState("");
    const [panNoValid, setPanNoValid] = useState(false);
    const [panNoError, setPanNoError] = useState("");
  
    const [aadharCard, setAadharCard] = useState("");
    const [aadharCardValid, setAadharCardValid] = useState(false);
    const [aadharCardError, setAadharCardError] = useState("");
  
    const [registrationNo, setRegistrationNo] = useState("");
    const [registrationNoValid, setRegistrationNoValid] = useState(false);
    const [registrationNoError, setRegistrationNoError] = useState("");
  
    const [gstNo, setGstNo] = useState("");
    const [gstNoValid, setGstNoValid] = useState(false);
    const [gstNoError, setGstNoError] = useState("");
  
    const [cinNo, setCinNo] = useState("");
    const [cinNoValid, setCinNoValid] = useState(false);
    const [cinNoError, setCinNoError] = useState("");













  function handleProjectstatus(e) {
   console.log(e.target.value)
    setProjectstatus(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  
  function handleChangeFile(event) {
    const file = event.target.files[0]
    // let filename = file.name
    setFileattach(file)
    validateField(event.target.name, event.target.files[0])
  }
  function handlepaymentdate(e) {

    setPaymentdate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handlemessage(e) {

    console.log(message)

    setMessage(e.target.value.replace(/\s+/g, ''))

    validateField(e.target.name, e.target.value)

  }

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
    validateField("companyName", e.target.value);
  };

  const handleContactPersonChange = (e) => {
    setContactPerson(e.target.value);
    validateField("contactPerson", e.target.value);
  };

  const handleContactMailChange = (e) => {
    setContactMail(e.target.value);
    validateField("contactMail", e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    validateField("phoneNumber", e.target.value);
  };

  const handleContractCodeChange = (e) => {
    setContractCode(e.target.value);
    validateField("contractCode", e.target.value);
  };

  const handleOrganizationAddressChange = (e) => {
    setOrganizationAddress(e.target.value);
    validateField("organizationAddress", e.target.value);
  };

  const handleFirmDetailsChange = (e) => {
    setFirmDetails(e.target.value);
    validateField("firmDetails", e.target.value);
  };

  const handlePanNoChange = (e) => {
    setPanNo(e.target.value);
    validateField("panNo", e.target.value);
  };

  const handleAadharCardChange = (e) => {
    setAadharCard(e.target.value);
    validateField("aadharCard", e.target.value);
  };

  const handleRegistrationNoChange = (e) => {
    setRegistrationNo(e.target.value);
    validateField("registrationNo", e.target.value);
  };

  const handleGstNoChange = (e) => {
    setGstNo(e.target.value);
    validateField("gstNo", e.target.value);
  };

  const handleCinNoChange = (e) => {
    setCinNo(e.target.value);
    validateField("cinNo", e.target.value);
  };


  function onSubmitEvent(event) {
    event.preventDefault()
   console.log(organizationAddress)
   console.log(contractCode)
   console.log(firmDetails)
   console.log(panNo)
   console.log(aadharCard)
   console.log(companyName)
   console.log(gstNo)
   console.log(phoneNumber)
   console.log(contactMail)
   console.log(contactPerson)


    let userData0 = new FormData()
    userData0.append('company_name', companyName)
    userData0.append('vendor_type', 'Contractor')
   // userData0.append('type_of_resource', resourcetype)
    userData0.append('phone_no', phoneNumber)
  //  userData0.append('onboarding_date', onboardingdate)
    userData0.append('email', contactMail)
    userData0.append('contact_person', contactPerson)
    userData0.append('contract_document', '')
 //   userData0.append('client_id', 'CL-2')
//take this out
  //  userData0.append('project_id', userData.projectId)

  //  userData0.append('filename', filename)



    userData0.append('contractor_code', contractCode)
    userData0.append('organization_address', organizationAddress)
    userData0.append('firm_details', firmDetails)
    userData0.append('pan_no', panNo)
    userData0.append('aadhaar_no', aadharCard)
    userData0.append('reg_no', registrationNo)
    userData0.append('gst_no', gstNo)
   // userData0.append('din_no','' )
    userData0.append('cin_no', cinNo)

    userData0.append('are_contractor_and_client_same', '1')





    axios({
      method: 'POST',

      url: baseurl + '/api/vendor/create?are_contractor_and_client_same=1', 

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token,
      },
      data:userData0,
    })

      .then((response) => {
   
      })
      .catch((error) => {
console.log(error)
//setError(error.response.data.message)
      });
      // if (handleI.current) {
      //   fileInputRef.current.value = '';
      // }
     
   
    setModalVisible(false)
  }

  const validateField = (fieldName, value) => {
    if (fieldName === "companyName") {
      if (value.length !== 0) {
        setCompanyNameError("");
        setCompanyNameValid(true);
      } else {
        setCompanyNameError("Please enter your Company Name");
        setCompanyNameValid(false);
      }
    } else if (fieldName === "contactPerson") {
      if (value.length !== 0) {
        setContactPersonError("");
        setContactPersonValid(true);
      } else {
        setContactPersonError("Please enter Contact Person");
        setContactPersonValid(false);
      }
    } else if (fieldName === "contactMail") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (value.match(emailRegex)) {
        setContactMailError("");
        setContactMailValid(true);
      } else {
        setContactMailError("Please enter a valid Contact Mail");
        setContactMailValid(false);
      }
    } else if (fieldName === "phoneNumber") {
      if (value.length === 10) {
        setPhoneNumberError("");
        setPhoneNumberValid(true);
      } else {
        setPhoneNumberError("Please enter a valid 10-digit Phone Number");
        setPhoneNumberValid(false);
      }
    } else if (fieldName === "contractCode") {
      if (value.length !== 0) {
        setContractCodeError("");
        setContractCodeValid(true);
      } else {
        setContractCodeError("Please enter the Contract Code");
        setContractCodeValid(false);
      }
    } else if (fieldName === "organizationAddress") {
      if (value.length !== 0) {
        setOrganizationAddressError("");
        setOrganizationAddressValid(true);
      } else {
        setOrganizationAddressError("Please enter Organization Address");
        setOrganizationAddressValid(false);
      }
    } else if (fieldName === "firmDetails") {
      if (value.length !== 0) {
        setFirmDetailsError("");
        setFirmDetailsValid(true);
      } else {
        setFirmDetailsError("Please enter Firm Details");
        setFirmDetailsValid(false);
      }
    } else if (fieldName === "panNo") {
    //  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
      //if (value.match(panRegex)) {
        if (value.length!=0) {
        setPanNoError("");
        setPanNoValid(true);
      } else {
        setPanNoError("Please enter a valid PAN Number");
        setPanNoValid(false);
      }
    } else if (fieldName === "aadharCard") {
      if (value.length === 12) {
        setAadharCardError("");
        setAadharCardValid(true);
      } else {
        setAadharCardError("Please enter a valid 12-digit Aadhar Card Number");
        setAadharCardValid(false);
      }
    } else if (fieldName === "registrationNo") {
      if (value.length !== 0) {
        setRegistrationNoError("");
        setRegistrationNoValid(true);
      } else {
        setRegistrationNoError("Please enter Registration Number");
        setRegistrationNoValid(false);
      }
    } else if (fieldName === "gstNo") {
     // const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/;
     // if (value.match(gstRegex)) {
      if (value.length!=0) {
        setGstNoError("");
        setGstNoValid(true);
      } else {
        setGstNoError("Please enter a valid GST Number");
        setGstNoValid(false);
      }
    } else if (fieldName === "cinNo") {
      if (value.length !== 0) {
        setCinNoError("");
        setCinNoValid(true);
      } else {
        setCinNoError("Please enter CIN Number");
        setCinNoValid(false);
      }
    }
  };
  

  return (
    <>
      <Modal show={status} onHide={btnClick} id="modal-size1" >
        <Modal.Body >
         
          {/* <CreateRequest /> */}
          {/* <center>  <div className="model-top"><b>Contractor Details</b></div></center> */}
  
          <div className="model-top">
           
                <div class="row pb-2 pt-2">
           
                
                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={(event) => {
                      onSubmitEvent(event);
                      btnClick(); // Close the modal
                    }}
                  >

                    <div class="row pt-5">
                    <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Company Name
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleCompanyNameChange}
                          valueText={companyName}
                          inputName="companyName"
                          inputId="companyName"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                         
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{companyNameError}</div>
                        </center>
                      </div>


                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Contact Person
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleContactPersonChange}
                          valueText={contactPerson}
                           id="contactPerson"
                     name="contactPerson"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{contactPersonError}</div>
                        </center>
                      </div>
                     
                     
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Contact Mail
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleContactMailChange}
                          valueText={contactMail}
                           id="contactMail"
                     name="contactMail"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{contactMailError}</div>
                        </center>
                      </div>

                        
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Phone Number
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handlePhoneNumberChange}
                          valueText={phoneNumber}
                           id="phoneNumber"
                     name="phoneNumber"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{phoneNumberError}</div>
                        </center>
                      </div>


                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Contract Code
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleContractCodeChange}
                          valueText={contractCode}
                           id="contractCode"
                     name="contractCode"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{contractCodeError}</div>
                        </center>
                      </div>
                     
                    
                    

                     
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Pan No	
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handlePanNoChange}
                          valueText={panNo}
                           id="panNo"
                     name="panNo"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{panNoError}</div>
                        </center>
                      </div>

                    
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Aadhar Card
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleAadharCardChange}
                          valueText={aadharCard}
                           id="aadharCard"
                     name="aadharCard"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{aadharCardError}</div>
                        </center>
                      </div>

                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Registration No
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleRegistrationNoChange}
                          valueText={registrationNo}
                           id="registrationNo"
                     name="registrationNo"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{registrationNoError}</div>
                        </center>
                      </div>

                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      GST No
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleGstNoChange}
                          valueText={gstNo}
                           id="gstNo"
                     name="gstNo"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{gstNoError}</div>
                        </center>
                      </div>

                   
                    
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      CIN No
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleCinNoChange}
                          valueText={cinNo}
                           id="cinNo"
                     name="cinNo"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{cinNoError}</div>
                        </center>
                      </div>
                    

                      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
                      <label for="formFileLg" className="login-text">
                      Firm Details
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handleFirmDetailsChange}
                          valueText={firmDetails}
                           id="firmDetails"
                     name="firmDetails"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                        //  placeholderValue=" companyName"
                        ></InputTextField>

                        <div className="login-error">{firmDetailsError}</div>
                        </center>
                      </div>

                      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pt-4  ">
                      <label for="formFileLg" className="login-text">
                      Organization Address
                      </label>
<center>
                        <div class="textareamargin">              

                          <textarea class="form-control" id="organizationAddress" value={organizationAddress} name="organizationAddress" rows="3"  onChange={handleOrganizationAddressChange}></textarea>
                        </div>

                        </center>

                      </div>
                    
                    
                    
                     

                    
                    
                    
                      {/* <div class="col-sm-12 col-12 col-lg-12 col-md-12 col-xl-12 pt-2 ">
                      <label className="login-text">
                Payment
              </label>
            </div>
            <div className="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
              <button
                type="button"
                className="project-box "
                onClick={() => handleClick()}
              >
                
                  {fileattach != undefined && fileattach != ''
                        ? 'Uploaded '
                        : 'Upload'}
              </button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChangeFile}
                style={{ display: 'none' }}
                name="fileattach"
                id="fileattach"
                accept="image/jpeg"
              />
               <div className="login-error">{fileattacherror}</div>
                 </div>
             

                     
                      <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-md-12 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast"
                  >
                  Contact Person
                  </label>
                  <select
                    className="project-box"  
                     id="contactPerson"
                     name="contactPerson"
                    //  defaultValue={companyname}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={contactPerson}
                 onChange={handleProjectstatus}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                    <option value="due_for_renewal">Due for Renewal</option>
                    <option value="suspended">Suspended</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="renewed">Renewed</option>
                  </select>
                  <div className="login-error">{projectstatuserror}</div>
                </div>
                    
                <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pt-4  ">
<center>
                        <div class="textareamargin">              

                          <textarea class="form-control" id="message" name="message" rows="3" placeholder='Message' onChange={handlemessage}></textarea>
                        </div>

                        </center>

                      </div> */}
 

                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                          
                        >
                          <b>Submit</b>
                        </button>
                      </center>{' '}
                    </div>
                  
                  </form>
                </div>
              
              </div>
            </div>
           
         
        </Modal.Body>
      </Modal>
    </>
  )
}
