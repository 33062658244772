import React from "react";
import { Link } from "react-router-dom";
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
import CreateCircle from "../Modal/CreateCircle";
import StatusupdateModal from "../Modal/Edit";
import DivisionView from "../Modal/DivisionView";
import ViewTickets from "../Modal/ViewTickets";
import { baseurl } from "../BaseUrl/Baseurl";
import cancelledimg from "../../assets/images/Status/cancelled.png";
import acceptedimg from "../../assets/images/Status/accepted.png";
import pendingimg from "../../assets/images/Status/pending.png";
import axios from "axios";
import close from "../../assets/images/Status/closed.png";
import open from "../../assets/images/Status/open.png";
import renewal from "../../assets/images/Status/renewal.png";
import renewed from "../../assets/images/Status/renewed.png";
import suspended from "../../assets/images/Status/suspended.png";
import { useSelector } from "react-redux";
import "./Commoncss.css";
import circleicon from "../../assets/icons/createcircle.png";
import editicon from "../../assets/icons/editicon.png";
import deleteicon from "../../assets/icons/deleteicon.png";
import circle from "../../assets/icons/Vector-3.png";
import CircleEdit from "../Modal/CircleEdit";
import { Button, Modal } from "react-bootstrap";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import subdiv from "../../assets/icons/Vector-5.png";
import { IoCloseSharp } from "react-icons/io5";

const WeareHere = () => {
  const [btnname, setBtnname] = useState("");
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [projectid, setProjectid] = useState("");
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [project_renewalid, setProject_renewalid] = useState("");
  const [ticketno, setTicketno] = useState("");
  const [successstatus, setSuccessstatus] = useState(false);
  const [successstatus1, setSuccessstatus1] = useState(false);
  const [successstatus2, setSuccessstatus2] = useState(false);
  const [editstatus, setEditstatus] = useState(false);
  const [deletestatus, setDeletestatus] = useState(false);
  const [show, setShow] = useState(false);
  const [subdivname, setSubdivname] = useState("");
  const [state, setState] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [subdivErr, setSubdivErr] = useState('');
  const [stateErr, setStateErr] = useState('');
  const [selectedErr, setSelectedErr] = useState();

  const [formErr, setFormErr] = useState("");
  const [formSuccess, setFormSuccess] = useState('')
  const [data, setData] = useState([])
  const [options, setOptions] = useState([])
  const [stateOptions, setStateOptions] = useState([])

  // const handleShow = () => setShow(true);

  const userData = useSelector((state) => state.userDetails);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });


    axios({
      method: 'GET',

      url: baseurl + '/api/get/list?field=sub_division&are_details_rqd=1',

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userData.token,
      }
    })

      .then((response) => {
        const RequestData = response.data.message
        console.log(RequestData);
        setRenewallist(RequestData)
        setData(RequestData)
      })
      .catch((error) => {
        console.log(error)
      })


  }, []);

  const toggleModelStatus = () => {
    //Refresh the Table

    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const RequestData = response.data;
        setRequestlist(RequestData);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    setRequeststatus(false);
  };
  const toggleModelStatus1 = () => {
    setUpdatestatus(false);


    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const RequestData = response.data;
        setRequestlist(RequestData);
        setSuccessstatus(true);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });
  };
  const toggleRenewalStatus = () => {
    console.log("***********" + renewalstatus);
    setRenewalstatus(false);
  };
  const toggleViewTicketStatus = () => {
    console.log("***********");
    setViewticketstatus(false);
  };

  const requestdata = () => {
    console.log("*****" + requeststatus);
    setRequeststatus(true);
    setShow(true);

    axios({
      method: 'GET',

      url: baseurl + '/api/get/list?field=sub_division',

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userData.token,
      }
    })

      .then((response) => {
        const RequestData = response.data.message
        console.log(RequestData);
        setOptions(RequestData)
      })
      .catch((error) => {
        console.log(error)
      })

    axios({
      method: 'GET',

      url: baseurl + '/api/get/list?field=state',

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userData.token,
      }
    })

      .then((response) => {
        const RequestData = response.data.message
        console.log(RequestData);
        setStateOptions(RequestData)
      })
      .catch((error) => {
        console.log(error)
      })



  };

  const RenewalUpdate = (id, areas) => {
    setProject_renewalid(id);
    setSelectedOptions(areas);
    console.log("Areas:", areas); // Add this to inspect the areas
    setRenewalstatus(true);
  };
  const onEdit = (id) => {
    setEditstatus(true);
  };

  const onDelete = (id) => {
    setDeletestatus(true);
  };
  const onEditClose = () => {
    setEditstatus(false);
  };
  const ViewticketstatusUpdate = (id) => {
    setTicketno(id);
    setViewticketstatus(true);
  };

  // const options = ["Option 1", "Option 2", "Option 3", "Option 4"];

  const handleAreaChange = (e) => {
    const selectedValues = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setSelectedOptions(selectedValues);
    validateField('area', selectedValues);
    setFormErr('')
  };

  const handleSubDivChange = (e) => {
    setSubdivname(e.target.value);
    validateField('subdivname', e.target.value);
    setFormErr('')
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    validateField('state', e.target.value);
    setFormErr('')
  };

  const validateField = (name, value) => {
    if (name === 'subdivname') {
      if (value === '') {
        setSubdivErr("Please Enter Sub-Division Name");
      } else {
        setSubdivErr("");
      }
    } else if (name === 'state') {
      if (value === '') {
        setStateErr("Please Select A State");
      } else {
        setStateErr("");
      }
    } else if (name === 'area') {
      if (value.length === 0) {
        setSelectedErr("Please Select An Area");
      } else {
        setSelectedErr("");
      }
    }
  };

  // console.log(typeof(subdivname));
  // console.log(typeof(selectedOptions));
  // console.log(typeof(state));


  const CreateSubdiv = (event) => {
    event.preventDefault();

    let userData1 = {
      sub_division: subdivname,
      area: selectedOptions,
    };

    axios({
      method: 'POST',
      url: `${baseurl}/api/list/add?user_id=${userData.userId}&field=sub_division`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userData.token}`,
      },
      data: userData1,
    })
      .then((response) => {
        console.log("Response Data:", response.data);
        setFormSuccess(response.data.message)
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        setFormErr(errorMessage);
        console.log(errorMessage);
      });
  };

  const handleClose = () => {
    setShow(false);
    setSubdivname('')
    setState('')
    setSelectedOptions([])
    setFormSuccess('')
  }


  return (
    <div class="row mt-5 centered-container-circle ">
      <div class=" col-xl-4 pt-1 pb-1 "></div>
      <div class=" col-xl-4 pt-1 pb-1"></div>
      <div class=" col-xl-3 pt-1 pb-1 mt-2 ">
        <button
          style={{ fontSize: "14px" }}
          onClick={requestdata}
          type="button"
          className="createcircle-btn align-items-center d-inline-flex justify-content-center"
        >
          <img src={subdiv} width={20} />
          &nbsp;Create New Sub-Division
        </button>
        {/* {requeststatus && (
          <CreateCircle status={requeststatus} btnClick={toggleModelStatus} />
        )} */}
      </div>
      <div class=" col-xl-1 pt-1 pb-1"></div>
      {successstatus1 && renewallist.length == 0 && (
        // !checkduedate &&

        <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3">
          <center>
            <b>
              <h6>No Data Available</h6>
            </b>
          </center>
        </div>
      )}
      {renewallist.length != 0 && (
        <table class="table  tablemargin" readOnly="readOnly">
          <tr class="th-circle">
            <th className="rounded-0">
              <div
              // className='trpad'
              >
                <center>Sub-Division List</center>
              </div>
            </th>
            <th>
              <center>State</center>
            </th>
            <th>
              <center>Area</center>
            </th>
            <th>
              <center>Created By</center>
            </th>
            <td></td>
          </tr>
          {editstatus && (
            <CircleEdit
              status={editstatus}
              btnClick={onEditClose}
              ProjectId={project_renewalid}
              modalname="Create Sub-Division"
              fieldName="Sub-Division"
            />
          )}

          {renewalstatus && (
            <DivisionView
              status={renewalstatus}
              btnClick={toggleRenewalStatus}
              ProjectId={project_renewalid}
              modalname="Area List"
              data={selectedOptions}
              tab={'Sub-Division'}
            />
          )}
          {renewallist.length != 0 &&
            renewallist.map((item, index) => {
              return (
                <tr key={index} className="tablefontsize">
                  <td>
                    <div>
                      <center>
                        {item.sub_division}
                      </center>
                    </div>
                  </td>
                  <td>
                    <center>
                      {" "}
                      {item.state}
                    </center>
                  </td>
                  <td>
                    {" "}
                    <center>
                      {" "}
                      <button
                        type="button"
                        className="view-circle"
                        onClick={() => RenewalUpdate(item.project_id, item.area_list)}
                      >
                        View
                      </button>
                    </center>
                  </td>
                  <td>
                    <center>
                      {item.created_by}
                    </center>
                  </td>
                  <td>
                    {" "}
                    <center>
                      <button
                        className="btn"
                        onClick={() => onEdit(item.project_id)}
                      >
                        <BiEdit
                          style={{ fontSize: "17px", color: "#00A350" }}
                        />
                      </button>
                      <button
                        className="btn"
                        onClick={() => onDelete(item.project_id)}
                      >
                        <RiDeleteBinLine
                          style={{ fontSize: "17px", color: "#FF0000" }}
                        />
                      </button>
                    </center>
                  </td>
                </tr>
              );
            })}
        </table>
      )}

      {requeststatus && (
        <Modal centered show={show} onHide={handleClose}>
          <div className="d-flex justify-content-end">
            <button className="btn " onClick={handleClose}>
              <IoCloseSharp />
            </button>
          </div>
          <Modal.Body className="bg-white">
            <form >
              <label className="form-label">Sub-Division Name</label>
              <input name="subdivname"
                value={subdivname}
                onChange={handleSubDivChange}
                className="form-control"
                type="text"
              />
              {/* {subdivErr && <p style={{ color: 'red', fontWeight: '400' }}>{subdivErr}</p>} */}

              <label className="form-label">Choose State</label>
              <select name="state"
                value={state}
                onChange={handleStateChange}
                className="form-control"
              >
                <option value=''>Select</option>
                {stateOptions.map((item, index) => {
                  return (
                    <option key={index} value={item}>{item}</option>
                  )
                })}
              </select>
              {/* {stateErr && <p style={{ color: 'red', fontWeight: '400' }}>{stateErr}</p>} */}

              <label className="form-label">Choose Area</label>
              <select name="area"
                className="form-control"
                multiple
                value={selectedOptions}
                onChange={handleAreaChange}
              >
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              {formSuccess && <p style={{ color: 'green', fontWeight: '500' }}>{formSuccess}</p>}
              {formErr && <p style={{ color: 'red', fontWeight: '500' }}>{formErr}</p>}

              <div className="text-center">
                <button onClick={CreateSubdiv} className="search-circle mt-1  " type="submit">
                  Submit
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default WeareHere;
