import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import '../UserCreation/UserCreation.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
import EditUser from './EditUser'
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
function FormPage() {
    function Loginspace() {
        // var username = document.getElementById('firstname')
    }
    const userData = useSelector((state) => state.userDetails)
    const [name, setName] = useState('')
    const [nameValid, setNameValid] = useState(false)
    const [nameerror, setNameerror] = useState('')
    const [contactno, setContactno] = useState('')
    const [contactnoValid, setContactnoValid] = useState(false)
    const [contactnoerror, setContactnoerror] = useState('')
    const [contactmail, setContactmail] = useState('')
    const [contactmailValid, setContactmailValid] = useState(false)
    const [contactmailerror, setContactmailerror] = useState('')
    const [successstatus, setSuccessstatus] = useState(false)
    const [projectid, setProjectid] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState(false)
    const [passworderror, setPassworderror] = useState('')
    const [role, setRole] = useState('')
    const [roleValid, setRoleValid] = useState(false)
    const [roleerror, setRoleerror] = useState('')
    const [isPasswordShown, setIsPasswordShown] = useState(false)
    const [userList, setUserList] = useState([])
    const [username, setUsername] = useState('')
    const [userid, setUserid] = useState('')
    const [errormsg, setErrormsg] = useState('')
    const [enablest, setEnablest] = useState(false)
    useEffect(() => {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

            axios({
                method: 'GET',
                url: baseurl + '/api/user/list?project_id='+userData.projectId,
          
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + userData.token,
                },
              })

            .then((response) => {
                console.log(response.data)
           const userlist=response.data
           const list=userlist.user_details
    
           setUserList(list)
            })
            .catch((error) => {
                console.log(error)
                //setErrormsg(error.response.data.message)
            })

    }, [])
    const togglePasswordVisiblity= (event) => {
        setIsPasswordShown(!isPasswordShown )
        event.preventDefault()
      }

      function handleUsernames(e) {
        console.log(e.target.value)
        setUsername(e.target.value)
       
    }

      function onpasswordChange(e) {
   
        setPassword(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
        
      }
    function handlename(e) {
        console.log(e.target.value)
        setName(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
    }
   
    function handleContactno(e) {
        setContactno(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
    }
    function handleContactmail(e) {
        setContactmail(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
    }
    function handleRole(e) {
        setRole(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
    }
  
    const validateField = (fieldName, value) => {
        if (fieldName === 'name') {
            console.log(value)
            if (value.length != 0) {
                setNameerror('')
                setNameValid(true)
            } else {
                setNameerror('Please enter your name')
                setNameValid(false)
            }
        }
     
        if (fieldName === 'contactmail') {
            const regex =
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            if (value.length != 0) {
                console.log(value.match(regex))
                if (value.match(regex)) {

                    setContactmailValid(true)
                    setContactmailerror('')
                } else {

                    setContactmailValid(false)
                    setContactmailerror('')
                }
            } else {

                setContactmailValid(false)
                setContactmailerror('')
            }
        }
       
        if (fieldName === 'contactno') {
            if (value.length != 0 && value.length == 10) {
                setContactnoerror('')
                setContactnoValid(true)
            } else {
                setContactnoerror('Please enter valid Contact number')
                setContactnoValid(false)
            }
        }
       
        if (fieldName === 'password') {
            const strongRegex = new RegExp(
                '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
              )
            let passwordValid = value != '' && value.match(strongRegex)
      
         
        
            setPasswordValid(passwordValid)
          
            if (passwordValid === null || passwordValid === undefined) {
              setPassworderror(
                'Password should be more than or equal to 6 character and it should contain lowercase,uppercase,numeric and special character'
              )
              setPasswordValid(false)
            } else {
              setPassworderror('')
              setPasswordValid(true)
            }
          }
    }
    //Once created refresh the table list
    function refreshUserlist() {
        setEnablest(!enablest)

 
        axios({
            method: 'GET',
            url: baseurl + '/api/user/list?project_id='+userData.projectId, 
      
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token,
            },
          })
        .then((response) => {
            console.log(response.data)
       const userlist=response.data
       const list=userlist.user_details

       setUserList(list)
      // setErrormsg('')
        })
        .catch((error) => {
            console.log(error)
          //  setErrormsg(error.response.data.message)
        })
    }

//Refresh userlist without closing form
    function refreshUserlist1() {

        axios({
            method: 'GET',
            url: baseurl + '/api/user/list?project_id='+userData.projectId,
      
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token,
            },
          })
        .then((response) => {
            console.log(response.data)
       const userlist=response.data
       const list=userlist.user_details

       setUserList(list)
      // setErrormsg('')
        })
        .catch((error) => {
            console.log(error)
          //  setErrormsg(error.response.data.message)
        })
    }

    function SaveandNext(event) {
        event.preventDefault()

        console.log(name)


        //  if (nameValid && contactmailValid && contactnoValid && passwordValid && roleValid
        // ) {



        let formdata = new FormData()
        formdata.append('username',contactmail)
        formdata.append('name',name)
        formdata.append('email',contactmail)
        formdata.append('contact_number', contactno)
        formdata.append('password', password)
        formdata.append('role', role)
        formdata.append('client_id', userData.clientId)
        formdata.append('project_id', userData.projecId)
      
            axios({
                method: 'POST',
                url: baseurl + '/api/user/add', formdata,
          
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + userData.token,
                },
              })
            .then((response) => {
                console.log(response.data)
               
             setSuccessstatus(true)
                // alert("success ")
            })
            .catch((error) => {
                console.log(error)
               // setErrormsg(error.response.data.message)
            })

//} 
//  setSuccessstatus(true)
    }
   function findUserId () {
     
  
      const user = userList.find(user => user.name === username);
  
      if (user) {
      setUserid(user.user_id);
   
  
      } 

   

     
    }

    function onSubmitEvent(event) {
        event.preventDefault()
        
        findUserId()
        if(username.length!=0)
        {
            setSuccessstatus(true)   
        }
    }
    
    return (
        <>

            {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
          
            <div className=" ">
                <div >

              
                    <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-md-12 pb-3">
                      <div class=" row pt-5">
                        <div class="col-lg-9 col-md-9 col-xl-9"></div>
                        <div class="col-lg-3 col-md-3 col-xl-3 pb-2">
                      
            <button 
                    type="button"
                    className="createpro-btn"
                    onClick={()=>setEnablest(!enablest)}
                  ><FaPlusCircle />
                   &nbsp;Create New User</button>
           
            </div>
<div class="pt-3 pb-3">
            {enablest && <EditUser  refreshUserlist={refreshUserlist} refreshUserlist1={refreshUserlist1}/>}
            </div>
            {userList.length != 0 &&    <table
              class="table "
              readOnly="readOnly"
            >
                <tr class="trst pt-1 pb-1">
                <th><div className='trpad'>Name</div></th>
                <th>Mail Id</th>
                <th>Contact Number</th>
                {/* <th>Temporary Password</th> */}
                <th>Role</th>
              </tr>
            
              {userList.length != 0 && userList.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>

                    <td><div className='trpad'>{item.name}</div></td>
                    <td>{item.email}</td>
                    <td>{item.contact_number}</td>
                    {/* <td style={{ width: '200px', maxWidth: '200px', overflowX: 'auto' }}>{item.password}</td> */}
                    <td>{item.role}</td>

                  </tr>
                )
              })}
            </table>}
                     </div>
                       
                    </div>
                  
<div>


</div>
                </div>
            </div>



        </>
    )
}

export default FormPage
