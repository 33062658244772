import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import './Support.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'

import completedimg from '../../assets/images/Status/completed-5.png'
import inprogressimg from '../../assets/images/Status/inprogress.png'
import pendingimg from '../../assets/images/Status/pending.png'
import startedimg from '../../assets/images/Status/started.png'
import close from '../../assets/images/Status/closed.png'
import open from '../../assets/images/Status/open.png'
import {useSelector } from 'react-redux'
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const [projectname, setProjectname] = useState('')
  const [projectnameValid, setProjectnameValid] = useState(false)
  const [projectnameerror, setProjectnameerror] = useState('')
  const [status, setStatus] = useState('')
  const [statusValid, setStatusValid] = useState(false)
  const [statuserror, setStatuserror] = useState('')
  const [date, setDate] = useState('')
  const [dateValid, setDateValid] = useState(false)
  const [dateerror, setDateerror] = useState('')
  const[searchlist,setSearchlist]=useState([])
  const userData = useSelector((state) => state.userDetails)
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    }, [])

  function handleProjectname(e) {
   console.log(e.target.value)
    setProjectname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handlestatus(e) {
    setStatus(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handledate(e) {
    setDate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  
  const validateField = (fieldName, value) => {
    if (fieldName === 'projectname') {
      console.log(value)
      if (value.length != 0) {
        setProjectnameerror('')
        setProjectnameValid(true)
      } else {
        setProjectnameerror('Please enter your Project name')
        setProjectnameValid(false)
      }
    }
    if (fieldName === 'status') {
      if (value.length != 'Select') {
        setStatuserror('')
        setStatusValid(true)
      } else {
        setStatuserror('Please Select the Status')
        setStatusValid(false)
      }
    }
    if (fieldName === 'date') {
      if (value.length != 0) {
        setDateerror('')
        setDateValid(true)
      } else {
        setDateerror('Please Select the Date')
        setDateValid(false)
      }
    }
  }
  
  function onSubmitEvent(event) {
    event.preventDefault()
    



    //  if (projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
    //      && companynameValid && contactpersonValid && contactmailValid && contactnoValid) {
  

console.log(date)
console.log(status)
console.log(projectname)
        let userData1 = new FormData()
        userData1.append('project_name',projectname )
        userData1.append('date',date )
        // userData1.append('support_status', status)
       
    

          axios({
            method: 'GET',
            url: baseurl + '/api/support/search/ticket/list?support_status=' + status, userData1,
      
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token,
            },
          })

          .then((response) => {
              // setSuccessstatus(true)
              console.log(response.data)
              const data=response.data
              setSearchlist(data)
              
          })
          .catch((error) => {
             console.log(error)
            //setErrormsg(error.response.data.message)
          })
   // }
  }
  const list1 = [
    { key1: "add", key2: "fdg", key3: "sds" },
    { key1: "add", key2: "fdg", key3: "sds" }
  ];
  
  return (
    <>
      
        {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
        {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}
        <div className="cont-margin1 tmar">
    <div className='table1bdstyle'>
            <div class='row pb-2 table1st '>
             

                
           
                
                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext "
                    
                  >

       

                      <div class="col-12 col-sm-12 col-lg-4 col-xl-4 col-md-12  pb-3 ">
                      <label for="formFileLg" className="login-text">
                    Project
                  </label>
                      <center>
                        <InputTextField
                        
                          valueText={projectname}
                           inputName="projectname"
                           inputId="projectname"
                          classname="project-box"
                          inputType="text"
                          placeholderValue="Project"
                         handleInputChange={handleProjectname}
                          PreventEnter={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                       
                         
                        ></InputTextField>

                        <div className="login-error"></div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-4 col-xl-4 col-md-6 pb-3">
                        <label for="formFileLg" className="login-text">
                          Date
                        </label>
                        <center>
                          <InputTextField
                             handleInputChange={handledate}
                             valueText={date}
                            inputName="date"
                            inputId="date"
                             classname="project-box"
                             inputType="date"
                             placeholderValue=" Date"
                            // mindate={todayDate}
                          ></InputTextField>

                          <div className="login-error"></div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12  col-md-6 col-lg-4 ol-md-4 pb-3">
                        <label
                          for="formFileLg"
                          class="form-label"
                          className="login-text"
                        >
                          Status
                        </label>
                        <select
                          className="project-box"
                          id="status"
                          name="status"
                          defaultValue={status}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                         value={status}
                        onChange={handlestatus}
                        >
                          <option value="Select" selected>
                            Select
                          </option>
                          <option value="0">Open</option>
                          <option value="1">Inprogress</option>
                          <option value="2">Closed</option>
                       
                        </select>
                        <div className="login-error"></div>
                      </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 ">
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                          onClick={onSubmitEvent}
                          // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
                          //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                        >
                          <b>Search</b>
                        </button>
                      </center>{' '}
                    </div>
                  
                  </form>
                  <div>
                  <div className='table1bdstyle '>
          <div class="row table1st">
          <div class="pt-1 pb-1  padtext">
                {/* <h5><b>Details</b></h5> */}
              </div>
              {searchlist.length != 0 &&   <table
              class="table "
              readOnly="readOnly"

            >
              <tr class="trst">
                <th><div className='trpad'>Client</div></th>
                <th>Project</th>
                <th>Ticket Number</th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;Status</th>
              </tr>
             
           {searchlist.length != 0 && searchlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>

                    <td><div className='trpad'>{item.client_name}</div></td>
                    <td>{item.project_name}</td>
                    <td>{item.ticket_number}</td>
                    
                    <td> {status === "0" ? 
            <img src={open} className='leftimg1' />: (status === "2" ? <img src={close}
             className='leftimg1' />:
                       <img src={inprogressimg} className='leftimg' />)} </td>
                  </tr>
                )
              })}
            </table>}
          </div>
      
        </div>
                  </div>
                </div>
              
              
            </div>
            </div>
              
              </div>
          
  

    
    </>
  )
}

export default FormPage
