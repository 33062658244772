
import Afterloginhome from '../Component/DashBoard/AfterLoginHome';

function HomePage() {
  return (
    <div>
     <Afterloginhome />
     
    </div>
  );
}

export default HomePage;
