import React from 'react';
import './AfterLoginHome.css';
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from 'react'
import RequestModal from '../Modal/CreateRequest'
import StatusupdateModal from '../Modal/Edit'
import Renewal from '../Modal/Renewal'
import ViewTickets from '../Modal/ViewTickets'
import { baseurl } from '../BaseUrl/Baseurl'
import cancelledimg from '../../assets/images/Status/cancelled.png'
import acceptedimg from '../../assets/images/Status/accepted.png'
import pendingimg from '../../assets/images/Status/pending.png'
import axios from 'axios'
import close from '../../assets/images/Status/closed.png'
import open from '../../assets/images/Status/open.png'
import renewal from '../../assets/images/Status/renewal.png'
import renewed from '../../assets/images/Status/renewed.png'
import suspended from '../../assets/images/Status/suspended.png'
import {useSelector } from 'react-redux'
const WeareHere = () => {
  const [btnname, setBtnname] = useState('')
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [projectid, setProjectid] = useState('');
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [project_renewalid, setProject_renewalid] = useState('');
  const [ticketno, setTicketno] = useState('');
  const [successstatus, setSuccessstatus] = useState(false);
  const [successstatus1, setSuccessstatus1] = useState(false);
  const [successstatus2, setSuccessstatus2] = useState(false);

  const userData = useSelector((state) => state.userDetails)
  useEffect(() => {
  },[requestlist])

  useEffect(() => {
    
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
 
  
      axios({
        method: 'GET',

        url: baseurl+'/api/request/trial/list',

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        }
      })

        .then((response) => {
      
          const RequestData = response.data
      
          setRequestlist(RequestData)
        setSuccessstatus(true)
        })
        .catch((error) => {
          console.log(error)
          // setErrormsg(error.response.data.message)
        })
    
    //Renwal list
    axios({
      method: 'GET',

      url: baseurl+'/api/project/renewal/list',

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token,
      }
     
    })
      .then((response) => {
        console.log(response.data)
        const renewalData = response.data
        setRenewallist(renewalData )
        setSuccessstatus1(true)
      })
      .catch((error) => {
        console.log(error)
        // setErrormsg(error.response.data.message)
      })

    //Ticket list
    axios({
      method: 'GET',

      url: baseurl+'/api/support/issue/ticket/list',

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token,
      }
     
    })
      .then((response) => {
        console.log(response.data)
        const TicketData = response.data
        setTicketlist(TicketData)
        setSuccessstatus2(true)
      })
      .catch((error) => {
        console.log(error)
        // setErrormsg(error.response.data.message)
      })

  }, [modelStatus, updatestatus])

  const toggleModelStatus = () => {
//Refresh the Table 

axios({
  method: 'GET',

  url: baseurl+'/api/request/trial/list',

  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + userData.token,
  }
})

  .then((response) => {

    const RequestData = response.data
    setRequestlist(RequestData)

  })
  .catch((error) => {
    console.log(error)
    // setErrormsg(error.response.data.message)
  })


    setRequeststatus(false)
  };
  const toggleModelStatus1 = () => {

    setUpdatestatus(false)

//Refresh the Table 

   axios({
        method: 'GET',

        url: baseurl+'/api/request/trial/list',

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        }
      })

        .then((response) => {
      
          const RequestData = response.data
          setRequestlist(RequestData)
        setSuccessstatus(true)
        })
        .catch((error) => {
          console.log(error)
          // setErrormsg(error.response.data.message)
        })


  };
  const toggleRenewalStatus = () => {
    console.log("***********")
    setRenewalstatus(false)
  };
  const toggleViewTicketStatus = () => {
    console.log("***********")
    setViewticketstatus(false)
  };
  const requestdata = () => {
    console.log("*****" + requeststatus)
    setRequeststatus(true)
  }
  const Editdata = (id) => {
    setProjectid(id)
    setUpdatestatus(true)
  }
  const RenewalUpdate = (id) => {
    setProject_renewalid(id)
    setRenewalstatus(true)
  }
  const ViewticketstatusUpdate = (id) => {
    setTicketno(id)
    setViewticketstatus(true)
  }
  return (

      <div class="cont-margin tmar ">
        <div className='table1bdstyle'>
          <div class="row table1st ">
          <div class=" col-xl-4 pt-1 pb-1 ">
              <div className='padtext1'>  <h5 ><b>New Request</b></h5></div>
              </div>
              <div class=" col-xl-4 pt-1 pb-1">
              
              </div>
              <div class=" col-xl-3 pt-1 pb-1 ">
              <button onClick={requestdata}
                    type="button"
                    className="createpro-btn"
                  ><FaPlusCircle /> &nbsp;Create Request</button>
                  {requeststatus &&
                    <RequestModal status={requeststatus} btnClick={toggleModelStatus} />
                  }
              </div>
              <div class=" col-xl-1 pt-1 pb-1">
              
              </div>
              {successstatus && requestlist.length == 0 &&
       
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3">
                <center><b><h6>No Data Available</h6></b></center>
             
                </div>}
          
                {requestlist.length!=0 &&  <table
              class="table tablemargin "     
            >
              <tr class="trst pt-1 pb-1 ">
             
                <th><div
              
                 ><font><center>Request ID</center></font></div></th>
                <th><center>Contact</center></th>
                <th><center>Company Name</center></th>
                <th><center>Phone Number</center></th>
                <th>
                 &nbsp;&nbsp;&nbsp;&nbsp;Status
                </th>
                <th>Edit</th>
              </tr>

              {updatestatus &&
                <StatusupdateModal status={updatestatus} btnClick={toggleModelStatus1} ProjectId={projectid} />}

              {requestlist.length!=0 &&requestlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize' >
                    <td ><div ><font><center>{item.request_id!=''&& item.request_id!=undefined?item.request_id:'NA'}</center></font></div></td>
                    <td><center>{item.contact_person!=''&& item.contact_person!=undefined?item.contact_person:'NA'}</center></td>
                    <td><center>{item.company_name!=''&& item.company_name!=undefined?item.company_name:'NA'}</center></td>
                    <td><center>{item.contact_number!=''&& item.contact_number!=undefined?item.contact_number:'NA'}</center></td>
                    <td > 
                     
 {item.request_status === "pending" ? 
<img src={pendingimg}  className='leftimg'/>: 
(item.request_status === "accepted" ?
 <img src={acceptedimg}  className='leftimg'/>:
                       <img src={cancelledimg}  className='leftimg'/>)} 
                 
                    </td>
                    <td><button
                      onClick={() => Editdata(item.request_id)}
                      type="button"
                      className="modalbtn"
                    ><FaEdit /></button>

                    </td>
                  </tr>
                )
              })}
            </table>}
          </div>

        </div>
        <div className='table1bdstyle '>
          <div class="row table1st">
          <div class="pt-1 pb-1 padtext">
                <h5><b>Upcoming Renewal</b></h5>
              </div>

              {successstatus1 && renewallist.length == 0 &&
            // !checkduedate &&
            
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3">
                <center><b><h6>No Data Available</h6></b></center>
             
                </div>}
              {renewallist.length != 0 &&    <table
              class="table  tablemargin"
              readOnly="readOnly"

            >
             
              <tr class="trst">
                <th><div 
                // className='trpad'
                ><center>Project ID</center></div></th>
                <th><center>Contact Person</center></th>
                <th><center>Renewal Date</center></th>
                <th><center>Last Renewal Date</center></th>
                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Status</td>
                <th><center>Edit</center> </th>

              </tr>
              {renewalstatus &&
                <Renewal status={renewalstatus} btnClick={toggleRenewalStatus} ProjectId={project_renewalid} />}
              {renewallist.length != 0 && renewallist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>
                    {/* <td>{index + 1}</td> */}
                    <td><div 
                    // className='trpad'
                    ><center>{item.project_id!=''&& item.project_id!=undefined?item.project_id:'NA'}</center></div></td>
                    <td><center> {item.contact_person!=''&& item.contact_person!=undefined?item.contact_person:'NA'}</center></td>
                    <td><center>{item.next_renewal_date!=''&& item.next_renewal_date!=undefined?item.next_renewal_date:'NA'}</center></td>
                    <td><center>{item.last_renewal_date!=''&& item.last_renewal_date!=undefined?item.last_renewal_date:'NA'}</center></td>
                    <td > 
                      
                     {item.renewal_status === "cancelled" ? 
                    <img src={cancelledimg}  className='leftimg'/>: 
                    (item.renewal_status === "renewed" ?
                     <img src={renewed}  className='leftimg'/>:
                    (item.renewal_status === "suspended" ?
                                           <img src={suspended}  className='leftimg'/>:
                                           <img src={renewal}  className='leftimg'/>))}  
                                     
                                        </td>
                    <td> <center> <button
                      type="button"
                      //  className="img-btn"
                      className="modalbtn"
                      onClick={() => RenewalUpdate(item.project_id)}
                    >
                      <b> 
                         {/* {item.renewal_status === "Pending" ? 
                    <img src={pendingimg}  className='leftimg'/>: 
                    (item.request_status === "Accepted" ?
                     <img src={acceptedimg}  className='leftimg'/>:
                                           <img src={cancelledimg}  className='leftimg'/>)}  */}
                                           <FaEdit />
                                           </b>
                    </button></center></td>

                  </tr>
                )
              })}
            </table>}
          </div>

        </div>
        <div className='table1bdstyle '>
          <div class="row table1st">
          <div class="pt-1 pb-1 padtext">
                <h5><b>Support Request</b></h5>
              </div>

              {successstatus2 && ticketlist.length == 0 &&
            // !checkduedate &&
            
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3">
                <center><b><h6>No Data Available</h6></b></center>
             
                </div>}
              {ticketlist.length != 0 &&    <table
              class="table tablemargin"
              readOnly="readOnly"

            >
             
              <tr class="trst">
                <th><div 
                // className='trpad'
                ><center>Project ID</center></div></th>
                <th><center>Contact</center></th>
                <th><center>Ticket Number</center></th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;Status</th>
                <th><center>Tickets</center></th>
              
              </tr>
              {viewticketstatus &&
                <ViewTickets status={viewticketstatus} btnClick={toggleViewTicketStatus} TicketNumber={ticketno}/>}
           {ticketlist.length != 0 && ticketlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>

                    <td><div
                    //  className='trpad'
                     ><center>{item.project_id!=undefined && item.project_id!=null ? item.project_id:'NA'}</center></div></td>
                    <td><center>{item.contact_person!=undefined && item.contact_person!=null ? item.contact_person:'NA'}</center></td>
                    <td><center> {item.ticket_number!=undefined && item.ticket_number!=null ? item.ticket_number:'NA'}</center></td>
               <td>   {item.request_status === "close" ? 
<img src={close}  className='leftimg2'/>: 

                       <img src={open}  className='leftimg2'/>} </td>  
                       
                    <td>  <center><button
                      type="button"
                      className="viewtic-btn1"
                      onClick={() => ViewticketstatusUpdate(item.ticket_number)}
                    >
                      <b>View</b>
                    </button></center></td>

                  </tr>
                )
              })}
            </table>}
          </div>
      
        </div>
      </div>



  );
}

export default WeareHere;
