import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import '../UserCreation/UserCreation.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
function FormPage({userid,refreshUserlist,refreshUserlist1}) {
    function Loginspace() {
        // var username = document.getElementById('firstname')
    }
    const userData = useSelector((state) => state.userDetails)
    const [name, setName] = useState('')
    const [nameValid, setNameValid] = useState(false)
    const [nameerror, setNameerror] = useState('')
    const [contactno, setContactno] = useState('')
    const [contactnoValid, setContactnoValid] = useState(false)
    const [contactnoerror, setContactnoerror] = useState('')
    const [contactmail, setContactmail] = useState('')
    const [contactmailValid, setContactmailValid] = useState(false)
    const [contactmailerror, setContactmailerror] = useState('')
    const [successstatus, setSuccessstatus] = useState(false)
    const [projectid, setProjectid] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState(false)
    const [passworderror, setPassworderror] = useState('')
    const [role, setRole] = useState('')
    const [roleValid, setRoleValid] = useState(false)
    const [roleerror, setRoleerror] = useState('')
    const [isPasswordShown, setIsPasswordShown] = useState(false)
    const [userList, setUserList] = useState([])
    const [username, setUsername] = useState('')
    const [validationMsg, setValidationMsg] = useState('')
    const [errormsg, setErrormsg] = useState('')

    useEffect(() => {

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })


      axios({
        method: 'GET',
        url: baseurl + '/api/user/get?user_id='+userid, 
  
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        },
      })
      .then((response) => {
          console.log(response.data)
        
      const res=response.data
    
          setName(res.name)
          setContactno(res.contact_number)
          setContactmail(res.email)
          setRole(res.role)
        //  setPassword()
        
      })
      .catch((error) => {
          console.log(error)
          //setErrormsg(error.response.data.message)
      })

    }, [])
    const togglePasswordVisiblity= (event) => {
        setIsPasswordShown(!isPasswordShown )
        event.preventDefault()
      }

      function handleUsernames(e) {
        console.log(e.target.value)
        setUsername(e.target.value)
       
    }

      function onpasswordChange(e) {
   
        setPassword(e.target.value.replace(/\s+/g, ''))
        validateField(e.target.name, e.target.value)
        setSuccessstatus(false)
      
      }
    function handlename(e) {
        console.log(e.target.value)
        setName(e.target.value.replace(/\s+/g, ''))
        setSuccessstatus(false)
      
        validateField(e.target.name, e.target.value)
    }
   
    function handleContactno(e) {
        setContactno(e.target.value.replace(/\s+/g, ''))
        setSuccessstatus(false)
        setValidationMsg('')
        validateField(e.target.name, e.target.value)
    }
    function handleContactmail(e) {
        setContactmail(e.target.value)
        setSuccessstatus(false)
        setValidationMsg('')
        validateField(e.target.name, e.target.value)
    }
    function handleRole(e) {
        setRole(e.target.value.replace(/\s+/g, ''))
        setSuccessstatus(false)
        setValidationMsg('')
        validateField(e.target.name, e.target.value)
    }
  
    const validateField = (fieldName, value) => {
        if (fieldName === 'name') {
            console.log(value)
            if (value.length != 0) {
                setNameerror('')
                setNameValid(true)
            } else {
                setNameerror('Please enter your name')
                setNameValid(false)
            }
        }
     
        if (fieldName === 'contactmail') {
            const regex =
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            if (value.length != 0) {
                console.log(value.match(regex))
                if (value.match(regex)) {

                    setContactmailValid(true)
                    setContactmailerror('')
                } else {

                    setContactmailValid(false)
                    setContactmailerror('Please enter valid email ')
                }
            } else {

                setContactmailValid(false)
                setContactmailerror('Please enter valid email')
            }
        }
       
        if (fieldName === 'contactno') {
            if (value.length != 0 && value.length == 10) {
                setContactnoerror('')
                setContactnoValid(true)
            } else {
                setContactnoerror('Please enter valid Contact number')
                setContactnoValid(false)
            }
        }
       
        if (fieldName === 'password') {
            const strongRegex = new RegExp(
                '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})'
              )
            let passwordValid = value != '' && value.match(strongRegex)
      
         
        
            setPasswordValid(passwordValid)
          
            if (passwordValid === null || passwordValid === undefined) {
              setPassworderror(
                'Password should be more than or equal to 6 character and it should contain lowercase,uppercase,numeric and special character'
              )
              setPasswordValid(false)
            } else {
              setPassworderror('')
              setPasswordValid(true)
            }
          }
          if (fieldName === 'role') {
            console.log(value)
            if (value != 'Select') {
                setRoleerror('')
                setRoleValid(true)
            } else {
                setRoleerror('Please select role')
                setRoleValid(false)
            }
        }
    }

    function SaveandNext(event) {
        event.preventDefault()



         if (nameValid && contactmailValid && contactnoValid && passwordValid && roleValid
        ) {


  
        let formdata = new FormData()
        formdata.append('username',contactmail)
        formdata.append('name',name)
        formdata.append('email',contactmail)
        formdata.append('contact_number', contactno)
        formdata.append('password', password)
        formdata.append('role', role)
        formdata.append('client_id', userData.clientId)
        formdata.append('project_id', userData.projecId)
       
            axios({
                method: 'POST',
                url: baseurl + '/api/user/add', formdata,
          
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + userData.token,
                },
                data:formdata
              })
            
            .then((response) => {
                console.log(response.data)
           
             setName('')
             setPassword('')
             setContactno('')
             setPassword('')
             setRole('')
             setContactmail('')
             setErrormsg('')
             setSuccessstatus(true)
             setValidationMsg('') 
                //To refresh the table 
                refreshUserlist()


                
            })
            .catch((error) => {
                console.log(error)
                setErrormsg(error.response.data.message)
            })

} 
else{
    setValidationMsg('Please fill all the required fields')
}
//  setSuccessstatus(true)
    }
    function SaveandCreateNewUser(event) {
        event.preventDefault()
        if (nameValid && contactmailValid && contactnoValid && passwordValid && roleValid
            ) {
        let formdata = new FormData()
        formdata.append('username',contactmail)
        formdata.append('name',name)
        formdata.append('email',contactmail)
        formdata.append('contact_number', contactno)
        formdata.append('password', password)
        formdata.append('role', role)
        formdata.append('client_id', userData.clientId)
        formdata.append('project_id', userData.projecId)

            axios({
                method: 'POST',
                url: baseurl + '/api/user/add', formdata,
          
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + userData.token,
                },
                data:formdata
              })

            .then((response) => {
                console.log(response.data)
                // alert("success ")
                setName('')
                setPassword('')
                setContactno('')
                setPassword('')
                setRole('')
                setContactmail('')
                setErrormsg('')
                setValidationMsg('')

                  //To refresh the table 
                refreshUserlist1()

                setSuccessstatus(true)
            })
            .catch((error) => {
                console.log(error)
                setErrormsg(error.response.data.message)
            })

        } 
        else{
            setValidationMsg('Please fill all the required fields')
        }
          
     
    }
    return (
        <>

            {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
            {/* {successstatus && <ButtonLink btnPath="/UserList" projectid={projectid} />} */}
            <div className=" pt-3">
                <div >

                  
                    <div className='table1bdstyle'>
                        <div className='table1st'>

                            <div class="row ">


                                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                                    <form
                                        class="row d-flex justify-content-center align-center  blacktext"
                                      
                                    >

                                        <div class="row">
                                        <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-3  pt-3 pb-3">
                                           <b> Create New User</b>
                                           
                                           </div>
                                            <div class="col-12 col-sm-12 col-lg-9 col-xl-9 col-md-3  pt-3 pb-3">
                                            </div>
                                            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6  pb-3">
                                                <label for="formFileLg" className="login-text">
                                                    Name
                                                </label>
                                                <center>
                                                    <InputTextField

                                                        valueText={name}
                                                        inputName="name"
                                                        inputId="name"
                                                        classname="project-box"
                                                        inputType="text"
                                                        placeholderValue="Name"
                                                        handleInputChange={handlename}
                                                        PreventEnter={(e) => {
                                                            e.key === 'Enter' && e.preventDefault()
                                                        }}


                                                    ></InputTextField>

                                                    <div className="login-error">{nameerror}</div>
                                                </center>
                                            </div>
                                            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                                                <label for="formFileLg" className="login-text">
                                                    Mail Id
                                                </label>
                                                <center>
                                                    <InputTextField
                                                        handleInputChange={handleContactmail}
                                                        valueText={contactmail}
                                                        inputName="contactmail"
                                                        inputId="contactmail"
                                                        classname="project-box"
                                                        inputType="text"
                                                        placeholderValue="Contact email"

                                                    ></InputTextField>

                                                    <div className="login-error">{contactmailerror}</div>
                                                </center>
                                            </div>

                                            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-6 pb-3">
                                                <label for="formFileLg" className="login-text">
                                                    Contact Number
                                                </label>
                                                <center>
                                                    <InputTextField
                                                        handleInputChange={handleContactno}
                                                        valueText={contactno}
                                                        inputName="contactno"
                                                        inputId="contactno"
                                                        classname="project-box"
                                                        maxLen={10}
                                                        inputType="text"
                                                        placeholderValue="Contact number"

                                                    ></InputTextField>
                                                </center>
                                                <div className="login-error">{contactnoerror}</div>

                                            </div>
                                            <div class="col-12 col-sm-12 col-lg-6 col-md-12 col-xl-6 ">
                                            <label for="formFileLg" className="login-text">
                                                    Temporary Password
                                                </label>
<center>            <InputTextField
                                     handleInputChange={onpasswordChange}
                                    valueText={password}
                                    inputName="password"
                                    inputId="password"
                                    classname="login-box3"
                                    maxLen={16}
                                    placeholderValue="Temporary Password"
                                    inputType={isPasswordShown  ? 'text' : 'password'}
                                    PreventEnter={(e) => {
                                        e.key === 'Enter' && e.preventDefault()
                                      }}
                                ></InputTextField></center>
                    
                    {isPasswordShown ? (
                  <AiFillEye
                    className="eye-style1"
                    onClick={togglePasswordVisiblity}
                  size={20}
                    
                  />
                ) : (
                    <AiOutlineEyeInvisible
                    className="eye-style1"
                    onClick={togglePasswordVisiblity}
                    size={20}
                  />
                )}
                                <div className="login-error">
                                    {passworderror}

                                </div>
                            </div>

                                            <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3">
                                                <label
                                                    for="formFileLg"
                                                    class="form-label"
                                                    className="formfontst ast"
                                                >
                                                    Role
                                                </label>
                                                <select
                                                    className="project-box"
                                                    id="role"
                                                    name="role"
                                                    //  defaultValue={companyname}
                                                    onKeyPress={(e) => {
                                                        e.key === 'Enter' && e.preventDefault()
                                                    }}
                                                    value={role}
                                                    onChange={handleRole}
                                                >
                                                    <option value="Select" selected>
                                                        Select
                                                    </option>
                                                    <option value="Admin">Admin</option>
                                                    <option value="Client">Client</option>

                                                </select>
                                                <div className="login-error">{roleerror}</div>
                                            </div>





                                        </div>
                                        {successstatus &&<div class=" success pt-3">
                <center><b><h6>Created SuccessFully </h6></b></center>
             
                </div>}
                {errormsg.length!=0 &&<div class=" login-error pt-3">
                <center><b><h6>{errormsg} </h6></b></center>
             
                </div>}
                {validationMsg.length!=0 &&<div class=" login-error pt-3">
                <center><b><h6>{validationMsg} </h6></b></center>
             
                </div>}
                                        <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 ">
                                            <center>
                                                <button
                                                    type="submit"
                                                    className="user-btn"
                                                    name="save"
                                                    onClick={SaveandNext}
                                                // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
                                                //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                                                >
                                                   <b> Save & Next</b>
                                                </button>
                                          &nbsp;&nbsp;&nbsp;
                                                <button
                                                    type="submit"
                                                    className="user-btn"
                                                    name="createnewuser"
                                                    onClick={SaveandCreateNewUser}
                                                // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
                                                //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                                                >
                                              <b>  Save & Create New User</b>
                                                </button>
                                            </center>{' '}
                                        </div>

                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>



        </>
    )
}

export default FormPage
