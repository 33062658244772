import { combineReducers } from 'redux'
const UPDATE_LOGIN_STATUS = 'UPDATE_LOGIN_STATUS'

export function updateLoginStatus(userData) {
  return {
    type: UPDATE_LOGIN_STATUS,
    userData,
  }
}


const defaultUserData = {
  isLoggedIn: false,
  userName: '',
  userId: '',
  role:'',
  token:'',
  Pagename:'Dashboard',
  projectId:'',
  clientId:'',
  profileImage:'',
  projectType:''

}

function userDetails(state = defaultUserData, action) {
  switch (action.type) {
    case UPDATE_LOGIN_STATUS:
      return {
        ...state,
        isLoggedIn: action.userData.isLoggedIn,
        userName: action.userData.userName,
        userId: action.userData.userId,
        Pagename:action.userData.Pagename,
        role:action.userData.role,
        token:action.userData.token,
        projectId:action.userData.projectId,
        clientId:action.userData.clientId,
        profileImage:action.userData.profileImage,
        projectType:action.userData.projectType,
      }
    
    default:
      return state
  }
}

const userDataStore = combineReducers({
  userDetails,
})

export default userDataStore
