import React, { useState, useEffect, useRef } from 'react';
import InputTextField from '../InputText/InputText';
import '../CreateProject/CreateProject.css'
import '../PlanUpdate/PlanUpdate.css'
import { FaPlus, FaEdit } from "react-icons/fa";
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import { baseurl } from '../BaseUrl/Baseurl'
import { pythonurl } from '../BaseUrl/Baseurl'
import { useNavigate, useLocation } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux'
import {
    updateLoginStatus,
    setUserName,
    setUserId,
} from '../../store/userDetails'
function FormPage() {
    const userData = useSelector((state) => state.userDetails)
    const [successstatus, setSuccessstatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileattach, setFileattach] = useState('')
    const [fileattacherror, setFileattacherror] = useState('')
    const [fileattachValid, setFileattachValid] = useState(false)
    const hiddenFileInput = React.useRef(null)
    const[submiterror,setSubmiterror]= useState('')
    const handleClick = (event) => {
      hiddenFileInput.current.click()
    }
    //   const location = useLocation();
    //   const projectType = location.state?.projectType
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
// alert(userData.token)
    }, [])

    //   const handleClick = (event) => {
    //     hiddenFileInput1.current.click()
    //   }

    function handleChangeFile(event) {
        const file = event.target.files[0]
        // let filename = file.name
        setFileattach(file)
        validateField(event.target.name, event.target.files[0])
      }
      const validateField = (fieldName, value) => {   
     
        if (fieldName === 'fileattach') {
         const filesize1 = fileattach.size
          if (filesize1 != 0) {
              setFileattacherror('')
              setFileattachValid(true)
            } else {
              setFileattacherror('Please upload plan')
              setFileattachValid(false)
            }
          }
      }
    const [fields, setFields] = useState([
        {
            // projectname: '',

            project_id: userData.projectId,
            road_type: '',
            start_chainage_in_km: '',
            end_chainage_in_km: '',
            start_lat_coordinate:'',
            start_lon_coordinate:'',
            end_lat_coordinate:'',
            end_lon_coordinate:'',
        },
    ]);

    const [fieldValidations, setFieldValidations] = useState([
        {
            startValid: false,
            startError: '',
            endValid: false,
            endError: '',
            start_lat_coordinateValid:'',
            start_lat_coordinateError:'',
            start_lon_coordinateValid:'',
            start_lon_coordinateError:'',
            end_lat_coordinateValid:'',
            end_lat_coordinateError:'',
            end_lon_coordinateValid:'',
            end_lon_coordinateError:'',

        },
    ]);
    



    const handleAddFields = () => {
        setFields([...fields, {
            project_id: userData.projectId,
            road_type: '',
            start_chainage_in_km: '',
            end_chainage_in_km: '',
            start_lat_coordinate:'',
            start_lon_coordinate:'',
            end_lat_coordinate:'',
            end_lon_coordinate:'',
        }]);

        setFieldValidations([...fieldValidations, {

            startValid: false,
            startError: '',
            endValid: false,
            endError: '',
            start_lat_coordinateValid:'',
            start_lat_coordinateError:'',
            start_lon_coordinateValid:'',
            start_lon_coordinateError:'',
            end_lat_coordinateValid:'',
            end_lat_coordinateError:'',
            end_lon_coordinateValid:'',
            end_lon_coordinateError:'',
        }]);
    };
    const handleFileInputChange = (e, index) => {
        const file = e.target.files[0];
        handleChangeField(index, 'fileattach', file);
    };
    const handleChangeField = (index, fieldName, value) => {
        
        
        const newFields = [...fields];
        newFields[index][fieldName] = value;
        newFields[index].road_type = "Type" + (index+1)
        setFields(newFields);

        const newValidations = [...fieldValidations];
        const validation = newValidations[index];



        if (fieldName === 'start_chainage_in_km') {
            if (value.trim() === '') {
                validation.startValid = false;
                validation.startError = 'Please enter start chainage';
            } else {
                validation.startValid = true;
                validation.startError = '';
            }
        }

        if (fieldName === 'end_chainage_in_km') {
            if (value.trim() === '') {
                validation.endValid = false;
                validation.endError = 'Please enter a end chainage.';
            } else {
                validation.endValid = true;
                validation.endError = '';
            }
        }
    
        if (fieldName === 'start_lat_coordinate') {
            if (value.trim() === '') {
                validation.start_lat_coordinateValid = false;
                validation.start_lat_coordinateError = 'Please enter start latitude.';
            } else {
                validation.start_lat_coordinateValid = true;
                validation.start_lat_coordinateError = '';
            }
        }
        if (fieldName === 'start_lon_coordinate') {
            if (value.trim() === '') {
                validation.start_lon_coordinateValid = false;
                validation.start_lon_coordinateError = 'Please enter start longtitude.';
            } else {
                validation.start_lon_coordinateValid = true;
                validation.start_lon_coordinateError = '';
            }
        }
        if (fieldName === 'end_lat_coordinate') {
            if (value.trim() === '') {
                validation.end_lat_coordinateValid = false;
                validation.end_lat_coordinateError = 'Please enter end latitude.';
            } else {
                validation.end_lat_coordinateValid = true;
                validation.end_lat_coordinateError = '';
            }
        }
        if (fieldName === 'end_lon_coordinate') {
            if (value.trim() === '') {
                validation.end_lon_coordinateValid = false;
                validation.end_lon_coordinateError = 'Please enter end longtitude.';
            } else {
                validation.end_lon_coordinateValid = true;
                validation.end_lon_coordinateError = '';
            }
        }


        newValidations[index] = validation;
        setFieldValidations(newValidations);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
      ///  setLoading(true)
      setSuccessstatus(true)
     
        // Handle your form submission here
        const allFieldsValid = fields.every((field, index) => {
            const validation = fieldValidations[index];

            return (
                field.start_chainage_in_km.trim() !== '' &&
                field.end_chainage_in_km.trim() !== '' &&
                field.start_lat_coordinate.trim() !== '' &&
                field.start_lon_coordinate.trim() !== '' &&
                field.end_lat_coordinate.trim() !== '' &&
                field.end_lon_coordinate.trim() !== '' &&
                validation.startValid &&
                validation.endValid &&
                validation.start_lat_coordinateValid &&
                validation.start_lon_coordinateValid &&
                validation.end_lat_coordinateValid &&
                validation.end_lon_coordinateValid

            );

        });
       

        if (allFieldsValid ) {
            // All fields are valid, you can proceed with form submission
            // const apiUrl = `${baseurl}/api/chainage/add`;

            // axios
            //     .post(apiUrl, fields, {
            //         headers: { 'Content-Type': 'application/json' ,
            //         'Authorization': `Bearer ${userData.token}`},
            //     })
                        //   alert(userData.token)
            
                const apiUrl = `${baseurl}/api/chainage/add`;
    
                axios
                    .post(apiUrl, fields, {
                        headers: { 'Content-Type': 'application/json' ,
                        'Authorization': 'Bearer'+ ' ' + userData.token},
                    })


                .then((response) => {

                    const dat = response.data
                    // console.log(response.data)                   
                   // setSuccessstatus(true)
                    //  alert("Success")
                     planupload()
                     alert("success")

                })
                .catch((error) => {
                    console.log(error)
                    //setErrormsg(error.response.data.message)
                })

            
             
               
        } else {
            // Display an error message or prevent submission         
            setLoading(false)
           setSubmiterror("Please fill all the required fields")
           
        }
       

    };
    function planupload(){
     

        if ( fileattachValid) {
            let plan = new FormData()
            plan.append('project_id',userData.projectId )
            plan.append('plan_name','Roadplan' )
            plan.append('files',fileattach)

        // axios
        // .post(pythonurl + '/api/plan/create', plan,{
        //   headers: { 'Content-Type': 'multipart/form-data',
        // //  'Authorization': `Bearer ${userData.token}` 
        
        // },
        // })
        axios({
            method: 'POST',
      
            url: pythonurl + '/api/plan/create',
      
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token,
            },
            data:plan,
           
          })
        .then((response) => {
        
          const dat=response.data     

        //  setSuccessstatus(true)
                    setSubmiterror("")
                    Project_Creation_status_update()
                    
                   
        })
        .catch((error) => {
           console.log(error)
          //setErrormsg(error.response.data.message)
        })
    }
    else{
        setSubmiterror("Please fill all the required fields")
    }
    }
    function Project_Creation_status_update()
    {
  
      axios({
        method: 'PUT',
        url: baseurl + '/api/project/update/project_creation_status?project_id='+userData.projectId+'&project_creation_status=2',
    
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        },
      })
      .then((response) => {
      
        const dat=response.data
      
        setLoading(false)
        setSuccessstatus(true)
      })
      .catch((error) => {
         console.log(error)
        //setErrormsg(error.response.data.message)
      })
    }
    return (
        <>   {successstatus && <ButtonLink btnPath="/Boq" />}
            <div className="cont-margin1 tmar pt-3">
                <div className='table1bdstyle'>
                    <div className='table1st'>
                        <div className='planstyle'>

                            <div class="row pb-5">


                                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  ">
                                    {loading && !successstatus &&
                                        <div class="pt-5">
                                            <center><h3>Please wait...</h3></center>
                                            {/* <br></br> */}
                                            <center><h3>Processing the plan</h3></center>
                                        </div>
                                    }
                                    {!loading && !successstatus &&
                                        <form onSubmit={handleSubmit}>
                                            <div class="row pb-3">
                                                <div class="col-lg-8 col-xl-8 "></div>
                                                <div class="col-lg-4 col-xl-4 ">
                                                    <button onClick={handleAddFields}
                                                        type="button"
                                                        className="create-btn"
                                                    ><FaPlus /> &nbsp;Add</button>
                                                </div>
                                            </div>
                                            {fields.map((field, index) => (
                                                <div class="row" key={index}>

                                                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                                                        <label htmlFor={`housingname-${index}`} className="login-text">
                                                            Start Chainge (Type &nbsp;{index})
                                                        </label>
                                                        <center>
                                                            <InputTextField
                                                                handleInputChange={(e) => handleChangeField(index, 'start_chainage_in_km', e.target.value)}
                                                                valueText={field.start_chainage_in_km}
                                                                inputName={`start_chainage_in_km-${index}`}
                                                                inputId={`start_chainage_in_km-${index}`}
                                                                classname="project-box"
                                                                inputType="text"
                                                                placeholderValue="Start Chainage"
                                                            />
                                                            <div className="login-error">{fieldValidations[index].startError}</div>
                                                        </center>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12 pb-3">
                                                        <label htmlFor={`end_chainage_in_km-${index}`} className="login-text">
                                                            End Chainge (Type &nbsp;{index})
                                                        </label>
                                                        <center>
                                                            <InputTextField
                                                                handleInputChange={(e) => handleChangeField(index, 'end_chainage_in_km', e.target.value)}
                                                                valueText={field.end_chainage_in_km}
                                                                inputName={`end_chainage_in_km-${index}`}
                                                                inputId={`end_chainage_in_km-${index}`}
                                                                classname="project-box"
                                                                inputType="text"
                                                                placeholderValue="End Chainage"
                                                            />
                                                            <div className="login-error">{fieldValidations[index].endError}</div>
                                                        </center>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-12 pb-3">
                                                        <label htmlFor={`end_chainage_in_km-${index}`} className="login-text">
                                                            Start&nbsp;Latitude (Type &nbsp;{index})
                                                        </label>
                                                        <center>
                                                            <InputTextField
                                                                handleInputChange={(e) => handleChangeField(index, 'start_lat_coordinate', e.target.value)}
                                                                valueText={field.start_lat_coordinate}
                                                                inputName={`start_lat_coordinate-${index}`}
                                                                inputId={`start_lat_coordinate-${index}`}
                                                                classname="project-box"
                                                                inputType="text"
                                                               // placeholderValue="End Chainage"
                                                            />
                                                            <div className="login-error">{fieldValidations[index].start_lat_coordinateValid}</div>
                                                        </center>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-12 pb-3">
                                                        <label htmlFor={`end_chainage_in_km-${index}`} className="login-text">
                                                            Start&nbsp;Longtitude  (Type &nbsp;{index})
                                                        </label>
                                                        <center>
                                                            <InputTextField
                                                                handleInputChange={(e) => handleChangeField(index, 'start_lon_coordinate', e.target.value)}
                                                                valueText={field.start_lon_coordinate}
                                                                inputName={`start_lon_coordinate-${index}`}
                                                                inputId={`start_lon_coordinate-${index}`}
                                                                classname="project-box"
                                                                inputType="text"
                                                                //placeholderValue="End Chainage"
                                                            />
                                                            <div className="login-error">{fieldValidations[index].start_lon_coordinateError}</div>
                                                        </center>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-12 pb-3">
                                                        <label htmlFor={`end_chainage_in_km-${index}`} className="login-text">
                                                            End&nbsp;Latitude              &nbsp;(Type &nbsp;{index})
                                                        </label>
                                                        <center>
                                                            <InputTextField
                                                                handleInputChange={(e) => handleChangeField(index, 'end_lat_coordinate', e.target.value)}
                                                                valueText={field.end_lat_coordinate}
                                                                inputName={`end_lat_coordinate-${index}`}
                                                                inputId={`end_lat_coordinate-${index}`}
                                                                classname="project-box"
                                                                inputType="text"
                                                              //  placeholderValue="End Chainage"
                                                            />
                                                            <div className="login-error">{fieldValidations[index].end_lat_coordinateError}</div>
                                                        </center>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-12 pb-3">
                                                        <label htmlFor={`end_chainage_in_km-${index}`} className="login-text">
                                                            End&nbsp;Longtitude  (Type &nbsp;{index})
                                                        </label>
                                                        <center>
                                                            <InputTextField
                                                                handleInputChange={(e) => handleChangeField(index, 'end_lon_coordinate', e.target.value)}
                                                                valueText={field.end_lon_coordinate}
                                                                inputName={`end_lon_coordinate-${index}`}
                                                                inputId={`end_lon_coordinate-${index}`}
                                                                classname="project-box"
                                                                inputType="text"
                                                              //  placeholderValue="End Chainage"
                                                            />
                                                            <div className="login-error">{fieldValidations[index].end_lon_coordinateError}</div>
                                                        </center>
                                                    </div>





                                                </div>

                                            ))}
                                            <div class="col-sm-12 col-12 col-lg-12 col-md-12 col-xl-12 pt-2 ">
                                                <label className="login-text">
                                                    Plan
                                                </label>

                                                <button
                                                    type="button"
                                                    className="project-box "
                                                    onClick={() => handleClick()}
                                                >
                                                    {fileattach != undefined && fileattach != ''
                                                        ? 'Uploaded '
                                                        : 'Upload'}
                                                </button>
                                                <input
                                                    type="file"
                                                    ref={hiddenFileInput}
                                                    onChange={handleChangeFile}
                                                    style={{ display: 'none' }}
                                                    name="fileattach"
                                                    id="fileattach"
                                                    accept="image/jpeg"
                                                />
                                                <div className="login-error">{fileattacherror}</div>
                                            </div>
                                            <div className="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-1">
                                                <center>
                                                    
                                                    <div className="login-error pb-3 pt-3">{submiterror}</div>
                                                    <button
                                                        type="submit"
                                                        className="project-btn"
                                                        name="submit"
                                                    //   disabled={!projectnameValid}
                                                    >
                                                        <b>Submit</b>
                                                    </button>
                                                </center>
                                            </div>
                                        </form>}


                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default FormPage;
