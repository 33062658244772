import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AfterloginHeader from './Component/AfterLoginHeader/AfterLoginHeader'
import Sidebar from './Component/SideBar/SideBar'
import AfterLoginhome from './Pages/DashBoard'
import ProjectPage from './Pages/Project'
import Sample from './Component/Projects/Projects'
import PlanUpdate from './Component/PlanUpdate/PlanUpdate2'
import Loginform from './Pages/LoginPage'
// import Boq from './Component/Boq/Boq'
import Sample2 from './Component/horizontallinesample/ss'
import { useSelector } from 'react-redux'
import CreateUser from './Component/UserCreation/UserCreation'
import Userlist from './Component/Userlist/Userlist'
import Report from './Component/Report/Clientstatus'
import SearchClient from './Component/SearchClient/SearchClient'
import Project from './Component/Projectlist/Projects'
import Calculate from './Component/CalculateRatio/Calculateratio'
import CalculatedDetails from './Component/CalculateRatio/CalculatedDetails'
import Support from './Component/Support/Support'
import ViewProject from './Component/ViewProjects/ViewProjects'
import EditUser from './Component/ViewAllProjectPages/EditUser'
import RoadPlanUpdate from './Component/RoadPlanUpdate/PlanUpdate'
import ResourceLocationDetails from './Component/Resource location details/ResourceLocationDetails'
import Adminconsole from './Component/AdminConsole/Adminconsole'

function RoutePage() {
   const isLoggedIn = useSelector((state) => state.userDetails.isLoggedIn)

  return (
    <>

      <Router>
        {isLoggedIn ? <AfterloginHeader /> : ''}
        {/* {isLoggedIn ? <Sidebar /> : ''} */}
        <Routes>
          <Route path="/" index element={<Loginform />} />
          <Route path="/DashBoard" index element={<AfterLoginhome />} />
          <Route path="/Project" index element={<Project />} />
          <Route path="/CreateProject" index element={<ProjectPage />} />
          <Route path="/PlanUpdate" index element={<PlanUpdate />} />
         {/* <Route path="/Boq" index element={<Boq />} />  */}
          <Route path="/UserCreation" index element={<CreateUser />} />
          <Route path="/UserList" index element={<Userlist />} />
          <Route path="/Report" index element={<Report />} />
          <Route path="/SearchClient" index element={<SearchClient />} />
          <Route path="/Calculate" index element={<Calculate />} />
          <Route path="/CalculatedDetails" index element={<CalculatedDetails />} />
          <Route path="/Support" index element={<Support />} />
          <Route path="/ViewProject" index element={<ViewProject />} />
          {/* <Route path="/sample" index element={<Sample />} /> */}
          <Route path="/sample2" index element={<Sample2 />} />
          <Route path="/User" index element={<EditUser />} />
          <Route path="/RoadPlanUpdate" index element={<RoadPlanUpdate />} />
          <Route path="/ResourceLocationDetails" index element={<ResourceLocationDetails />} />
          <Route path="/AdminConsole" index element={<Adminconsole />} />
        </Routes>
     
      </Router>
    </>
  )
}

export default RoutePage
