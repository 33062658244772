import React,{useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal, Form } from 'react-bootstrap'
import './Modal.css'
import CreateRequest from '../CreateRequest/CreateRequest'
import { useState} from 'react'
import InputTextField from '../InputText/InputText'
import {baseurl} from '../BaseUrl/Baseurl'
import axios from 'axios'
import {useSelector } from 'react-redux'
export default function Model({ btnClick, status, ProjectId }) {
  const [projectstatus, setProjectstatus] = useState('')
  const [projectstatusValid, setProjectstatusValid] = useState(false)
  const [projectstatuserror, setProjectstatuserror] = useState('')
  const [modalVisible, setModalVisible] = useState(status);
  const userData = useSelector((state) => state.userDetails)
  const [message, setMessage] = useState('')
    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
console.log("hi");
    }, [status,modalVisible])

  function handleProjectstatus(e) {
   console.log(e.target.value)
    setProjectstatus(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  const validateField = (fieldName, value) => {
 
  }
  function handlemessage(e) {

    console.log(message)

    setMessage(e.target.value.replace(/\s+/g, ''))

    validateField(e.target.name, e.target.value)

  }
  function onSubmitEvent(event) {
    event.preventDefault() 

  let userData1 = new FormData()
  userData1.append('request_id',ProjectId)
  userData1.append('status_comment', message)
  userData1.append('request_status', projectstatus)
console.log(ProjectId)
console.log(projectstatus)
console.log(message)
    axios({
      method: 'PUT',

      url: baseurl + '/api/request/trial/update/status',

      headers: {
        'Content-Type': 'multipart/form-data',     
        Authorization: 'Bearer ' + userData.token,
      },

      data: userData1,
    })
      
    .then((response) => {
  // alert("success Status updated")
  setModalVisible(false)
    })
    .catch((error) => {
       console.log(error)
     // setErrormsg(error.response.data.message)
    })
    
    
  }

  return (
    <>
      <Modal show={modalVisible} onHide={btnClick} id="modal-size1">
        <Modal.Body>
          <center>  <div className="model-top">Edit</div></center>
          {/* <CreateRequest /> */}
          
  
          <div className="cont-modalstyle">
                <div class="row pb-2">               
                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                    onSubmit={(event) => {
                      onSubmitEvent(event);
                      btnClick(); // Close the modal
                    }}
                  >

                    <div class="row">
                    <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                      <label for="formFileLg" className="login-text">
                    Request ID
                  </label>
                      <center>
                        <InputTextField
                      //  handleInputChange={handleContactperson}
                      //  valueText={contactperson}
                      //  inputName="contactperson"
                      //  inputId="contactperson"
                       classname="project-box"
                      //  maxLen={20}
                       inputType="text"
                       placeholderValue={ProjectId}
                      disabled
                     
                        ></InputTextField>

                        
                        </center>
                      </div>
                     
                      <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-md-12 pb-3">
                  <label
                    for="formFileLg"
                    class="form-label"
                    className="formfontst ast"
                  >
                    Status
                  </label>
                  <select
                    className="project-box"  
                     id="projectstatus"
                     name="projectstatus"
                    //  defaultValue={companyname}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    value={projectstatus}
                 onChange={handleProjectstatus}
                  >
                    <option value="Select" selected>
                      Select
                    </option>
                    <option value="pending">Pending</option>
                    <option value="accepted">Accepted</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                  <div className="login-error">{projectstatuserror}</div>
                </div>
                    
                    
                <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pt-4  ">
<center>
                        <div class="textareamargin">              

                          <textarea class="form-control" id="message" name="message" rows="3" placeholder='Message' onChange={handlemessage}></textarea>
                        </div>

                        </center>

                      </div>

                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                          // disabled={!(projectstatusValid )}
                        >
                          <b>Submit</b>
                        </button>
                      </center>{' '}
                    </div>
                  
                  </form>
                </div>
              
              </div>
            </div>
           
         
        </Modal.Body>
      </Modal>
    </>
  )
}
