import React from "react";
import logo1 from "../../assets/images/brick_icons/Logo.png";
import "./AfterLoginheader.css";
import notification from "../../assets/images/brick_icons/Notification.png";
import logout from "../../assets/images/brick_icons/logout.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { Outlet, Link } from "react-router-dom";
import "@fontsource/plus-jakarta-sans";
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from "../../store/userDetails";
import { useState, useEffect } from "react";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import profile from "../../assets/images/profile.png";
export default function Header() {
  const userData = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    //Global Data

    if (userData.Pagename == undefined) {
      dispatch(
        updateLoginStatus({
          isLoggedIn: true,
          Pagename: "Admin_Console",
          userName: userData.userName,
          userId: userData.userId,
          role: userData.role,
          token: userData.token,
          projectId: userData.projectId,
          clientId: userData.clientId,
          projectType: userData.projectType,
          profileImage: userData.profileImage,
        })
      );
    }
  }, [userData.Pagename]);

  const [linkname, setLinkname] = useState("");

  function updatelinkname(lname) {
    //Page name updata
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: lname,
        userName: userData.userName,
        userId: userData.userId,
        role: userData.role,
        token: userData.token,
        projectId: userData.projectId,
        clientId: userData.clientId,
        profileImage: userData.profileImage,
        projectType: userData.projectType,
      })
    );
  }

  function logoutfunc() {
    //Logout
    axios({
      method: "PUT",
      url: baseurl + "/api/logout?user_id=" + userData.userId,

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => { })
      .catch((error) => {
        ////////////////////////////console.log(error.response.data.message)
      });
    localStorage.clear();
    dispatch(
      updateLoginStatus({
        isLoggedIn: true,
        Pagename: "",
        userName: "",
        userId: "",
        role: "",
        token: "",
        projectId: "",
        clientId: "",
        profileImage: "",
      })
    );

    window.location.href = "/";
  }
  return (
    <div className="headersizediv ">
      <div className="headercolor">
        <nav className="navbar navbar-expand-xl navbar-light ">
          <div className="container-fluid ">
            <div className="logo-inside">
              <center>
                <img
                  src={logo1}
                  alt="not found"
                  class="rounded"
                  width={150}
                  height={65}
                />
              </center>
            </div>
            <div>
              <button
                className="navbar-toggler expandbtn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent1"
                aria-controls="navbarSupportedContent1"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <center className="padlh">
                  {" "}
                  <span className="navbar-toggler-icon"></span>
                </center>
              </button>
            </div>
            <div
              className="collapse navbar-collapse linkstylehead"
              id="navbarSupportedContent1"
            >
              <ul className="d-flex align-items-center justify-content-end w-100  mb-0 pb-0">
                <li className="ml-5">
                  <div
                    className={
                      userData.Pagename === "Admin_Console"
                        ? "headlinkcolor-active pt-3"
                        : "headlinkcolor-inactive pt-3"
                    }
                  >
                    <center>
                      <Link
                        to="/AdminConsole"
                        onClick={() => updatelinkname("Admin_Console")}
                        className="Linkeffect"
                      >
                        <font style={{ fontSize: '18px' }}
                          className={
                            userData.Pagename === "Admin_Console"
                              ? "navtextcolor"
                              : "navtextcolor1"
                          }
                        >
                          Admin&nbsp;Console
                        </font>
                      </Link>
                    </center>
                    <div>
                      {" "}
                      {userData.Pagename === "Admin_Console" && (
                        <hr className="linkunderline m-0"></hr>
                      )}
                    </div>
                  </div>
                </li>

                <li className="ml-5">
                  <div
                    className={
                      userData.Pagename === "Dashboard"
                        ? "headlinkcolor-active pt-3	"
                        : "headlinkcolor-inactive pt-3	"
                    }
                  >
                    <center>
                      <Link
                        to="/DashBoard"
                        onClick={() => updatelinkname("Dashboard")}
                        className="Linkeffect"
                      >
                        {/* <div class="row">
				<div class="col-xl-2 col-lg-2 col-md-1"></div>
				<div class="col-xl-1 col-lg-1 col-md-1">
				{userData.Pagename==="Dashboard"
				? <img src={dashboardhover}/>
				:<img src={dashboard}/>}
				</div>
				<div class="col-xl-1 col-lg-1 col-md-1"></div>
				<div class="col-xl-3 col-lg-3 col-md-3"> */}
                        <font style={{ fontSize: '18px' }}
                          className={
                            userData.Pagename === "Dashboard"
                              ? "navtextcolor"
                              : "navtextcolor1"
                          }
                        >
                          Dashboard
                        </font>

                        {/* </div>
				<div class="col-xl-3 col-lg-3 col-md-3"></div>
				</div> */}
                      </Link>
                    </center>
                    <div>
                      {" "}
                      {userData.Pagename === "Dashboard" && (
                        <hr className="linkunderline m-0"></hr>
                      )}
                    </div>
                  </div>
                </li>

                <li className="ml-5">
                  <div
                    className={
                      userData.Pagename === "Create_Project"
                        ? "headlinkcolor-active pt-3"
                        : "headlinkcolor-inactive pt-3"
                    }
                  >
                    <center>
                      <Link
                        to="/Project"
                        onClick={() => updatelinkname("Create_Project")}
                        className="Linkeffect"
                      >
                        {/* <div class="row">
				<div class="col-xl-2 col-lg-2 col-md-2"></div>
				<div class="col-xl-1 col-lg-1 col-md-1">
				{userData.Pagename==="Create_Project"
				? <img src={projecthover}/>
				:<img src={project}/>}
				</div>
				<div class="col-xl-1 col-lg-1 col-md-1"></div>
				<div class="col-xl-3 col-lg-3 col-md-3"> */}
                        <font style={{ fontSize: '18px' }}
                          className={
                            userData.Pagename === "Create_Project"
                              ? "navtextcolor"
                              : "navtextcolor1"
                          }
                        >
                          Project
                        </font>

                        {/* </div>
				<div class="col-xl-3 col-lg-3 col-md-3"></div>
				</div> */}
                      </Link>
                    </center>
                    <div>
                      {" "}
                      {userData.Pagename === "Create_Project" && (
                        <hr className="linkunderline m-0"></hr>
                      )}
                    </div>
                  </div>
                </li>

                <li className="ml-5">
                  <div
                    className={
                      userData.Pagename === "Report"
                        ? "headlinkcolor-active pt-3"
                        : "headlinkcolor-inactive pt-3"
                    }
                  >
                    <center>
                      <Link
                        to="/Report"
                        onClick={() => updatelinkname("Report")}
                        className="Linkeffect"
                      >
                        {/* <div class="row">
				<div class="col-xl-2 col-lg-2 col-md-2"></div>
				<div class="col-xl-1 col-lg-1 col-md-1">
				{userData.Pagename==="Report"
				? <img src={reporthover}/>
				:<img src={report}/>}
				</div>
				<div class="col-xl-1 col-lg-1"></div>
				<div class="col-xl-3 col-lg-3"> */}
                        <font style={{ fontSize: '18px' }}
                          className={
                            userData.Pagename === "Report"
                              ? "navtextcolor"
                              : "navtextcolor1"
                          }
                        >
                          Report
                        </font>

                        {/* </div>
				<div class="col-xl-3 col-lg-3"></div>
				</div> */}
                      </Link>
                    </center>
                    <div>
                      {" "}
                      {userData.Pagename === "Report" && (
                        <hr className="linkunderline m-0"></hr>
                      )}
                    </div>
                  </div>
                </li>

                <li className="ml-5">
                  <div
                    className={
                      userData.Pagename === "Support"
                        ? "headlinkcolor-active pt-3"
                        : "headlinkcolor-inactive pt-3"
                    }
                  >
                    <center>
                      <Link
                        to="/Support"
                        onClick={() => updatelinkname("Support")}
                        className="Linkeffect"
                      >
                        {/* <div class="row">
				<div class="col-xl-2 col-lg-2"></div>
				<div class="col-xl-1 col-lg-1">
				{userData.Pagename==="Support"
				? <img src={supporthover}/>
				:<img src={support}/>}
				</div> */}
                        {/* <div class="col-xl-1 col-lg-1"></div> */}
                        {/* <div class="col-xl-3 col-lg-3"> */}
                        <font style={{ fontSize: '18px' }}
                          className={
                            userData.Pagename === "Support"
                              ? "navtextcolor"
                              : "navtextcolor1"
                          }
                        >
                          Support
                        </font>

                        {/* </div>
				<div class="col-xl-3 col-lg-3"></div>
				</div> */}
                      </Link>
                    </center>
                    <div>
                      {" "}
                      {userData.Pagename === "Support" && (
                        <hr className="linkunderline m-0"></hr>
                      )}
                    </div>
                  </div>
                </li>

                {/* <li className="photostyle m-0 pt-1 ml-5"> */}
                {/* </li> */}
                <li className="ml-5">
                  <div className=" mt-0" style={{ width: '250px' }}>
                    <img src={profile} className="imageprof" />
                    <font className="namefont w-100">
                      <b> {userData.userName}</b>{" "}
                    </font>
                    <br></br>
                    <font className="graytext">{userData.role}</font>
                  </div>
                </li>

                <li className=" pt-1">
                  <center>
                    <Link>
                      <img src={notification} className="icon1st" />
                    </Link>
                  </center>
                </li>

                <li className="logoutpad pt-1">
                  <center>
                    <Link onClick={(e) => logoutfunc()}>
                      <img src={logout} className="icon1st" />
                    </Link>
                  </center>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
