
import Login from '../Component/LoginForm/LoginForm';
import React from 'react'
import banner2 from '../assets/images/Icons_and_images/Banner-bg.jpg'
import logo from '../assets/images/Icons_and_images/logo.jpg'
import { Link } from 'react-router-dom'
import '../Component/LoginForm/Loginbackstyle.css'
import "@fontsource/plus-jakarta-sans";
function LoginandRegistration() {
    return (
<div class="container-fluid">
        <div
            class="freebg"
            style={{ backgroundImage: `url(${banner2})` }}
        >
            <div>

                <div className='row '>
                    <div class="col-lg-1 col-xl-1">  </div>
                    <div class="col-lg-10 col-xl-10 space ">
                    <div className='row '>
                    <div class="col-lg-1 col-xl-1">  </div>
                    <div class="col-lg-10 col-xl-10 space  ">
                        <div className='formback'>
                            <form>
                                <div class="row ">

                                    <div class="col-lg-6 col-xl-6 col-md-6">
                                        <div className='foursidepad1 '>
                                   <img src={logo} />
                                   <div class="pt-4 pb-3">
                                 <h3><b>Avoid delays & Save</b></h3>
                              <h3><b>upto 7% on project costs</b></h3>
                              <ul class="pt-3 pb-2 " id="courselist1">
<li class="pricepad">
  Get better site visibility of your all construction projects
</li>
<li class="pricepad fontstt">
Streamline your labour management process
</li>
<li class="pricepad">
Efficient material management
</li>
</ul>

                        <button
                          type="submit"
                          className="next-btn1"
                        //   name="submit"
                          align="left"
                          disabled
                        //   disabled={!(nameValid && phnoValid)}
                        >
                          <b>Next &nbsp;&nbsp; <span>&#8594;</span></b>
                        </button>
              

                              </div>
                                    </div>
                                    </div>

                                    <div class="col-lg-6 col-xl-6 col-md-6 ">
                                 <Login />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col-lg-1 col-xl-1">  </div>
                    </div>
                    </div>
                    <div class="col-lg-1 col-xl-1">  </div>

                </div>

            </div>
        </div>
        </div>

    )
}
export default LoginandRegistration

