import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
// import '../Boq/Boq.css'
import View from '../Modal/View'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import cancelledimg from '../../assets/images/brick_icons/Support.png'
import acceptedimg from '../../assets/images/brick_icons/Support.png'
import pendingimg from '../../assets/images/brick_icons/Dashboard.png'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
// import plan from '../../assets/images/plan.png'
function FormPage() {

  const userData = useSelector((state) => state.userDetails)
  const [successstatus, setSuccessstatus] = useState('')
  const [structureid, setStructureid] = useState('')
  const [viewstatus1, setViewstatus1] = useState(false)
  const [boqlist, setBoqlist] = useState([])
  const [levelList, setLevelList] = useState([])
  const [levelListStatus, setLevelListStatus] = useState(false)
  const [planimgnamelist1, setPlanimgnamelist1] = useState('')
  const [planimg1, setPlanimg1] = useState('')

  const [level, setlevel] = useState('')
  const [levelValid, setlevelValid] = useState(false)
  const [levelerror, setlevelerror] = useState('')
  const [itemofwork, setItemofwork] = useState('')
  const [itemofworkValid, setItemofworkValid] = useState(false)
  const [itemofworkerror, setItemofworkerror] = useState('')
  const [structuretype, setStructuretype] = useState('ALL')
  const [structuretypeValid, setStructuretypeValid] = useState(false)
  const [structuretypeerror, setStructuretypekerror] = useState('')
  const [date, setDate] = useState('')
  const [dateValid, setDateValid] = useState(false)
  const [dateerror, setDateerror] = useState('')
  const [owner, setOwner] = useState('')
  const [ownerValid, setOwnerValid] = useState(false)
  const [ownererror, setOwnererror] = useState('')
  const [requeststatus, setRequeststatus] = useState(false);
  //   const [itemofworkValid, setProjecttypeValid] = useState(false)
  //   const [projecttypeerror, setProjecttypeerror] = useState('')
  const [reslist1, setReslist1] = useState([])
  const [reslist2, setReslist2] = useState([])
  const [itemofworklist, setItemofworklist] = useState([])
  const [structurelist, setStructurelist] = useState([])
  const [checkboxval, setCheckboxval] = useState(false);
  const [checklist, setChecklist] = useState([])
  const [checkduedate, setCheckduedate] = useState(false);
  const [viewimagestatus, setViewimagestatus] = useState(false);
  const [modalstatus, setModalstatus] = useState(false);
  const [currentimg, setCurrentimg] = useState('')
  const [planimgnamelist, setPlanimgnamelist] = useState([])
  const [planimg, setPlanimg] = useState('')
  const [structureitem, setStructureitem] = useState('')
  const [structuretype1, setStructuretype1] = useState('')
  const [viewstatus, setViewstatus] = useState(false)
  const [buttonColor, setButtonColor] = useState('blue');
  const [isClicked, setIsClicked] = useState(false);
  const [index, setIndex] = useState(0);
  const [btnIndex, setBtnIndex] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    //  //alert("BOQ  "+userData.projectId)
    //    const id='PR-1'

    // axios({
    //   method: 'GET',
    //   url: baseurl + '/api/plan/list/structure_items' + '?project_id=' + userData.projectId,

    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     Authorization: 'Bearer ' + userData.token,
    //   },
    // })
    //   .then((response) => {
    //     console.log(response.data)
    //     // //alert("hello")
    //     const data = response.data
    //     console.log(data)
    //     setBoqlist(data)
    //     // setSuccessstatus(true)
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //     //setErrormsg(error.response.data.message)
    //   })





    axios({
      method: 'GET',

      url: baseurl + '/api/plan/list/level/details?project_id=' + userData.projectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }
    })
      .then((response) => {

        const des = response.data
        console.log(JSON.stringify(response.data))
        const levelList1 = des.level_or_chainage_details || []
        setLevelList(levelList1)


      })
      .catch((error) => {
        console.log(error)
        //setErrormsg(error.response.data.message)
      })
    //Level Table 
    if (userData.projectType === 'Building') {
      axios({
        method: 'GET',
        url: baseurl +
          '/api/plan/view?level=' + 'FLOOR' + '&structure_type=' + 'SECTIONS' + '&project_id=' + userData.projectId,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
      })
        .then((response) => {

          const data = response.data

          const imglist = data.image_data
          setPlanimg1(imglist)
          const namelist = data.filenames
          setPlanimgnamelist1(namelist)

        })
        .catch((error) => {
          console.log(error)
          //setErrormsg(error.response.data.message)
        })

    }
    if (userData.projectType === 'Road') {
      axios({
        method: 'GET',

        url: baseurl +
          //'/api/plan/view?level=FLOOR&structure_type=SECTIONS&project_id=PR-1',

          '/api/plan/view?level=' + 'TYPE' + '&structure_type=' + 'ALL' + '&project_id=' + userData.projectId,

        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token
        }
      })
        .then((response) => {

          const data = response.data

          const imglist = data.image_data
          setPlanimg1(imglist)
          const namelist = data.filenames
          setPlanimgnamelist(namelist)

        })
        .catch((error) => {
          console.log(error)
          //setErrormsg(error.response.data.message)
        })

    }


  }, [])

  const onclickdata = (id) => {
    setStructureid(id)
    setViewstatus(true)
  }
  const toggleModelStatus1 = () => {

    setViewstatus(false)
  };
  const list1 = [
    { key1: "F0'", key2: "Level 0", key3: "sds" },
    { key1: "F0", key2: "Level 1", key3: "sds" },
    { key1: "F1", key2: "Level 2", key3: "sds" },
    { key1: "F2", key2: "Level 3", key3: "sds" },
    { key1: "F3", key2: "Level 4", key3: "sds" },
    { key1: "F4", key2: "Level 5", key3: "sds" }
  ];



  function handlelevel(e) {
    const val6 = e.target.value.replace(/-.*$/, '')
   
    setlevel(e.target.value)
    validateField(e.target.name, e.target.value)

    setStructuretype('ALL')
    setItemofworkValid('')
    const leveldata = e.target.value
    let fData = new FormData()
    fData.append('project_id', userData.currentprojectId)
    fData.append('level', val6)

    axios({
      method: 'GET',

      url: baseurl + '/api/plan/list/structure_type?project_id=' + userData.projectId + '&level=' + val6,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {

        console.log(response.data)
        const dat = response.data
        const struc = dat.structure_types
        // const modifiedList1 = struc.map(item => item.replace(/ /g, '_'));
        console.log(struc)
        setStructurelist(struc)

      })
      .catch((error) => {
        console.log(error)
       
      })
  }


useEffect(()=>
{

  if(!levelListStatus)
  {
  let fData = new FormData();
  fData.append('project_id', userData.projectId);
  axios({
    method: 'GET',

    url: baseurl + '/api/plan/specifications/get/due_date?project_id=' + userData.projectId,

    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + userData.token
    }
  })
    .then((response) => {
      console.log("CCCCChiane List"+response.data);
      const dat = response.data || []
      setTableData(dat);
      setLevelListStatus(true)
    })
    .catch((error) => {
      console.log(error);
    });
  
  }
 
})

  function handlestructuretype(e) {

    console.log(e.target.value)
    setStructuretype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  const validateField = (fieldName, value) => {
    if (fieldName === 'itemofwork') {

      if (value != 'Select') {
        setItemofworkerror('')
        setItemofworkValid(true)
      } else {
        setItemofworkerror('Please select')
        setItemofworkValid(false)
      }
    }
    if (fieldName === 'structuretype') {

      if (value != 'Select') {
        setStructuretypekerror('')
        setStructuretypeValid(true)
      } else {
        setStructuretypekerror('Please select')
        setStructuretypeValid(false)
      }
    }
  }

  const toggleModelStatus2 = () => {

    setViewstatus(false)
  };

  function Viewimages() {
    setViewimagestatus(!viewimagestatus)

  }

  function planModel(imgval, idx) {
    setModalstatus(true)
    setCurrentimg(imgval)
    setIsClicked(!isClicked);
    setBtnIndex(idx)

  }

  function onSubmitEvent(event) {

    //alert(level)
    //alert(structuretype)

    event.preventDefault()
    setSuccessstatus(false)
    setReslist1([])
    setReslist2([])
    setCheckduedate(false)
    setPlanimg([])
    setViewimagestatus(false)
    setBtnIndex(null)
    setCurrentimg('')
    const stype = structuretype.replace(/_/g, ' ')

  if(userData.projectType==="Road")
  {
    //used for take type alone from chainage

    const lv = level.replace(/-.*$/, '')
    const firstHyphenIndex = level.indexOf('-');

    // Extract the text after the first hyphen
    const extractedText = firstHyphenIndex !== -1 ? level.slice(firstHyphenIndex + 1).trim() : null;

    const d = date;
    const currentDate = new Date(d)
    const day = String(currentDate.getDate()).padStart(2, '0')
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const year = currentDate.getFullYear()

    //  // Format the current date as 'DD-MM-YYYY'
    const currentDateString1 = `${day}-${month}-${year}`
   
    axios({
      method: 'GET',

      url: baseurl + '/api/plan/list/structure_items?project_id='+userData.projectId+'&level='
      +extractedText+'&structure='+stype,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        console.log(response.data)
        const data = response.data
   
        //alert("AAAAAAAAAAAAAAA"+JSON.stringify(data))
        setReslist1(data)
        setSuccessstatus(true)
      })
      .catch((error) => {
        console.log(error)
      })

    
    let formobj = new FormData()
    console.log(lv)
    console.log(stype)
    formobj.append('level', lv)
    formobj.append('structure_type', stype)

    axios({
      method: 'GET',

      url: baseurl + '/api/plan/view?level=' + lv + '&structure_type=' + stype + '&project_id=' + userData.projectId,

      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData.token
      }

    })
      .then((response) => {
        const data = response.data
        console.log(JSON.stringify(data))
        const imglist = data.image_data  
        setPlanimg(imglist)
        console.log("madhu" + data)
        const namelist = data.filenames
        setPlanimgnamelist(namelist)
      })
      .catch((error) => {
        console.log(error)
       
      })
    }

    //Building

    else{






const d = date;
const currentDate = new Date(d)
const day = String(currentDate.getDate()).padStart(2, '0')
const month = String(currentDate.getMonth() + 1).padStart(2, '0')
const year = currentDate.getFullYear()

//  // Format the current date as 'DD-MM-YYYY'
const currentDateString1 = `${day}-${month}-${year}`

axios({
  method: 'GET',

  url: baseurl + '/api/plan/list/structure_items?project_id='+userData.projectId+'&level='
  +level+'&structure='+stype,

  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + userData.token
  }

})
  .then((response) => {
    console.log(response.data)
    const data = response.data

    //alert("AAAAAAAAAAAAAAA"+JSON.stringify(data))
    setReslist1(data)
    setSuccessstatus(true)
  })
  .catch((error) => {
    console.log(error)
  })


let formobj = new FormData()

formobj.append('level', level)
formobj.append('structure_type', stype)

axios({
  method: 'GET',

  url: baseurl + '/api/plan/view?level=' +level + '&structure_type=' + stype + '&project_id=' + userData.projectId,

  headers: {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + userData.token
  }

})
  .then((response) => {
    const data = response.data
    //alert(JSON.stringify(data))
    const imglist = data.image_data  
    setPlanimg(imglist)
    const namelist = data.filenames
    setPlanimgnamelist(namelist)
  })
  .catch((error) => {
    console.log(error)
   
  })


    }

  }


  return (
    <>

      {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
      {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}
      <div className="">
        <div className=''>
          <div class='row pb-2 '>
            <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  ">
             
                <div className='table1bdstyle '>
                  <div class="row table1st" >
                   
                    <div class="col-xl-1 col-lg-1 col-md-1 pb-3"></div>
                    <div class="col-xl-4 col-lg-4 col-md-4 pb-3 pt-5">
                      {levelList.length != 0 && <table
                        class="table "
                        readOnly="readOnly"

                      >
                        <tr class="trst">
                          <th><div className='trpad'>{userData.projectType === "Road" ? 'Chainage' : ' Level'}</div></th>
                          <th>Naming </th>


                        </tr>

                        {levelList.length != 0 && levelList.map((item, index) => {

                          return (
                            <tr key={index} className='tablefontsize'>

                              <td><div className='trpad'>{item.level_or_chainage}</div></td>
                              <td>{item.name}</td>

                            </tr>
                          )

                        })}
                      </table>}

                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 pb-3"></div>
                    <div class="col-lg-6 col-xl-6 col-xxl-6  pb-3 ">

                      <div id="plan">

                        <img src={planimg1} />
                      </div>
                    </div>
                   

{/*                     
              {boqlist.length != 0 &&   <table
              class="table "
              readOnly="readOnly"
             
            >
              <tr class="trst">
                <th><div className='trpad'>Structure</div></th>
                <th>Type</th>
                <th>Length</th>
                <th>Width</th>
                <th>Height</th>
                <th>Itemofwork</th>
              </tr>
              {viewstatus &&
                <View status={viewstatus} btnClick={toggleModelStatus1} structureid={structureid} />}
           {boqlist.length != 0 && boqlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>

                    <td><div className='trpad'>{item.structure_item}</div></td>
                    <td>{item.structure_type}</td>
                    <td>{item.length}</td>
                    <td>{item.width}</td>
                    <td>{item.height}</td>
                    <td> <button
                      onClick={() => onclickdata(item.structure_item)}
                      type="button"
                      className="user-btn"
                    >View</button> </td>
                  </tr>
                )
              })}
            </table>} */}

 {/* Work reference search */}

         
          <div class="col-12 col-sm-12  col-md-6 col-lg-6 col-md-6 pb-3 pt-5">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
             {userData.projectType==="Road"?'Chainage':'Level'} 
            </label>
            <select
              className="project-box"
              id="level"
              name="level"
              defaultValue={level}
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={level}
              onChange={handlelevel}
            >
              <option value="All" selected>
                Select
              </option>

              {tableData.map((item, index) => {

          let parts =''
          let result =''
          let chainage=''
                if(userData.projectType==="Road")
                {
                 parts = item.floor.split('-');
                 result = parts.slice(1).join('-').trim();
                chainage = result.replace(/\./g, '/');
                }
                else{
                  chainage=item.floor
                  
                }
                return (
                  <option key={index} value={item.floor}>
                    {chainage}
                  </option>)
              })}
            </select>
            <div className="login-error"></div>
          </div>

          <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-6 pb-3 pt-5">
            <label
              for="formFileLg"
              class="form-label"
              className="login-text"
            >
           {userData.projectType==="Road" ? 'Structures/Layer':'Structures'}
            </label>
            <select
              className="project-box"
              id="structuretype"
              name="structuretype"
              defaultValue="All"
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault()
              }}
              value={structuretype}
              onChange={handlestructuretype}
            >
              <option value="ALL" selected>
                Select
              </option>
              {/* <option value="0">Project</option>
                          <option value="1">Payment</option> */}
              {structurelist.map((name, index) => (
                <option key={index} value={name.replace(/ /g, '_')}>
                  {name}
                </option>
              ))}
            </select>
            <div className="login-error"></div>
          </div>
         
          <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-5 ">
            <center>
              <button
                type="submit"
                className="project-btn"
                name="submit"
                onClick={onSubmitEvent}
              // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
              //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
              // disabled={!(itemofworkValid && structuretypeValid && dateValid && ownerValid)}
              >
                <b>Search</b>
              </button>
            </center>{' '}
          </div>

        {successstatus && reslist1.length == 0 &&
        
     
          <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error " >
            <center><b><h6>No Data Available</h6></b></center>
          
        </div>}
     
      

        

          <div class="col-xl-6 col-lg-6">
          <div > 
          {reslist1 != 0 &&     <table
              class="table "
              readOnly="readOnly"

            >

              <tr class="trst">
             
                <th><center>Structure Id</center></th>
                <th><center>Item of Work</center></th>  
                <th>Length (m)</th>
                <th>Width (m)</th>
                <th>Height (m)</th>
                <th>Area (m2)</th>
                <th>Volume (m3)</th>
              
              </tr>
              {viewstatus &&
                <View status={viewstatus} btnClick={toggleModelStatus2} structureid={structureid}
                  structuretype={structuretype1}
                />}

              {reslist1 != 0 && reslist1.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>     
                    <td><center> &nbsp;&nbsp;&nbsp;{item.structure_item}</center> </td>
                
                    <td><center> 
                      <button
                      onClick={() => onclickdata(item.structure_item)}
                      type="button"
                      className="user-btn"
                    >View
                    </button></center> </td>
                  <td>{item.length}</td>
                  <td>{item.width}</td>
                  <td>{item.height}</td>
<td>{item.area_in_m2!=undefined && item.area_in_m2!=null ? item.area_in_m2:'NA'}</td>
                  <td>{item.volume_in_m3!=undefined && item.volume_in_m3!=null ? item.volume_in_m3:'NA'}</td>
                  </tr>
                )
              })}
              
            </table>}
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 ">
          <center>
          {successstatus && reslist1.length != 0 && <button
              type="submit"
              className="project-btn6 mb-5 "
              name="submit"
              onClick={Viewimages}
           
            >
              <b>View Images</b>
            </button>
            }
            </center>

            {
                viewimagestatus && planimg.length == 0 && planimgnamelist.length == 0 &&
                <div class=" login-error pt-1"><center><h6><b>No Data Available</b></h6></center></div>
              }
            <div class="row">
              {
                viewimagestatus && planimg.length != 0 && planimgnamelist.length != 0 &&
                planimg.map((item, index) => {


                  return (
                    <>
                      <div class="col-lg-4 col-xl-4 col-md-4 col-4 "  >
                        <center>
                          <button
                            key={index}
                            //  className=" imageview mb-2"
                            name="submit"
                            onClick={() => planModel(item, index)}
                            className={(btnIndex === index) ? ' imagebtn mb-2 clicked' : ' imagebtn mb-2 normal'}
                          // className={`my-button ${(isClicked && index==index) ? 'clicked' : ''}`}

                          // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
                          //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                          // disabled={!(itemofworkValid && structuretypeValid && dateValid && ownerValid)}
                          >
                            {planimgnamelist[index]}
                          </button>

                          <br></br>
                        </center>
                      </div>
                    </>
                  )

                })

              }
              <div class="col-lg-12 col-md-12 col-sm-12 col-xxl-12">

{viewimagestatus && <img src={currentimg} width="100%" height="auto" />}

</div>
            </div>
            
          </div>
         
         
      

      
       </div>

</div>

            </div>
          </div>
        </div>
      </div>




    </>
  )
}

export default FormPage
