import React from "react";
import { Link } from "react-router-dom";
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from "react";
import CreateCircle from "../Modal/CreateCircle";
import StatusupdateModal from "../Modal/Edit";
import DivisionView from "../Modal/DivisionView";
import ViewTickets from "../Modal/ViewTickets";
import { baseurl } from "../BaseUrl/Baseurl";
import cancelledimg from "../../assets/images/Status/cancelled.png";
import acceptedimg from "../../assets/images/Status/accepted.png";
import pendingimg from "../../assets/images/Status/pending.png";
import axios from "axios";
import close from "../../assets/images/Status/closed.png";
import open from "../../assets/images/Status/open.png";
import renewal from "../../assets/images/Status/renewal.png";
import renewed from "../../assets/images/Status/renewed.png";
import suspended from "../../assets/images/Status/suspended.png";
import { useSelector } from "react-redux";
import "./Commoncss.css";
import circleicon from "../../assets/icons/createcircle.png";
import editicon from "../../assets/icons/editicon.png";
import deleteicon from "../../assets/icons/deleteicon.png";
import circle from "../../assets/icons/Vector-3.png";
import CircleEdit from "../Modal/CircleEdit";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
const WeareHere = () => {
  const [btnname, setBtnname] = useState("");
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [updatestatus, setUpdatestatus] = useState(false);
  const [projectid, setProjectid] = useState("");
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [project_renewalid, setProject_renewalid] = useState("");
  const [ticketno, setTicketno] = useState("");
  const [successstatus, setSuccessstatus] = useState(false);
  const [successstatus1, setSuccessstatus1] = useState(false);
  const [successstatus2, setSuccessstatus2] = useState(false);
  const [editstatus, setEditstatus] = useState(false);
  const [deletestatus, setDeletestatus] = useState(false);
  const [data,setData] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([]);

  const userData = useSelector((state) => state.userDetails);
  
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios({
      method: 'GET',

      url: baseurl+'/api/get/list?field=circle&are_details_rqd=1',

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userData.token,
      }
    })

      .then((response) => {    
        const RequestData = response.data.message
        console.log(RequestData);
        setRenewallist(RequestData)
        setData(RequestData)
      })
      .catch((error) => {
        console.log(error)
      })

  }, []);

  const toggleModelStatus = () => {
    //Refresh the Table

    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const RequestData = response.data;
        setRequestlist(RequestData);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });

    setRequeststatus(false);
  };
  const toggleModelStatus1 = () => {
    setUpdatestatus(false);

    //Refresh the Table

    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },
    })
      .then((response) => {
        const RequestData = response.data;
        setRequestlist(RequestData);
        setSuccessstatus(true);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });
  };
  const toggleRenewalStatus = () => {
    console.log("***********");
    setRenewalstatus(false);
  };
  const toggleViewTicketStatus = () => {
    console.log("***********");
    setViewticketstatus(false);
  };
  const requestdata = () => {
    console.log("*****" + requeststatus);
    setRequeststatus(true);
  };

  const RenewalUpdate = (id,division) => {
    setProject_renewalid(id);
    setSelectedOptions(division)
    setRenewalstatus(true);
  };
  const onEdit = (id) => {
    setEditstatus(true);
  };

  const onDelete = (id) => {
    setDeletestatus(true);
  };
  const onEditClose = () => {
    setEditstatus(false);
  };
  const ViewticketstatusUpdate = (id) => {
    setTicketno(id);
    setViewticketstatus(true);
  };
  return (
    <div class="row mt-5 centered-container-circle ">
      <div class=" col-xl-4 pt-1 pb-1 "></div>
      <div class=" col-xl-4 pt-1 pb-1"></div>
      <div class=" col-xl-3 pt-1 pb-1 mt-2 ">
        <button
          onClick={requestdata}
          type="button"
          className="createcircle-btn"
        >
          <img src={circle} width={20} />
          &nbsp;Create New Circle
        </button>
        {requeststatus && (
          <CreateCircle
            tab={"Circle"}
            status={requeststatus}
            btnClick={toggleModelStatus}
            fieldName1={"Circle Name"}
            fieldName2={"Choose Division"}
          />
        )}
      </div>
      <div class=" col-xl-1 pt-1 pb-1"></div>
      {successstatus1 && renewallist.length == 0 && (
        // !checkduedate &&

        <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3">
          <center>
            <b>
              <h6>No Data Available</h6>
            </b>
          </center>
        </div>
      )}
      {renewallist.length != 0 && (
        <table class="table  tablemargin" readOnly="readOnly">
          <tr class="th-circle">
            <th className="rounded-0">
              <div
              // className='trpad'
              >
                <center>Circle Name</center>
              </div>
            </th>
            <th>
              <center>State</center>
            </th>
            <th>
              <center>&nbsp;&nbsp;&nbsp; &nbsp;Division</center>
            </th>
            <th>
              <center>Created By</center>
            </th>
            <td></td>
          </tr>
          {editstatus && (
            <CircleEdit
              status={editstatus}
              btnClick={onEditClose}
              ProjectId={project_renewalid}
              modalname="Create Circle"
              fieldName="Circle"
            />
          )}

          {renewalstatus && (
            <DivisionView
              status={renewalstatus}
              btnClick={toggleRenewalStatus}
              // ProjectId={project_renewalid}
              modalname="Division List"
              data={selectedOptions}
              tab={'Circle'}
            />
          )}
          {renewallist.length != 0 &&
            renewallist.map((item, index) => {
              return (
                <tr key={index} className="tablefontsize">
                  <td>
                    <div>
                      <center>
                        {item.circle}
                      </center>
                    </div>
                  </td>
                  <td>
                    <center>
                      {" "}
                      {item.state}
                    </center>
                  </td>
                  <td>
                    {" "}
                    <center>
                      {" "}
                      <button
                        type="button"
                        className="view-circle"
                        onClick={() => RenewalUpdate(item.project_id,item.division_list)}
                      >
                        View
                      </button>
                    </center>
                  </td>
                  <td>
                    <center>
                      {item.created_by}
                    </center>
                  </td>
                  <td>
                    {" "}
                    <button
                      onClick={() => onEdit(item.project_id)}
                      className="btn"
                    >
                      <BiEdit style={{ fontSize: "17px", color: "#00A350" }} />
                    </button>
                    <button
                      className="btn"
                      onClick={() => onDelete(item.project_id)}
                    >
                      <RiDeleteBinLine
                        style={{ fontSize: "17px", color: "#FF0000" }}
                      />
                    </button>
                  </td>
                </tr>
              );
            })}
        </table>
      )}
    </div>
  );
};

export default WeareHere;
