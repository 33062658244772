import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import './SearchClient.css'
// import { Navigate } from 'react-router-dom'
import InputTextField from '../InputText/InputText'
import mappic from '../../assets/images/map1.jpg'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import cancelledimg from '../../assets/images/Status/cancelled.png'
import completedimg from '../../assets/images/Status/completed-5.png'
import pendingimg from '../../assets/images/Status/pending.png'
import {useSelector } from 'react-redux'
function FormPage() {
  function Loginspace() {
    // var username = document.getElementById('firstname')
  }
  const [clientname, setClientname] = useState('')
  const [clientnameValid, setClientnameValid] = useState(false)
  const [clientnameerror, setClientnameerror] = useState('')
  const [projecttype, setProjecttype] = useState('')
  const [projecttypeValid, setProjecttypeValid] = useState(false)
  const [projecttypeerror, setProjecttypeerror] = useState('')
  const[successstatus,setSuccessstatus]=useState('')
  const [projectlist,setProjectlist]=useState('')
  const [paymentlist,setPaymentlist]=useState('')

  const userData = useSelector((state) => state.userDetails)


    useEffect(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    }, [])



  function handleclientname(e) {
   console.log(e.target.value)
    setClientname(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleprojecttype(e) {
    setProjecttype(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  
  const validateField = (fieldName, value) => {
    if (fieldName === 'clientname') {
      console.log(value)
      if (value.length != 0) {
        setClientnameerror('')
        setClientnameValid(true)
      } else {
        setClientnameerror('Please enter Client name')
        setClientnameValid(false)
      }
    }
    if (fieldName === 'projecttype') {
      if (value.length != 'Select') {
        setProjecttypeerror('')
        setProjecttypeValid(true)
      } else {
        setProjecttypeerror('Please select Project type')
        setProjecttypeValid(false)
      }
    }
   
  }
  
  function onSubmitEvent(event) {
    event.preventDefault()
    
    if (projecttypeValid && clientnameValid) {
  
   
        // let userData = new FormData()
        // userData.append('project_name',projectname )
        // userData.append('client_contact_person', contactperson)
        // userData.append('company_name', companyname)
        // userData.append('contact_number',contactno )
        // userData.append('contact_email',contactmail)
        // userData.append('est_start_date',startdate )
        // userData.append('est_end_date', enddate)
        // userData.append('budget', budget)
     

          axios({
            method: 'GET',
            url: baseurl + '/api/report/client/status/type?report_type='+projecttype,
      
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer ' + userData.token,
            },
          })

          .then((response) => {
            console.log(response.data)
            const data=response.data
            setSuccessstatus(true)
            if(projecttype==="0")
            {
            setProjectlist(data)
            setPaymentlist([])
            }
            else{
              setPaymentlist(data)
              setProjectlist([])
            }
          })
          .catch((error) => {
          console.log(error)
            //setErrormsg(error.response.data.message)
          })
        
    }
  }
  const list1 = [
    { key1: "add", key2: "fdg", key3: "sds" },
    { key1: "add", key2: "fdg", key3: "sds" }
  ];
  const list2 = [
    { key1: "add", key2: "fdg", key3: "sds" },
    { key1: "add", key2: "fdg", key3: "sds" }
  ];
  
  return (
    <>
      
        {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
        {/* {successstatus && <ButtonLink btnPath="/PlanUpdate" />} */}
        <div className="cont-margin1 tmar pt-3">
    <div className='table1bdstyle'>
            <div class='row pb-2 table1st '>            
                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext "
                    
                  >
                      <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-12  pb-3 ">
                      <label for="formFileLg" className="login-text">
                    Client Name
                  </label>
                      <center>
                        <InputTextField
                        
                          valueText={clientname}
                          inputName="clientname"
                          inputId="clientname"
                          classname="project-box"
                          inputType="text"
                          placeholderValue="Client Name"
                          handleInputChange={handleclientname}
                          PreventEnter={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                       
                         
                        ></InputTextField>

                        <div className="login-error">{clientnameerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12  col-md-6 col-lg-6 ol-md-4 pb-3">
                        <label
                          for="formFileLg"
                          class="form-label"
                          className="login-text"
                        >
                          Type of Report
                        </label>
                        <select
                          className="project-box"
                          id="projecttype"
                          name="projecttype"
                           defaultValue={projecttype}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                           value={projecttype}
                          onChange={handleprojecttype}
                        >
                          <option value="Select" selected>
                            Select
                          </option>
                          <option value="0">Project</option>
                          <option value="1">Payment</option>
                        
                        </select>
                        <div className="login-error">{projecttypeerror}</div>
                      </div>
                    
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 ">
                      <center>
                        <button
                          type="submit"
                          className="project-btn"
                          name="submit"
                          onClick={onSubmitEvent}
                          // disabled={!(projectnameValid && projecttypeValid && startdateValid && enddateValid && budgetValid 
                          //   && companynameValid && contactpersonValid && contactmailValid && contactnoValid)}
                        >
                          <b>Search</b>
                        </button>
                      </center>{' '}
                    </div>
                  
                  </form>
                  <div>
                  {/* {projecttype==="Select" && projectlist.length==0 && paymentlist.length==0 &&
                  <div className='table1bdstyle '>
                  <div class="row table1st">
                  <div className='fullheight'>
                 </div></div>
</div>} */}
                    { successstatus && projectlist.length!=0 &&
                  <div className='table1bdstyle '>
          <div class="row table1st">
         
              <div class="pt-1 pb-1 padtext">
              <h5><b>Project Details</b></h5>
              </div>
            <table
              class="table"
              readOnly="readOnly"

            >

              <tr class="trst">
                <th>&nbsp;&nbsp;&nbsp;&nbsp;Project Name</th>
                <th><center>No of Support to Client</center></th>
                <th><center>Start date</center></th>
                <th><center>End date</center></th>
                <th><center>Payment Status</center></th>
              </tr>
             
           {projectlist.length != 0 && projectlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>

                    <td>&nbsp;&nbsp;&nbsp;&nbsp;{item.project_name}</td>
                    <td><center>{item.no_of_supports_to_client}</center></td>
                    <td><center>{item.start_date}</center></td>
                    <td><center>{item.end_date}</center></td>
                    <td> {item.payment_status=== "Pending" ? 
            <img src={pendingimg} className='leftimg' />: (item.request_status === "Completed" ? <img src={completedimg} className='leftimg' />:
                       <img src={cancelledimg} className='leftimg' />)} </td>

                  </tr>
                )
              })}
            </table>
            
          </div>
      
        </div>}
        {/*  */}


        { successstatus && paymentlist.length!=0 &&
                  <div className='table1bdstyle '>
          <div class="row table1st">
         
              <div class="pt-1 pb-1 ">
                {/* <h5><b>Payment Details</b></h5> */}
              </div>
            <table
              class="table "
              readOnly="readOnly"

            >

              <tr class="trst">
                <th><div className='trpad'>Project Name</div></th>
                <th>Bill Number</th>
                <th>Payment date</th>
                <th>Mode</th>
                <th><center>Payment Level</center></th>
                <th>Payment By</th>
                <th><center>Amount</center></th>
              </tr>
             
           {paymentlist.length != 0 && paymentlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>

                    <td><div className='trpad'>{item.project_name}</div></td>
                    <td>{item.bill_number}</td>
                    <td>{item.payment_date}</td>
                    <td>{item.mode_of_payment}</td>
                    <td><center>{item. payment_level}</center></td>
                    <td>{item.payment_by}</td>
                    <td><div className='padright'><center>{item.payment_amount}</center></div></td>
                    {/* <td> {item.request_status === "Pending" ? 
            <img src={pendingimg} className='leftimg1' />: (item.request_status === "Completed" ? <img src={completedimg} className='leftimg1' />:
                       <img src={cancelledimg} className='leftimg1' />)} </td> */}

                  </tr>
                )
              })}
            </table>
            
          </div>
      
        </div>}
                  </div>
                </div>
              
              
            </div>
            </div>
              
              </div>
          
  

    
    </>
  )
}

export default FormPage
