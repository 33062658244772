import React, { useState ,useEffect, useRef } from 'react';
import InputTextField from '../InputText/InputText';
import '../CreateProject/CreateProject.css'
import './PlanUpdate.css'
import { FaPlus, FaEdit } from "react-icons/fa";
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import { baseurl } from '../BaseUrl/Baseurl'
import { pythonurl } from '../BaseUrl/Baseurl'
import { useNavigate, useLocation } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
function FormPage() {
  const userData = useSelector((state) => state.userDetails)
  const [successstatus, setSuccessstatus] = useState(false)
  const [loading, setLoading] = useState(false)
  // const location = useLocation();
  // const projectid = location.state?.projectid
      useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
     
      }, [])
  
    //   const handleClick = (event) => {
    //     hiddenFileInput1.current.click()
    //   }



      const fileInputRefs = useRef([]);
  const [fields, setFields] = useState([
    {
      // projectname: '',
      housingname: '',
      noofFloors: '',
      fileattach: '',
    },
  ]);

  const [fieldValidations, setFieldValidations] = useState([
    {
      // projectnameValid: false,
      // projectnameError: '',
      housingnameValid: false,
      housingnameError: '',
      noofFloorsValid: false,
      noofFloorsError: '',
      fileattachValid: false,
      fileattachError: '',
    },
  ]);
  const handleAddFields = () => {
    setFields([...fields, {  housingname: '', noofFloors: '', fileattach: '' }]);
    setFieldValidations([...fieldValidations, {
    
      housingnameValid: false,
      housingnameError: '',
      noofFloorsValid: false,
      noofFloorsError: '',
      fileattachValid: false,
      fileattachError: '',
    }]);
  };
  const handleFileInputChange = (e, index) => {
    const file = e.target.files[0];
    handleChangeField(index, 'fileattach', file);
  };
  const handleChangeField = (index, fieldName, value) => {
    const newFields = [...fields];
    newFields[index][fieldName] = value;
    setFields(newFields);

    const newValidations = [...fieldValidations];
    const validation = newValidations[index];



    if (fieldName === 'housingname') {
      if (value.trim() === '') {
        validation.housingnameValid = false;
        validation.housingnameError = 'Please enter a contact person.';
      } else {
        validation.housingnameValid = true;
        validation.housingnameError = '';
      }
    }

    if (fieldName === 'noofFloors') {
      if (value.trim() === '') {
        validation.noofFloorsValid = false;
        validation.noofFloorsError = 'Please enter a client name.';
      } else {
        validation.noofFloorsValid = true;
        validation.noofFloorsError = '';
      }
    }

    if (fieldName === 'fileattach') {
      if (!value) {
        validation.fileattachValid = false;
        validation.fileattachError = 'Please upload a file.';
      } else {
        validation.fileattachValid = true;
        validation.fileattachError = '';
      }
    }

    newValidations[index] = validation;
    setFieldValidations(newValidations);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    // Handle your form submission here
    // const allFieldsValid = fields.every((field, index) => {
    //     const validation = fieldValidations[index];
        
    //     return (
    //       field.housingname.trim() !== '' &&
    //       field.noofFloors.trim() !== '' &&
    //       field.fileattach !== '' &&
    //       validation.housingnameValid &&
    //       validation.noofFloorsValid &&
    //       validation.fileattachValid
    //     );
    
    //   });
  
      // if (allFieldsValid) {
        // All fields are valid, you can proceed with form submission
        console.log('Form submitted with valid data:', fields);
        fields.map((field, index) => {      
          console.log(userData.projectId)
          console.log("plan Started")
  let formdata = new FormData()
  formdata.append('project_id',  userData.projectId)
  formdata.append('plan_type',  field.housingname)
  formdata.append('plan_name',  field.housingname)
  formdata.append('project_id',userData.projectId)
  formdata.append('no_of_floors',  field.noofFloors)
  formdata.append('files', field.fileattach )

    axios({
      method: 'POST',
      url: pythonurl + '/api/plan/create',
      headers: {
        'Content-Type': 'multipart/form-data',  
       // Authorization: 'Bearer ' + userData.token,     
      },
      data: formdata,
    })     
    .then((response) => {

     setSuccessstatus(true)
    })
    .catch((error) => {
      setLoading(false)
       setSuccessstatus(true)
       console.log(error)
     // setErrormsg(error.response.data.message)
    })
  })
  //not required

      // } else {
      //   // Display an error message or prevent submission
      //   console.log('Form has validation errors.');
      // }
    
  };

  return (
    <>   {successstatus &&<ButtonLink btnPath="/Boq" />}
    <div className="cont-margin1 tmar pt-3">
    <div className='table1bdstyle'>
            <div className='table1st'>
      <div className='planstyle'>

        <div class="row pb-5">
       
        
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12  ">
        {loading && !successstatus &&
        <div class="pt-5">
<center><h3>Please wait...</h3></center>
{/* <br></br> */}
<center><h3>Processing the plan</h3></center>
          </div>
}
          {!loading && !successstatus &&
    <form onSubmit={handleSubmit}>
        <div class="row ">
        <div class="col-lg-8 col-xl-8 "></div>
        <div class="col-lg-4 col-xl-4 ">
      <button onClick={handleAddFields}
                    type="button"
                    className="create-btn"
                  ><FaPlus /> &nbsp;Add</button>
                  </div>
        </div>
        {fields.map((field, index) => (
          <div key={index}>
            {/* <div className="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
              <label htmlFor={`projectname-${index}`} className="login-text">
                Number of Housing type
              </label>
              <center>
                <InputTextField
                  handleInputChange={(e) => handleChangeField(index, 'projectname', e.target.value)}
                  valueText={field.projectname}
                  inputName={`projectname-${index}`}
                  inputId={`projectname-${index}`}
                  classname="project-box"
                  inputType="text"
                  placeholderValue="Project name"
                />
                <div className="login-error">{fieldValidations[index].projectnameError}</div>
              </center>
            </div> */}

            <div className="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
              <label htmlFor={`housingname-${index}`} className="login-text">
                Housing Name 
              </label>
              <center>
                <InputTextField
                  handleInputChange={(e) => handleChangeField(index, 'housingname', e.target.value)}
                  valueText={field.housingname}
                  inputName={`housingname-${index}`}
                  inputId={`housingname-${index}`}
                  classname="project-box"
                  maxLen={20}
                  inputType="text"
                  placeholderValue="Housing name"
                />
                <div className="login-error">{fieldValidations[index].housingnameError}</div>
              </center>
            </div>

            <div className="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
              <label htmlFor={`noofFloors-${index}`} className="login-text">
                No of Floors
              </label>
              <center>
                <InputTextField
                  handleInputChange={(e) => handleChangeField(index, 'noofFloors', e.target.value)}
                  valueText={field.noofFloors}
                  inputName={`noofFloors-${index}`}
                  inputId={`noofFloors-${index}`}
                  classname="project-box"
                  maxLen={20}
                  inputType="text"
                  placeholderValue="No of floors"
                />
                <div className="login-error">{fieldValidations[index].noofFloorsError}</div>
              </center>
            </div>

            <div className="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-2">
              <label htmlFor={`fileattach-${index}`} className="login-text">
                Plan
              </label>
            </div>
            <div className="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pb-3">
            
              <button
                type="button"
                className="project-box"
                onClick={() => fileInputRefs.current[index].click()}
              >
                {field.fileattach !== undefined && field.fileattach !== ''
                  ? 'Uploaded '
                  : 'Upload'}
              </button>
              
              <input
                ref={(input) => (fileInputRefs.current[index] = input)}
                onChange={(e) => handleFileInputChange(e, index)}
                name={`fileattach-${index}`}
                id={`fileattach-${index}`}
                accept="image/jpeg"
                style={{ display: 'none' }}
                type="file"
              />
              
              <div className="login-error">{fieldValidations[index].fileattachError}</div>
            </div>
          {fields.length>1 &&  <hr></hr>}
          </div>
         
        ))}

        <div className="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-1">
          <center>
            <button
              type="submit"
              className="project-btn"
              name="submit"
            //   disabled={!projectnameValid}
            >
              <b>Submit</b>
            </button>
          </center>
        </div>
      </form>}

    
      </div>
              
              </div>
            </div>
            </div>
              
              </div>
            </div>
            </> 
  );
}

export default FormPage;
