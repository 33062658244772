import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, Form } from "react-bootstrap";
import "./Modal.css";
import CreateRequest from "../CreateRequest/CreateRequest";
import { useState } from "react";
import InputTextField from "../InputText/InputText";
import { baseurl } from "../BaseUrl/Baseurl";
import axios from "axios";
import { useSelector } from "react-redux";
import circle from "../../assets/icons/Vector-3.png";
import { IoCloseSharp } from "react-icons/io5";
export default function Model({ btnClick, status,fieldName ,modalname}) {
  const [projectname, setProjectname] = useState("");
  const [projectnameValid, setProjectnameValid] = useState(false);
  const [projectnameerror, setProjectnameerror] = useState("");
  const [contactno, setContactno] = useState("");
  const [contactnoValid, setContactnoValid] = useState(false);
  const [contactnoerror, setContactnoerror] = useState("");
  const [clientname, setClientname] = useState("");
  const [clientnameValid, setClientnameValid] = useState(false);
  const [clientnameerror, setClientnameerror] = useState("");
  const [circle, setcircle] = useState("");
  const [circleValid, setcircleValid] = useState(false);
  const [circleerror, setcircleerror] = useState("");
  const [modalVisible, setModalVisible] = useState(true);
  const [division, setdivision] = useState("");
  const [divisionValid, setdivisionValid] = useState(false);
  const [divisionerror, setdivisionerror] = useState("");
  const [contactmail, setContactmail] = useState("");
  const [contactmailValid, setContactmailValid] = useState(false);
  const [contactmailerror, setContactmailerror] = useState("");
  const [divisionlist, setdivisionlist] = useState([]);

  const userData = useSelector((state) => state.userDetails);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    axios({
      method: "GET",

      url: baseurl + "/api/request/trial/list/company/status/accepted",

      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userData.token,
      },

      // data: userData,
    })
      .then((response) => {
        console.log(response.data);
        const RequestData = response.data;
        const Datalist = RequestData[0];
        const data = Datalist.company_names;
        setdivisionlist(data);
      })
      .catch((error) => {
        console.log(error);
        // setErrormsg(error.response.data.message)
      });
  }, []);
  function handleContactmail(e) {
    setContactmail(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  function handleProjectname(e) {
    console.log(e.target.value);
    setProjectname(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  function handledivision(e) {
    setdivision(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  function handleContactno(e) {
    setContactno(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }

  // function handleClientname(e) {
  //   setClientname(e.target.value.replace(/\s+/g, ''))
  //   validateField(e.target.name, e.target.value)
  // }
  function handlecircle(e) {
    setcircle(e.target.value.replace(/\s+/g, ""));
    validateField(e.target.name, e.target.value);
  }
  const validateField = (fieldName, value) => {
    if (fieldName === "projectname") {
      if (value.length != 0) {
        setProjectnameerror("");
        setProjectnameValid(true);
      } else {
        setProjectnameerror("Please enter your Project name");
        setProjectnameValid(false);
      }
    }

    if (fieldName === "contactno") {
      if (value.length != 0 && value.length == 10) {
        setContactnoerror("");
        setContactnoValid(true);
      } else {
        setContactnoerror("Please enter valid Contact number");
        setContactnoValid(false);
      }
    }
    // if (fieldName === 'clientname') {

    //   if (value != "") {
    //     setClientnameerror('')
    //     setClientnameValid(true)
    //   } else {
    //     setClientnameerror('Please select Client name')
    //     setClientnameValid(false)
    //   }
    // }
    if (fieldName === "division") {
      console.log(value);

      if (value != "") {
        setdivisionerror("");
        setdivisionValid(true);
      } else {
        setdivisionerror("Please enter Company name");
        setdivisionValid(false);
      }
    }
    if (fieldName === "circle") {
      if (value != "") {
        setcircleerror("");
        setcircleValid(true);
      } else {
        setcircleerror("Please select Contact person");
        setcircleValid(false);
      }
    }
    if (fieldName === "contactmail") {
      const regex =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (value.length != 0) {
        if (value.match(regex)) {
          setContactmailValid(true);
          setContactmailerror("");
        } else {
          setContactmailValid(false);
          setContactmailerror("Please enter valid email");
        }
      } else {
        setContactmailValid(false);
        setContactmailerror("Please enter valid email");
      }
    }
  };

  function onSubmitEvent(event) {
    event.preventDefault();

    // console.log(projectname)
    // console.log(contactno)

    if (

      circleValid &&
      divisionValid 
    
    ) {
    
      console.log(circle);
      console.log(division);
     

    //   let userData = new FormData();
    //   userData.append("designation", projectname);
    //   userData.append("contact_person", circle);
    //   userData.append("company_name", division);
    //   userData.append("contact_number", contactno);
    //   userData.append("contact_email", contactmail);
    //   userData.append("status_comment", division);
    //   axios({
    //     method: "POST",

    //     url: baseurl + "/api/request/trial/new",

    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       Authorization: "Bearer " + userData.token,
    //     },

    //     data: userData,
    //   })
    //     .then((response) => {
    //       //  alert("success request created")
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       // setErrormsg(error.response.data.message)
    //     });
    //   //  setModalVisible(!modalVisible)
    }
  }

  return (
    <>
      <Modal show={status} onHide={btnClick} id="modal-size1" >
      <div className="d-flex justify-content-end">
            <button className="btn " onClick={btnClick}>
              <IoCloseSharp />
            </button>
          </div>
        <Modal.Body>
         
          {/* <CreateRequest /> */}

         
                {/* <div class="projectbtnstyle d-inline-flex justify-content-around align-items-center  ">
                  <p className="mb-0  text-white" style={{ fontWeight: "500" }}>
                    <img className=" mr-2" src={circle} width={30} alt="" />
                    &nbsp;Projects
                  </p>
                </div> */}
         
                <form
                  class="row d-flex justify-content-center align-center  circlediv"
                  onSubmit={(event) => {
                    onSubmitEvent(event);
                    btnClick(); // Close the modal
                  }}
                >
                     <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3 pt-3">
                     <center><b>{modalname}</b></center>
                     </div>
               
                     <div class="col-12 col-sm-12 col-lg-1 col-xl-1 col-md-12  pb-3 pt-3"></div>
                    <div class="col-12 col-sm-12 col-lg-10 col-xl-10 col-md-12  pb-3 pt-3">
                      <label for="formFileLg" className="login-text">
                        {fieldName}
                      </label>
                      <center>
                        <InputTextField
                          handleInputChange={handlecircle}
                          valueText={circle}
                          inputName="circle"
                          inputId="circle"
                          classname="project-box"
                          maxLen={20}
                          inputType="text"
                          //  placeholderValue="Contact"
                          defaultValue={circle}
                          onKeyPress={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                        ></InputTextField>
                      </center>
                      <div className="login-error">{circleerror}</div>
                    </div>      
                    <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-12  pb-3 pt-3"></div>      
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pb-3">
                    <center>
                      <button
                        type="submit"
                        className="search-circle"
                        name="submit"
                        disabled={!projectnameValid}
                      >
                        <b>Create</b>
                      </button>
                    </center>{" "}
                  </div>
                </form>
            
        </Modal.Body>
      </Modal>
    </>
  );
}
