import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import userDataStore from './store/userDetails';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import "@fontsource/plus-jakarta-sans"; // Defaults to weight 400
// import "@fontsource/plus-jakarta-sans/400.css"; // Specify weight
// import "@fontsource/plus-jakarta-sans/400-italic.css"; // Specify weight and style
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
const store = createStore(userDataStore);
// 
ReactDOM.render(
<Provider store={store}>
    <App />
</Provider>, document.getElementById('root'));
// 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
