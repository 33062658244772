import React from 'react'

const InputTextField = ({
  handleInputChange,
  onKeydown,
  valueText,
  inputName,
  inputId,
  inputType,
  classname,
  minLen,
  maxLen,
  disabled,
  placeholderValue,
  mindate,
  maxdate
}) => {
  return (
    <input
      type={inputType}
      name={inputName}
      id={inputId}
      onKeyDown={onKeydown}
      className={classname}
      value={valueText}
      onChange={handleInputChange}
      onContextMenu={(e) => e.preventDefault()}
      minLength={minLen}
      maxLength={maxLen}
      disabled={disabled}
      placeholder={placeholderValue}
      min={mindate}
      max={maxdate}
      onKeyPress={(e) => {
        e.key === 'Enter' && e.preventDefault()
      }}
    />
  )
}

export default InputTextField
