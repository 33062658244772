import React, { useEffect, useState } from "react";
import "./Adminconsole.css";
import wing from "../../assets/icons/RoadHorizon-1.png";
import wingactive from "../../assets/icons/RoadHorizon.png";
import boqactive from "../../assets/icons/Scroll.png";
import boq from "../../assets/icons/Scroll-1.png";
import roleactive from "../../assets/icons/UserCheck.png";
import role from "../../assets/icons/UserCheck-1.png";
import useractive from "../../assets/icons/Vector.png";
import user from "../../assets/icons/Vector-1.png";
import circleactive from "../../assets/icons/Vector-2.png"
import circle from "../../assets/icons/Vector-3.png";
import subdivactive from "../../assets/icons/Vector-4.png"
import subdiv from "../../assets/icons/Vector-5.png";
import divactive from "../../assets/icons/Vector-6.png"
import divicon from "../../assets/icons/Vector-7.png";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Wingpage from './Wing'
import Circlepage from './Circle'
import Division from './DivisionList'
import Subdivision from './SubDivisionList'
import User from './Userlist'
import Boq from "./Boq";
import Boq1 from "./Boq";

function WorkDetails() {
  const [wingstatus, setWingstatus] = useState(false);
  const [circlest, setCirclest] = useState(false);
  const [boqst, setBoqst] = useState(false);
  const [divist, setDivist] = useState(false);
  const [subdivst, setSubdivst] = useState(true);
  const [rolest, setRolest] = useState(false);
  const [userst, setUserst] = useState(false);



  const userData = useSelector((state) => state.userDetails);

  const handleWing = () => {
    setWingstatus(true);
  setCirclest(false)
  setBoqst(false)
  setSubdivst(false)
  setDivist(false)
  setUserst(false)
  setRolest(false)
  };

  const handleCircle= () => {
    setWingstatus(false);
    setCirclest(true)
    setBoqst(false)
    setSubdivst(false)
    setDivist(false)
    setUserst(false)
    setRolest(false)
  };

  const handleDiv = () => {
    setWingstatus(false);
  setCirclest(false)
  setBoqst(false)
  setSubdivst(false)
  setDivist(true)
  setUserst(false)
  setRolest(false)
  };

  const handleSubdiv = () => {
    setWingstatus(false);
    setCirclest(false)
    setBoqst(false)
    setSubdivst(true)
    setDivist(false)
    setUserst(false)
    setRolest(false)
  };
  
  const handleUser = () => {
    setWingstatus(false);
    setCirclest(false)
    setBoqst(false)
    setSubdivst(false)
    setDivist(false)
    setUserst(true)
    setRolest(false)
  };
  
  const handleRole = () => {
    setWingstatus(false);
    setCirclest(false)
    setBoqst(false)
    setSubdivst(false)
    setDivist(false)
    setUserst(false)
    setRolest(true)
  };
  
  const handleBoq = () => {
    setWingstatus(false);
    setCirclest(false)
    setBoqst(true)
    setSubdivst(false)
    setDivist(false)
    setUserst(false)
    setRolest(false)
  };

  return (
    <>
    <div class="cont-margin tmar ">
        <div className='table1bdstyle'>
          <div class="row table1st  ">
      <div
        className="container-fluid wd-cont w-75 ml-4  mt-4 bg-yellow  pb-1"
        style={{
          position: "absolute",
          top: "130px",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div className=" w-100  pt-2 d-flex justify-content-center
         align-items-center mb-4 pb-1 "s>
          <div className=" mx-auto  text-center rounded w-25  " style={{backgroundColor:'', color:'#1953AA'}}>
            <h5 className="   mb-0 ">{userData.projectName}</h5>
            <h6
      className="text-center mt-2"
      style={{  fontWeight: "700" }} 
    >
      {/* TOTAL CHAINAGE: 1/0 - 6/0 */}
    </h6>
          </div>
        
        </div>
        <div
          className="d-flex justify-content-center evenly mx-auto  p-0 rounded "
          style={{
            backgroundColor: "#1C434D",
            height: "50px",
            position: "relative",
            width: '700px',
          }}
        >

<div className="  text-center">
            <div onClick={handleSubdiv} style={{ cursor: "pointer" }}>
              <div className={`${subdivst ? "tabMenu pt-2" : ""}`}>
                <img src={subdivst? subdivactive:subdiv} width={20} alt="icon" />
              </div>

              <label
                style={{ fontSize: "12px",color: subdivst?'#ffb700':'white' }}
                className={`${
                  subdivst
                    ? "form-label mb-0 mt-4 pt-1"
                    : "pt-0"
                }`}
              >
                Sub-Division List
              </label>
            </div>
          </div>

          <div className=" ml-5 text-center">
            <div onClick={handleDiv} style={{ cursor: "pointer" }}>
              <div className={`${divist ? "tabMenu1 pt-2" : ""}`}>
              <img src={divist? divactive : divicon} width={20} alt="icon" />
              </div>

              <label
                style={{ fontSize: "12px",color: divist?'#ffb700':'white' }}
                className={`${
                  divist
                    ? "form-label mb-0 mt-4 pt-1"
                    : " pt-0"
                }`}
              >
                Division List
              </label>
            </div>
          </div>

          <div className="ml-5 text-center">
            <div
              className="ml-4"
              onClick={handleCircle}
              style={{ cursor: "pointer" }}
            >
              <div className={`${circlest ? "tabMenu2 pt-2" : ""}`}>
              <img src={circlest? circleactive : circle} width={20} alt="icon" />
              </div>

              <label
                style={{ fontSize: "12px",color: circlest?'#ffb700':'white' }}
                className={`${
                  circlest
                    ? "form-label mb-0  mt-4 pt-1"
                    : " pt-0"
                }`}
              >
                {" "}
                Circle List
              </label>
            </div>
          </div>

          <div className="ml-5  text-center ">
            <div
              className="mr-4"
              onClick={handleWing}
              style={{ cursor: "pointer" }}
            >
              <div className={`${wingstatus ? "tabMenu3 mx-auto pt-2" : " "}`}>
                <img src={wingstatus? wingactive : wing} width={20} alt="icon" />
              </div>
              <label
                style={{ fontSize: "12px",color: wingstatus?'#ffb700':'white' }}
                className={`${
                  wingstatus
                    ? "form-label mb-0 mt-4 pt-1 "
                    : " pt-0"
                }`}
              >
                Wing
              </label>
            </div>
          </div>

         

         
         

          {/* <div className=" ml-5 text-center">
            <div onClick={handleRole} style={{ cursor: "pointer" }}>
              <div className={`${rolest ? "tabMenu4 pt-2" : ""}`}>
                <img src={rolest? roleactive:role} width={20} alt="icon" />
              </div>

              <label
                style={{ fontSize: "12px",color: rolest?'#ffb700':'white' }}
                className={`${
                rolest
                    ? "form-label mb-0 mt-4 pt-1"
                    : "pt-0"
                }`}
              >
                Role List
              </label>
            </div>
          </div> */}



          <div className=" ml-5 text-center">
            <div onClick={handleUser} style={{ cursor: "pointer" }}>
              <div className={`${userst ? "tabMenu4 pt-2" : ""}`}>
                <img src={userst? useractive:user} width={20} alt="icon" />
              </div>

              <label
                style={{ fontSize: "12px",color: userst?'#ffb700':'white' }}
                className={`${
                  userst
                    ? "form-label mb-0 mt-4 pt-1"
                    : "pt-0"
                }`}
              >
                User List
              </label>
            </div>
          </div>


          <div className=" ml-5 text-center">
            <div onClick={handleBoq} style={{ cursor: "pointer" }}>
              <div className={`${boqst ? "tabMenu5 pt-2" : ""}`}>
                <img src={boqst? boqactive:boq} width={20} alt="icon" />
              </div>

              <label
                style={{ fontSize: "12px",color: boqst?'#ffb700':'white' }}
                className={`${
                  boqst
                    ? "form-label mb-0 mt-4 pt-1"
                    : "pt-0"
                }`}
              >
                BOQ
              </label>
            </div>
          </div>

        </div>
       
      </div>
      

      </div>
      {wingstatus && <Wingpage />}
      {circlest && <Circlepage />}
      {divist && <Division />}
      {subdivst && <Subdivision />}
      {userst && <User />}
      {boqst && <Boq/>}
      
      </div>
      </div>
    
    </>
  );
}

export default WorkDetails;
