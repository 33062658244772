import React from 'react'
import './Commoncss.css'
import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
// import InputTextField from "../InputText/InputText";
// import inprogressimg from "../../assets/images/Status/inprogress.png";
// import close from "../../assets/images/Status/closed.png";
// import open from "../../assets/images/Status/open.png";
// import { useSelector } from "react-redux";
import { FaPlus, FaUpload } from "react-icons/fa6";
import { BiEditAlt } from "react-icons/bi";
import { Button, Form, Modal } from "react-bootstrap";
import { FaXmark } from "react-icons/fa6";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";

export default function Boq() {

    const itemsPerPage = 10;
  const [boq,setBoq]=useState([])
//  const userData = useSelector((state) => state.userDetails);
  const [add,setAdd]=useState('')
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  
  const [filteredData, setFilteredData] = useState([]);
 

const  clickModal =()=> setAdd(true)
const handleClose = () => setAdd(false);

const startIndex = itemOffset + 1;
  const endIndex = Math.min(itemOffset + itemsPerPage, filteredData.length);

  useEffect(() => {
    setFilteredData(Boq)
   

  }, []);



  const Boq=[
    {
      itemofwork:'Earthwork',
      id:'2',
      Rate:'100',
      CalculatedQty :'25',
      CalculatedAmount:'2500',
      AgreementQty:'25',
      AgtAmount:'2',
      UtilizedQty:'100',
      UtilizedAmount:'25',
      BilledQty:'2500',
      BilledAmount:'25',     
    },
    {
      itemofwork:'Soilfilling',
      id:'2',
      Rate:'100',
      CalculatedQty :'25',
      CalculatedAmount:'2500',
      AgreementQty:'25',
      AgtAmount:'2',
      UtilizedQty:'100',
      UtilizedAmount:'25',
      BilledQty:'2500',
      BilledAmount:'25',     
    },
    {
      itemofwork:'WMM',
      id:'2',
      Rate:'100',
      CalculatedQty :'25',
      CalculatedAmount:'2500',
      AgreementQty:'25',
      AgtAmount:'2',
      UtilizedQty:'100',
      UtilizedAmount:'25',
      BilledQty:'2500',
      BilledAmount:'25',     
    },
  ]
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredData.length; // Use filteredData instead of Completedworklist
    setItemOffset(newOffset);
  };
  
 
  useEffect(() => {
  
    const endOffset = itemOffset + itemsPerPage;
    const newItems = filteredData.slice(itemOffset, endOffset);
  
    // if (newItems !== boq) {
    //   setBoq(newItems);
    // }
    
    const newPageCount = Math.ceil(filteredData.length / itemsPerPage);
    if (newPageCount !== pageCount) {
      setPageCount(newPageCount);
    }
  }, [itemOffset, itemsPerPage, filteredData, boq, pageCount]);
  

  const handleSearch = (event) => {
    
    const searchValue = event.target.value.toLowerCase();

    setSearchTerm(searchValue);
  
    // Filter data using the current search value
    const searchItem = Boq.filter(item =>
      item.itemofwork.toLowerCase().includes(searchValue)
    );
    setFilteredData(searchItem);
  };

  return (
    <div>
        <div className="container pt-3">
        <div
          className="d-flex justify-content-between sha2  p-3"
          style={{ borderRadius: "2px" }}
        >
      
<div class="group ">
  <svg class="icon2" aria-hidden="true" viewBox="0 0 24 24"><g><path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path></g></svg>
  <input 
  value={searchTerm} 
  onChange={handleSearch} 
  placeholder="Search" type="search" class="input2 "/>
</div>

          <div className="d-flex ">
            <button onClick={clickModal} 
              className="btn d-flex align-items-center sha text-white mr-3"
              style={{ background: "#069E55", fontWeight: "500" }}
            >
              <FaPlus />
              &nbsp;&nbsp;<span>Add</span>{" "}
            </button>
            <button
              className="btn d-flex align-items-center sha1 text-white"
              style={{ background: "#6777EF", fontWeight: "500" }}
            >
              <FaUpload />
              &nbsp;&nbsp;<span>Upload</span>
            </button>
          </div>
        </div>


       

 
              {filteredData.map((item,index)=>{
                return(
                  <>
                  <div  className="border sha2 mt-3 pl-3 pr-3 pt-2 " style={{ borderRadius: "5px", }}>
                   <div className="d-flex align-items-center  border-bottom justify-content-between pb-2 "
        >
          <h6 className="mb-0 mt-2" >
            Item of Work{" "}
            <span className="ml-2" style={{ color: "#00682A" }}>
              {item.itemofwork}
             
            </span>
          </h6>

         

          <div className="d-flex ">
            <button className="btn btn2  ">
              <BiEditAlt style={{ fontSize: "20px", color: "#069E55" }} />
            </button>

            <button class="btn btn1 ">
              <svg
                viewBox="0 0 15 17.5"
                height="17.5"
                width="15"
                xmlns="http://www.w3.org/2000/svg"
                class="icon"
              >
                <path
                  transform="translate(-2.5 -1.25)"
                  d="M15,18.75H5A1.251,1.251,0,0,1,3.75,17.5V5H2.5V3.75h15V5H16.25V17.5A1.251,1.251,0,0,1,15,18.75ZM5,5V17.5H15V5Zm7.5,10H11.25V7.5H12.5V15ZM8.75,15H7.5V7.5H8.75V15ZM12.5,2.5h-5V1.25h5V2.5Z"
                  id="Fill"
                ></path>
              </svg>
            </button>
          </div>
          </div>
                  <div className="row mt-4 ">
            <div className="col-4 ">
                  <table className="border-0  table-borderless table" key={index}>
                  <tbody >
                    <tr >
                      <th className="p-0  text-dark w-50 pb-1">Id</th>
                      <th className="p-0 ">:</th>
                      <td className="p-0">{item.id}</td>
                    </tr>
                    <tr>
                      <th className="p-0 text-dark w-50 pb-1">Rate/m3</th>
                      <th className="p-0 ">:</th>
                      <td className="p-0">{item.Rate}</td>
                    </tr>
                    <tr>
                      <th className="p-0 text-dark w-50 pb-1">Calculated Qty (m3)</th>
                      <th className="p-0 ">:</th>
                      <td className="p-0">{item.CalculatedQty}</td>
                    </tr>
                    <tr>
                      <th className="p-0 text-dark w-50 pb-1">Calculated Amount</th>
                      <th className="p-0 ">:</th>
                      <td className="p-0">{item.CalculatedAmount}</td>
                    </tr>
                  </tbody>
                </table>
                </div>

                <div className=" col-4  ">
             
             <table className="border-0 table-borderless table" key={index}>
             <tbody >
               <tr >
                 <th className="p-0 text-dark w-50 pb-1">Agreement Qty (m3)</th>
                 <th className="p-0 ">:</th>
                 <td className="p-0">{item.AgreementQty}</td>
               </tr>
               <tr>
                 <th className="p-0 text-dark w-50 pb-1">Agt Amount</th>
                 <th className="p-0 ">:</th>
                 <td className="p-0">{item.AgtAmount}</td>
               </tr>
               <tr>
                 <th className="p-0 text-dark w-50 pb-1">Utilized Qty (m3)</th>
                 <th className="p-0 ">:</th>
                 <td className="p-0">{item.UtilizedQty}</td>
               </tr>
               <tr>
                 <th className="p-0 text-dark w-50 pb-1">Utilized Amount</th>
                 <th className="p-0 ">:</th>
                 <td className="p-0">{item.UtilizedAmount}</td>
               </tr>
             </tbody>
           </table>
       
        
       </div>

       
       <div className="col-4">
             
             <table className="border-0  table-borderless table" key={index}>
             <tbody >
               <tr >
                 <th className="p-0 text-dark w-50 pb-1">Billed Qty (m3)</th>
                 <th className="p-0 ">:</th>
                 <td className="p-0">{item.BilledQty}</td>
               </tr>
               <tr>
                 <th className="p-0 text-dark w-50 pb-1">Billed Amount</th>
                 <th className="p-0 ">:</th>
                 <td className="p-0">{item.BilledAmount}</td>
               </tr>
             
             
             </tbody>
           </table>
        
       </div>

                </div></div></>
              )
              })}

               <div className="d-flex justify-content-between mt-2 align-items-center">
            <p style={{ fontWeight: "500" }}>
              Showing
              <span style={{ fontWeight: "bold" }}>{startIndex}</span> to{" "}
              <span style={{ fontWeight: "bold" }}>{endIndex}</span> of{" "}
              {Boq.length}
            </p>

            <ReactPaginate
              breakLabel="..."
              nextLabel={<GrFormNext />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={<GrFormPrevious />}
              renderOnZeroPageCount={null}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
             
            </div>

           
           

          
        



     

      <Modal show={add} onHide={handleClose} >
        
        <Modal.Header className="border-0 p-0 justify-content-end bg-white" ><button className="btn " onClick={handleClose}><FaXmark /></button>
         
        </Modal.Header>
        <Modal.Body className="mx-auto w-100 bg-white pl-5 pr-5"style={{width:'60%'}}> 
          <label>Item Of Work</label>
          <select className="form-select input2 w-100 " placeholder="Search" />
          <label className="mt-4">Rate/m3</label>
          <input className="form-control input2 " placeholder="Search" />
          <label className="mt-4">Agreement Qty/m3</label>
          <input className="form-control input2 " placeholder="Search" />
          <label className="mt-4">Agreement Rate</label>
          <input className="form-control input2 " placeholder="Search" />
          </Modal.Body>
          
        <Modal.Footer className='bg-white p-0'>
          
          <Button className=" mx-auto btn1 mb-3" style={{background:'#6777EF'}} >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>




    </div>
  )
}
