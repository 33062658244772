import { Outlet, Link } from 'react-router-dom'
import React from 'react'
import { useState, useEffect } from 'react'
import '../CreateProject/CreateProject.css'
import './CalculateRatio.css'
import InputTextField from '../InputText/InputText'
import { baseurl } from '../BaseUrl/Baseurl'
import axios from 'axios'
import ButtonLink from '../ButtonLink/ButtonLink'
import { useSelector } from 'react-redux'

function FormPage() {
 
  const [beam, setBeam] = useState('')
  const [beamValid, setBeamValid] = useState(false)
  const [beamerror, setBeamerror] = useState('')
  const [beamcement, setBeamcement] = useState('')
  const [beamcementValid, setBeamcementValid] = useState(false)
  const [beamcementerror, setBeamcementerror] = useState('')
  const [beamsand, setBeamsand] = useState('')
  const [beamsandValid, setBeamsandValid] = useState(false)
  const [beamsanderror, setBeamsanderror] = useState('')
  const [beamaggregate, setBeamaggregate] = useState('')
  const [beamaggregateValid, setBeamaggregateValid] = useState(false)
  const [beamaggregateerror, setBeamaggregateerror] = useState('')
  const [wall, setwall] = useState('')
  const [wallValid, setWallValid] = useState(false)
  const [wallerror, setWallerror] = useState('')
  const [wallcement, setWallcement] = useState('')
  const [wallcementValid, setWallcementValid] = useState(false)
  const [wallcementerror, setWallcementerror] = useState('')
  const [wallsand, setWallsand] = useState('')
  const [wallsandValid, setWallsandValid] = useState(false)
  const [wallsanderror, setWallsanderror] = useState('')
  const [wallcement1, setWallcement1] = useState('')
  const [wallcementValid1, setWallcementValid1] = useState(false)
  const [wallcementerror1, setWallcementerror1] = useState('')
  const [wallsand1, setWallsand1] = useState('')
  const [wallsandValid1, setWallsandValid1] = useState(false)
  const [wallsanderror1, setWallsanderror1] = useState('')
  const [brickwork, setBrickwork] = useState('')
  const [brickworkValid, setBrickworkValid] = useState(false)
  const [brickworkerror, setBrickworkerror] = useState('')
  const [brickworkcement, setBrickworkcement] = useState('')
  const [brickworkcementValid, setBrickworkcementValid] = useState(false)
  const [brickworkcementerror, setBrickworkcementerror] = useState('')
  const [brickworksand, setBrickworksand] = useState('')
  const [brickworksandValid, setBrickworksandValid] = useState(false)
  const [brickworksanderror, setBrickworksanderror] = useState('')
  const [ceilingcement, setCeilingcement] = useState('')
  const [ceilingcementValid, setCeilingcementValid] = useState(false)
  const [ceilingcementerror, setCeilingcementerror] = useState('')
  const [ceilingsand, setCeilingsand] = useState('')
  const [ceilingsandValid, setCeilingsandValid] = useState(false)
  const [ceilingsanderror, setCeilingsanderror] = useState('')
  const[successstatus,setSuccessstatus]=useState('')
  const userData = useSelector((state) => state.userDetails)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

  }, [])

  function handleBeam(e) {
   
    setBeam(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleBeamcement(e) {
   
    setBeamcement(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleBeamsand(e) {
    
    setBeamsand(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleBeamaggregate(e) {
 
    setBeamaggregate(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }

  function handleWall(e) {
   
    setBeam(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleWallcement(e) {
 
    setWallcement(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleWallsand(e) {
 
    setWallsand(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleWallcement1(e) {
   
    setWallcement1(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleWallsand1(e) {
    console.log(e.target.value)
    setWallsand1(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleBrick(e) {
   
    setBrickwork(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleBrickcement(e) {
   
    setBrickworkcement(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleBricksand(e) {
   
    setBrickworksand(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleCeilingcement(e) {
    
    setCeilingcement(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  function handleCeilingsand(e) {
   
    setCeilingsand(e.target.value.replace(/\s+/g, ''))
    validateField(e.target.name, e.target.value)
  }
  const validateField = (fieldName, value) => {
    if (fieldName === 'beam') {
  
      if (value != 'Select') {
        setBeamerror('')
        setBeamValid(true)
        if(value==='0')
      { 
        setBeamcementValid(true)
        setBeamsandValid(true)
        setBeamaggregateValid(true)
      }

      } else {
        setBeamerror('Please select the type ')
        setBeamValid(false)
      }
    }
    if (fieldName === 'beamcement') {
  
      if (value.length != 0) {
        setBeamcementerror('')
        setBeamcementValid(true)
      } else {
        setBeamcementerror('Please enter')
        setBeamcementValid(false)
      }
    }
 
    
    if (fieldName === 'beamsand') {
    
      if (value.length != 0) {
        setBeamsanderror('')
        setBeamsandValid(true)
      } else {
        setBeamsanderror('Please enter')
        setBeamsandValid(false)
      }

  }

    if (fieldName === 'beamaggregate') {
      if (value.length != 0) {
        setBeamaggregateerror('')
        setBeamaggregateValid(true)
      } else {
        setBeamaggregateerror('Please enter ')
        setBeamaggregateValid(false)
      }
    }
    if (fieldName === 'brickwork') {

      if (value != 'Select') {
        setBrickworkerror('')
        setBrickworkValid(true)
        if(value==='0')
        { 
          setBrickworkcementValid(true)
          setBrickworksandValid(true)
        
        }
      } else {
        setBrickworkerror('Please select the type ')
        setBrickworkValid(false)
      }
    }
    if (fieldName === 'brickworkcement') {
        
      if (value.length != 0) {
        setBrickworkcementerror('')
        setBrickworkcementValid(true)
      } else {
        setBrickworkcementerror('Please enter')
        setBrickworkcementValid(false)
      }
    
  
    }
    if (fieldName === 'brickworksand') {
     
      if (value.length != 0) {
        setBrickworksanderror('')
        setBrickworksandValid(true)
      } else {
        setBrickworksanderror('Please enter')
        setBrickworksandValid(false)
      }
  
    }
    if (fieldName === 'wallcement') {
      if (value.length != 0) {
        setWallcementerror('')
        setWallcementValid(true)
      } else {
        setWallcementerror('Please enter')
        setWallcementValid(false)
      }
    }
    if (fieldName === 'wallcement1') {
      if (value.length != 0) {
        setWallcementerror1('')
        setWallcementValid1(true)
      } else {
        setWallcementerror1('Please enter')
        setWallcementValid1(false)
      }
    }
    if (fieldName === 'wallsand') {
      if (value.length != 0) {
        setWallsanderror('')
        setWallsandValid(true)
      } else {
        setWallsanderror('Please enter')
        setWallsandValid(false)
      }
    }
    if (fieldName === 'wallsand1') {
      if (value.length != 0) {
        setWallsanderror1('')
        setWallsandValid1(true)
      } else {
        setWallsanderror1('Please enter')
        setWallsandValid1(false)
      }
    }
    if (fieldName === 'ceilingcement') {
      if (value.length != 0) {
        setCeilingcementerror('')
        setCeilingcementValid(true)
      } else {
        setCeilingcementerror('Please enter')
        setCeilingcementValid(false)
      }
    }
    if (fieldName === 'ceilingsand') {
      if (value.length != 0) {
        setCeilingsanderror('')
        setCeilingsandValid(true)
      } else {
        setCeilingsanderror('Please enter')
        setCeilingcementValid(false)
      }
    }
  }


  function onSubmitEvent(event) {
    event.preventDefault()
    //  alert("I am calling")
// console.log(beamValid)
// console.log(beamcementValid)
// console.log(beamsandValid)
// console.log(beamaggregateValid)
// console.log(brickworkValid)
// console.log(brickworkcementValid)
// console.log(brickworksandValid)
// console.log(wallcementValid)
// console.log(wallsandValid)
// console.log(wallcementValid1)
// console.log(wallsandValid1)
// console.log(ceilingcementValid)
// console.log(ceilingsandValid)
// console.log("*************************************")
// console.log(beam)
// console.log(brickwork)
        // if (beamValid && beamcementValid && beamsandValid && beamaggregateValid &&
        //   brickworkValid && brickworkcementValid && brickworksandValid && wallcementValid &&
        //   wallsandValid && wallcementValid1 && wallsandValid1 && ceilingcementValid && ceilingsandValid) {
                          //  alert("All entered"+userData.projectId)
                          const x=0
                          const x1='PR-1'
        let userData1 = new FormData()
        
        userData1.append('conc_mix_type',beam)
        userData1.append('project_id', userData.projectId)
        userData1.append('cement_in_kg_for_concrete', beamcement)
        userData1.append('sand_in_kg_for_concrete', beamsand)
        userData1.append('aggregate_in_kg_for_concrete', beamaggregate)
        // userData1.append('cement_in_kg_for_brickwork',brickworkcement)
        // userData1.append('sand_in_kg_for_brickwork', brickworksand)
        userData1.append('plas_mix_type',x)
        // userData1.append('cement_in_kg_for_plastering', wallcement)
        // userData1.append('sand_in_kg_for_plastering', wallsand)
        userData1.append('bwk_mix_type', x)
        
        // axios
        //   .post(baseurl + '/api/plan/specifications/create', userData1, {
        //     headers: { 'Content-Type': 'multipart/form-data' },
        //   })
          axios({
            method: 'POST',
          
            url:  baseurl+'/api/plan/specifications/create',
          
            headers: {
              'Content-Type': 'multipart/form-data',     
              Authorization: 'Bearer ' + userData.token,
            },
          
             data: userData1,
          })
          .then((response) => {

            const dat=response.data
            console.log("&&&&&&&"+response.data)
            //  alert("success Boq")
            setSuccessstatus(true)
            // setProjectid(dat.project_id)   
            // setClientid(dat.client_id) 
            // setSuccessstatus(true)
          })
          .catch((error) => {
             console.log(error)
            //setErrormsg(error.response.data.message)
          })
      //  }

  }

  return (
    <>

      {/* {success && <Navigate to="/FreeTrialRegistration" />} */}
      {successstatus && <ButtonLink btnPath="/CalculatedDetails"
     
      />}
      <div className="cont-margin tmar pt-3 ">
        <div >
          <div className='table1bdstyle'>
            <div className='table1st'>

              <div class="row ">


                <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                  <form
                    class="row d-flex justify-content-center align-center  blacktext"
                  
                  >

                    <div class="row">

                      <div class="col-12 col-sm-12  col-md-12 col-lg-12 col-md-12  ">
                        <label
                          for="formFileLg"
                          class="form-label"
                          className="login-text"
                        >
                          Concrete
                        </label>
                        <select
                          className="project-box"
                          id="beam"
                          name="beam"
                          //  defaultValue={companyname}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                          value={beam}
                          onChange={handleBeam}
                        >
                          <option value="Select" selected>
                            Select
                          </option>
                          <option value="0">Nominal Mix</option>
                          <option value="1">Design Mix</option>

                        </select>
                        <div className="login-error">{beamerror}</div>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 ">
                        {beam === '1' && (
                          <div class="row">

                            <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                              <label for="formFileLg" class="ratiopad">
                                {/* Cement */}
                              </label>
                              <center>
                                <InputTextField
                                  handleInputChange={handleBeamcement}
                                  valueText={beamcement}
                                  inputName="beamcement"
                                  inputId="beamcement"
                                  classname="project-box"
                                  inputType="text"
                                  placeholderValue="cement"

                                ></InputTextField>(kg/m<sup>3</sup> )

                                <div className="login-error">{beamcementerror}</div>
                              </center>
                            </div>
                            <div class="col-12 col-sm-12 col-lg-1 col-xl-1 col-md-6 pb-3">

                              <div className="ratiosymbolpad ">
                                <center>

                                  {/* <font > <b>:</b></font> */}

                                </center>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                              <label for="formFileLg" className="ratiopad">
                                {/* Sand */}
                              </label>
                              <center>
                                <InputTextField
                                  handleInputChange={handleBeamsand}
                                  valueText={beamsand}
                                  inputName="beamsand"
                                  inputId="beamsand"
                                  classname="project-box"
                                  inputType="text"
                                  placeholderValue="sand"

                                ></InputTextField>(kg/m<sup>3</sup> )

                                <div className="login-error">{beamsanderror}</div>
                              </center>
                            </div>
                            <div class="col-12 col-sm-12 col-lg-1 col-xl-1 col-md-6 pb-3 ">

                              <div className="ratiosymbolpad ">
                                <center>

                                  {/* <font > <b>:</b></font> */}

                                </center>
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-6 pb-3">
                              <label for="formFileLg" className="ratiopad">
                                {/* Aggregate */}
                              </label>
                              <center>

                                <InputTextField
                                  handleInputChange={handleBeamaggregate}
                                  valueText={beamaggregate}
                                  inputName="beamaggregate"
                                  inputId="beamaggregate"
                                  classname="project-box"
                                  inputType="text"
                                  placeholderValue="aggregate"

                                ></InputTextField>(kg/m<sup>3</sup> )

                                <div className="login-error">{beamaggregateerror}</div>
                              </center>
                            </div>
                          </div>)
                        }
                      </div>

                      {/* <div class="col-12 col-sm-12  col-md-8 col-lg-12 col-md-6  col-xl-12 pt-3 ">
                        <label
                          for="formFileLg"
                          class="form-label"
                          className="login-text"
                        >
                          Brick Work
                        </label>
                        <select
                          className="project-box"
                          id="brickwork"
                          name="brickwork"
                          //  defaultValue={companyname}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                          value={brickwork}
                          onChange={handleBrick}
                        >
                          <option value="Select" selected>
                            Select
                          </option>
                          <option value="0">9 Inch Thick</option>
                          <option value="1">4 1\2 Inch Thick</option>

                        </select>
                        <div className="login-error">{brickworkerror}</div>
                      </div> */}

                      {/*  */}


                      <div class="col-12 col-sm-12  col-lg-12 col-xl-12 col-md-6 pb-3 ">
                        {brickwork === '1' && (
                          <div class="row ">
                            {/* <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-4">
                              <label for="formFileLg" class="ratiopad">
                               
                              </label>
                              <center>
                                <InputTextField
                                  handleInputChange={handleBrickcement}
                                  valueText={brickworkcement}
                                  inputName="brickworkcement"
                                  inputId="brickworkcement"
                                  classname="project-box"
                                  inputType="text"
                                  placeholderValue="cement"

                                ></InputTextField>(kg/m<sup>2</sup> )

                                <div className="login-error">{brickworkcementerror}</div>
                              </center>
                            </div> */}
                            {/* <div class="col-12 col-sm-12 col-lg-1 col-xl-1 col-md-1 ">

                              <div className="ratiosymbolpad ">
                                <center>

                                  <font > <b>:</b></font>

                                </center>
                              </div>
                            </div> */}
                            {/* <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-4 ">
                              <label for="formFileLg" className="ratiopad">
                             
                              <center>
                                <InputTextField
                                  handleInputChange={handleBricksand}
                                  valueText={brickworksand}
                                  inputName="brickworksand"
                                  inputId="brickworksand"
                                  classname="project-box"
                                  inputType="text"
                                  placeholderValue="sand"

                                ></InputTextField>(kg/m<sup>2</sup> )

                                <div className="login-error">{brickworksanderror}</div>
                              </center>
                            </div> */}
                            <div class="col-12 col-sm-12 col-lg-6 col-xl-6 col-md-4 ">
                            </div>
                          </div>
                        )}
                      </div>


                      {/*  */}
                      {/* <div class="col-12 col-sm-12  col-md-6 col-lg-12 col-md-12 col-xl-12 pb-5 pt-4">
                       
                        <font className='wallplaster'>     Wall Plastering</font>

                      </div>
                      <div class="col-12 col-sm-12 col-lg-4 col-xl-4 col-md-4 pb-3">

                        <font className='wallplaster'> 9 Inch Thick</font>

                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-3 pb-3">

                        <center>
                          <InputTextField
                            handleInputChange={handleWallcement}
                            valueText={wallcement}
                            inputName="wallcement"
                            inputId="wallcement"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="cement"

                          ></InputTextField>(kg/m<sup>2</sup> )

                          <div className="login-error">{wallcementerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-1 col-xl-1 col-md-1 pb-3">

                        <div className="ratiosymbolpad1 ">
                          <center>

                            <font > <b>:</b></font>

                          </center>
                        </div>
                      </div> */}
                      {/* <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-3 pb-3">

                        <center>
                          <InputTextField
                            handleInputChange={handleWallsand}
                            valueText={wallsand}
                            inputName="wallsand"
                            inputId="wallsand"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="sand"

                          ></InputTextField>(kg/m<sup>2</sup> )

                          <div className="login-error">{wallsanderror}</div>
                        </center>
                      </div>
                        <div class="col-12 col-sm-12 col-lg-1 col-xl-1 col-md-1 pb-3"></div>
                      
                      <div class="col-12 col-sm-12 col-lg-4 col-xl-4 col-md-6 pb-3">

                        <font className='wallplaster'>   4 1/2 Inch Thick </font>

                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-3 pb-3">

                        <center>
                          <InputTextField
                            handleInputChange={handleWallcement1}
                            valueText={wallcement1}
                            inputName="wallcement1"
                            inputId="wallcement1"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="cement"

                          ></InputTextField>(kg/m<sup>2</sup> )

                          <div className="login-error">{wallcementerror1}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-1 col-xl-1 col-md-6 pb-3">

                        <div className="ratiosymbolpad1 ">
                          <center>

                            <font > <b>:</b></font>
                            

                          </center>
                        </div>
                      </div> */}
                      {/* <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-3 pb-3">

                        <center>
                          <InputTextField
                            handleInputChange={handleWallsand1}
                            valueText={wallsand1}
                            inputName="wallsand1"
                            inputId="wallsand1"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="sand"

                          ></InputTextField>(kg/m<sup>2</sup> )

                          <div className="login-error">{wallsanderror1}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-1 col-xl-1 col-md-1 pb-3"></div>
              

                      <div class="col-12 col-sm-12  col-md-4 col-lg-4 col-md-4 col-xl-4 pb-3 pt-3">

                        <font className='wallplaster'>   Ceiling Plastering</font>

                      </div>
                      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-3 pb-3">

                        <center>
                          <InputTextField
                            handleInputChange={handleCeilingcement}
                            valueText={ceilingcement}
                            inputName="ceilingcement"
                            inputId="ceilingcement"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="cement"

                          ></InputTextField>(kg/m<sup>2</sup> )

                          <div className="login-error">{ceilingcementerror}</div>
                        </center>
                      </div>
                      <div class="col-12 col-sm-12 col-lg-1 col-xl-1 col-md-1 pb-3">

                        <div className="ratiosymbolpad1 ">
                          <center>

                            <font > <b>:</b></font>

                          </center>
                        </div>
                      </div> */}
                      {/* <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-md-3 pb-3">

                        <center>
                          <InputTextField
                            handleInputChange={handleCeilingsand}
                            valueText={ceilingsand}
                            inputName="ceilingsand"
                            inputId="ceilingsand"
                            classname="project-box"
                            inputType="text"
                            placeholderValue="sand"

                          ></InputTextField>(kg/m<sup>2</sup> )

                          <div className="login-error">{ceilingsanderror}</div>
                        </center>
                      </div> */}
                      {/*  */}
                    </div>
                    <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 ">
                      <center>
                        <Link 
                        // to="/CalculatedDetails"
                        >
                          <button
                            type="submit"
                            className="project-btn"
                            name="submit"
                            onClick={onSubmitEvent}
                          // disabled={!(beamValid && beamcementValid && beamsandValid && beamaggregateValid &&
                          //   brickworkValid && brickworkcementValid && brickworksandValid && wallcementValid &&
                          //   wallsandValid && wallcementValid1 && wallsandValid1 && ceilingcementValid && ceilingsandValid)}
                          >
                            <b>Calculate</b>
                          </button>
                        </Link>
                      </center>{' '}
                    </div>

                  </form>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>



    </>
  )
}

export default FormPage
