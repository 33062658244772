import React from 'react';
import '../DashBoard/AfterLoginHome.css';
import './Projects.css'
import { Link } from 'react-router-dom';
import { FaPlus, FaEdit, FaPlusCircle } from "react-icons/fa";
import { AiOutlinePlusCircle } from "react-icons/fa";
import { useState, useEffect } from 'react'
import RequestModal from '../Modal/CreateRequest'
import StatusupdateModal from '../Modal/Edit'
import Renewal from '../Modal/Renewal'
import ViewTickets from '../Modal/ViewTickets'
import { baseurl } from '../BaseUrl/Baseurl'
import completedimg from '../../assets/images/Status/completed-5.png'
import inprogressimg from '../../assets/images/Status/inprogress.png'
import pendingimg from '../../assets/images/Status/pending.png'
import startedimg from '../../assets/images/Status/started.png'
import axios from 'axios'
import plus from '../../assets/images/brick_icons/Plus_white.png'
import banner from '../../assets/images/Icons_and_images/Banner-bg.jpg'
import open from '../../assets/images/Status/open.png'
import ButtonLink from '../ButtonLink/ButtonLink'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  updateLoginStatus,
  setUserName,
  setUserId,
} from '../../store/userDetails'
const WeareHere = () => {
  const [btnname, setBtnname] = useState('')
  const [modelStatus, setModelStatus] = useState(false);
  const [requeststatus, setRequeststatus] = useState(false);
  const [viewstatus, setViewstatus] = useState(false);
  const [renewalstatus, setRenewalstatus] = useState(false);
  const [viewticketstatus, setViewticketstatus] = useState(false);
  const [requestlist, setRequestlist] = useState([]);
  const [renewallist, setRenewallist] = useState([]);
  const [ticketlist, setTicketlist] = useState([]);
  const [projectid, setProjectid] = useState('');
  const [project_renewalid, setProject_renewalid] = useState('');
  const [ticketno, setTicketno] = useState('');
  const [successstatus, setSuccessstatus] = useState(false);
  const userData = useSelector((state) => state.userDetails)
  const dispatch = useDispatch()
  const [projectlist, setProjectlist] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    axios({
        method: 'GET',
  
        url: baseurl+'/api/project/list?client_id='+userData.clientId,
  
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + userData.token,
        }
       
      })
        .then((response) => {
          console.log(response.data)
          const Data = response.data
         
          setProjectlist(Data )
          setSuccessstatus(true)
        })
        .catch((error) => {
          console.log(error)
          // setErrormsg(error.response.data.message)
        })
  }, [])


  function ViewProject(project_id,project_type)
  {

    dispatch(
      updateLoginStatus({
          isLoggedIn:true,
          Pagename: userData.Pagename, 
          userName:userData.userName,
          userId:userData.userId,
          role:userData.role,
          token:userData.token,
          projectId:project_id,
          clientId:userData.clientId,
          projectType:project_type       
      })
    )
setProjectid(project_id)
setViewstatus(true)


  }
  return (

      <div class="cont-margin tmar">
         {viewstatus && <ButtonLink btnPath="/ViewProject" projectid={projectid} />}
        {/* <div  style={{ backgroundImage: `url(${banner})` }}> */}
        <div className='table1bdstyle'>
          
          <div class="row table1st ">
          {/* <div class=" col-xl-1 pt-1 pb-1">
              
              </div> */}
          <div class=" col-xl-4 pt-1 pb-1 ">
              <div className='padtext1'>  <h5 ><b>Project Status</b></h5></div>
              </div>
              <div class=" col-xl-4 pt-1 pb-1">
              
              </div>
              <div class=" col-xl-3 pt-1 pb-1 ">
            <Link to="/CreateProject"> 
            <button 
                    type="button"
                    className="createpro-btn"
                  ><FaPlusCircle />
                   &nbsp;Create New Project</button>
            </Link> 
              </div>
              <div class=" col-xl-1 pt-1 pb-1">
              
              </div>

              {successstatus && projectlist.length == 0 &&
            // !checkduedate &&
            
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3">
                <center><b><h6>No Data Available</h6></b></center>
             
                </div>}
              {projectlist.length!=0 &&   <table
              class="table tablemargin" 
             
            >
              <tr class="trst pt-1 pb-1">
                {/* <th>S.No</th> */}
                <th><div className='trpad'><font><center>Project Name</center></font></div></th>
                <th><center>Contact</center></th>
                {/* <th>Type</th> */}
                <th><center>Client Name</center></th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Status</th>
                <th></th>
              </tr>

              {projectlist.length!=0 && projectlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize' >
                    <td><div className='trpad'><font><center>{item.project_name}</center></font></div></td>
                    <td><center>{item.client_contact_person}</center></td>
                    {/* <td>{item.project_type}</td> */}
                    <td><center>{item.company_name}</center></td>
                   
                    <td > 
                     
 {item.project_status === "Completed" ? 
<img src={completedimg} width={10}  className='leftimg'/>: 
(item.project_status === "Inprogress" ?
 <img src={inprogressimg}  className='leftimg'/>:
                       <img src={open} width={10} className='leftimg'/>)} 
                 
                    </td>
                    <td>
            <button 
                    type="button"
                    className="view-btn"
                    onClick={()=>ViewProject(item.project_id,item.project_type)}
                  >
                    {/* <FaPlusCircle /> */}
                   &nbsp;View Project</button>
            </td>
                  </tr>
                )
              })}
            </table>}
          </div>

        </div>
        <div className='table1bdstyle '>
          <div class="row table1st">
          <div class="pt-1 pb-1 padtext">
                <h5><b>Project Creation Status</b></h5>
              </div>
              {successstatus && projectlist.length == 0 &&
            // !checkduedate &&
            
              <div class="col-xl-12 col-md-12 col-lg-12 col-xxl-12 login-error pb-3">
                <center><b><h6>No Data Available</h6></b></center>
             
                </div>}

              {projectlist.length!=0 &&    <table
              class="table tablemargin "
              readOnly="readOnly"

            >
             
              <tr class="trst">
                <th><div className='trpad'><font><center>Project Name</center></font></div></th>
                <th><center>Contact </center></th>
               <th><center>Project Type</center></th>
                <th><center>Completion Date</center></th>
                <th><center>Client Name</center></th>
                <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Status</th>
               
              </tr>
           
              {projectlist.length!=0 && projectlist.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize' >
                    <td><div className='trpad'><font><center>{item.project_name}</center></font></div></td>
                    <td><center>{item.client_contact_person}</center></td>
                    <td><center>{item.project_type}</center></td>
                    <td><center>{item.completion_date}</center></td>
                    <td><center>{item.company_name}</center></td>
                    <td>               
 {item.project_status === "1" ? 
<img src={completedimg}   className='leftimg'/>: 

 <img src={inprogressimg}  className='leftimg'/>
 } </td>
                   
                  </tr>
                )
              })}
            </table>}
          </div>

        </div>
    
      </div>

      // </div>

  );
}

export default WeareHere;
