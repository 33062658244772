import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Button, Modal, Form } from 'react-bootstrap'
import './Modal.css'
import CreateRequest from '../CreateRequest/CreateRequest'
import { useState } from 'react'
import InputTextField from '../InputText/InputText'
import axios from 'axios'
import { baseurl } from '../BaseUrl/Baseurl'
import { Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
export default function Model({ btnClick, status, structureid }) {
  const userData1 = useSelector((state) => state.userDetails)
  const [modalVisible, setModalVisible] = useState(status);
  const [itemlist1, setItemlist1] = useState([]);


  useEffect(() => {
    
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    alert(structureid)
    let userData = new FormData()
    axios({
      method: 'GET',

      url: baseurl +'/api/plan/list/item_of_work?project_id='+'PR-2'+'&structure_item='+structureid,
      
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userData1.token,
      },
      data: userData,
    })
      .then((response) => {
     
      
        console.log("hello" + response.data)
        const Data = response.data
        const dt=Data.item_of_work
        console.log(Data)
        setItemlist1(dt)
        alert(response.data)

      })
      .catch((error) => {
        console.log(error)
        // setErrormsg(error.response.data.message)
      })
  }, [status, modalVisible])

  return (
    <>
      <Modal show={modalVisible} onHide={btnClick} id="modal-size1">
        <Modal.Body>
        <div>
          {/* <center>  <div className="model-top">View</div></center> */}


          <div className='table1bdstyle '>
          <div class="row table1st">
          <div class="pt-1 pb-1">
                {/* <p><b>Upcoming Renewal</b></p> */}
              </div>
            <table
              class="table "
              readOnly="readOnly"

            >
             
              <tr class="trst">
                <th><div className='trpad'>Item&nbsp;of&nbsp;Work</div></th>
                <th>Description</th>
               

           </tr>
                {itemlist1.length != 0 && itemlist1.map((item, index) => {
                return (
                  <tr key={index} className='tablefontsize'>
                 
                 <td><div className='trpad'>{item.item_name}</div></td>
                    <td>{item.description}</td>
                    
                  </tr>
                )
              })}
            </table>
          </div>
         
        </div>
        </div>
          {/* <CreateRequest /> */}

{/* 
          <div className="cont-modalstyle">
           

                  <div class="row pb-2">


                    <div class="col-lg-12 col-xl-12col-md-12 col-sm-12 col-12  ">
                      <form
                        class="row d-flex justify-content-center align-center  blacktext "
                        onSubmit={(event) => {
                          onSubmitEvent(event);
                          btnClick(); // Close the modal
                        }}
                      >


                        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                          <label for="formFileLg" className="login-text">
                            Ticket ID
                          </label>
                          <center>
                            <InputTextField
                              //  handleInputChange={handleContactperson}
                              //  valueText={contactperson}
                              //  inputName="contactperson"
                              //  inputId="contactperson"
                              classname="project-box"
                              //  maxLen={20}
                              inputType="text"
                              placeholderValue={TicketNumber}
                              disabled
                            ></InputTextField>
                          </center>
                        </div>
                        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                          <label for="formFileLg" className="login-text">
                         Discription
                          </label>
                          <center>
                            <InputTextField
                              //  handleInputChange={handleContactperson}
                              //  valueText={contactperson}
                              //  inputName="contactperson"
                              //  inputId="contactperson"
                              classname="project-box"
                              //  maxLen={20}
                              inputType="text"
                              placeholderValue={ticketlist1.issue_description
                              }
                              disabled
                            ></InputTextField>
                          </center>
                        </div>


                        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                          <label for="formFileLg" className="login-text">
                            Raised By
                          </label>
                          <center>
                            <InputTextField
                              //  handleInputChange={handleContactperson}
                              //  valueText={contactperson}
                              //  inputName="contactperson"
                              //  inputId="contactperson"
                              classname="project-box"
                              //  maxLen={20}
                              inputType="text"
                              placeholderValue={ticketlist1.raised_by}
                              disabled
                            ></InputTextField>
                          </center>
                        </div>
                        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                          <label for="formFileLg" className="login-text">
                            Status
                          </label>
                          <center>
                            <InputTextField
                              //  handleInputChange={handleContactperson}
                              //  valueText={contactperson}
                              //  inputName="contactperson"
                              //  inputId="contactperson"
                              classname="project-box"
                              //  maxLen={20}
                              inputType="text"
                              placeholderValue={ticketlist1.support_status}
                              disabled
                            ></InputTextField>
                          </center>
                        </div>
                        <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12  pb-3">
                          <label for="formFileLg" className="login-text">
                          Valid Date
                          </label>
                          <center>
                            <InputTextField
                              //  handleInputChange={handleContactperson}
                              //  valueText={contactperson}
                              //  inputName="contactperson"
                              //  inputId="contactperson"
                              classname="project-box"
                              //  maxLen={20}
                              inputType="text"
                              placeholderValue={ticketlist1.raised_on}
                              disabled
                            ></InputTextField>
                          </center>
                        </div>
                             
                <div class="col-12 col-sm-12 col-lg-12 col-xl-12 col-md-12 pt-4  ">
<center>
                        <div class="textareamargin">              

                          <textarea class="form-control" id="message" name="message" rows="3" placeholder='Message' onChange={handlemessage}></textarea>
                        </div>

                        </center>

                      </div>
                        <div class="col-sm-12 col-lg-12 col-12 col-md-12 col-xl-12 pt-4 pb-3">
                          <center>
                            <button
                              type="submit"
                              className="project-btn"
                              name="submit"
                            // disabled={!(projectstatusValid )}
                            >
                              <b>Submit</b>
                            </button>
                          </center>{' '}
                        </div>

                      </form>
                    </div>

                  </div>
                </div> */}
            

        </Modal.Body>
      </Modal>
    </>
  )
}
